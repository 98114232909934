import iso from "src/assets/iso.json";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { GetMyBots } from "src/app/core/services/outbound";
import {
  bot_status_color_map,
  bot_status_map,
  published_status_map,
  user_search_placeholder_map,
} from "src/app/core/services/constants";

@Component({
  selector: "app-bots-selector",
  templateUrl: "./bots-selector.component.html",
  styleUrls: ["./bots-selector.component.scss"],
  providers: [],
})

/**
 * Contacts domain-list component
 */
export class BotsSelectorComponent implements OnInit, OnDestroy {
  @Input() mode = "list";
  @Input() single = false;
  @Input() chatID: string;
  @Input() selectedBots = [];
  @Output() selectedNotifier = new EventEmitter<any>();
  @Output() cancelNotifier = new EventEmitter<boolean>(false);
  // bread crumb items
  breadCrumbItems: Array<{}>;
  page: Array<any> = [];
  extensionspage: Array<any> = [];
  eop = false;
  pageSize = 24;
  currentPage = 0;
  botsList: {};
  extensionsList: {};
  listBotsSubscription: any;
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  status_map = {};
  status_color_map = {};
  user_search_placeholder_map = {};
  published_status_map = {};
  user_role_map = {};
  user_role_color_map = {};
  payload = {};
  userListForm: UntypedFormGroup;
  submitted = false;
  dupe = false;
  criterionCount = 0;
  component_ref: any;
  search_message = "";
  countries = [];
  loading = false;
  verifySubscription: any;
  my_id: any;

  selectedBotsList = {};
  device_os_map = {};

  constructor(
    private ws: WebsocketService,
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService
  ) {}

  ngOnInit() {
    this.chatID = this.chatID ? this.chatID : localStorage.getItem("chat_id");
    this.countries = iso.map((a) => a.name);
    this.status_map = bot_status_map;
    this.published_status_map = published_status_map;
    this.status_color_map = bot_status_color_map;
    this.user_search_placeholder_map = user_search_placeholder_map;

    // console.log(this.countries);
    this.component_ref = this.mdw.makeRef(16);
    this.currentPage = 1;
    this.botsList = {};
    this.extensionsList = {};
    // console.log(Object.keys(this.botsList).length);

    this.userListForm = this.formBuilder.group({
      member_id: new UntypedFormControl(null),
      search_value: new UntypedFormControl(null),
      status: new UntypedFormControl(""),
      role_id: new UntypedFormControl(""),
      date: new UntypedFormControl(null),
      search_type: new UntypedFormControl("name"),
    });

    this.listBotsSubscription = this.ws.listBotsResponseContainer$.subscribe(
      (listBotsData) => {
        if (listBotsData && listBotsData.ref === this.component_ref) {
          this.loading = false;
          this.page = [];
          listBotsData.bots.forEach((bot, index) => {
            if(bot.owner){
              this.botsList[String(bot.user_id)] = bot;
            }else{
              this.extensionsList[String(bot.user_id)] = bot;
            }
          });
          this.page = Object.values(this.botsList);
          this.extensionspage = Object.values(this.extensionsList);
          if (this.page.length == 0) {
            this.search_message = "No results matched your search criteria";
          }
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData" + this.router.url);
        if (verifyData) {
          // console.log('verifyData');
          this.loading = true;
          this.ws.processMethod(new GetMyBots(1, this.component_ref));
          this.ws.processMethod(new GetMyBots(0, this.component_ref));
        }
      }
    );

    if (this.selectedBots && this.selectedBots.length > 0) {
      for (let i = 0; this.selectedBots.length > i; i++) {
        this.selectedBotsList[this.selectedBots[i].user_id] = this.selectedBots[i];
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.userListForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.userListForm.valid) {
    } else {
      return;
    }
  }

  botSearch() {
    let filter = this.f.search_value.value.toUpperCase();
    let type = this.f.search_type.value;
    let list: any = document.querySelectorAll("#bots-list");
    for (let i = 0; i < list.length; i++) {
      let el = list[i].querySelector(`#${type}`).getElementsByTagName("p")[0];
      let value = el.innerText;
      if (value.toLocaleUpperCase().indexOf(filter) > -1) {
        list[i].style.display = "";
      } else {
        list[i].style.display = "none";
      }
    }
  }

  selectUsers(e, data) {
    // e.preventDefault();
    e.stopPropagation();
    if (this.single) {
      if (this.selectedBotsList[data.user_id]) {
        delete this.selectedBotsList[data.user_id];
      } else {
        this.selectedBotsList = {};
        this.selectedBotsList[data.user_id] = data;
      }
    } else {
      if (this.selectedBotsList[data.user_id]) {
        delete this.selectedBotsList[data.user_id];
      } else {
        this.selectedBotsList[data.user_id] = data;
      }
    }

    // this.ref.detectChanges();
  }

  insertEvent() {
    const selected = Object.values(this.selectedBotsList);
    this.selectedNotifier.emit(selected);
  }

  cancelEvent() {
    this.cancelNotifier.emit(true);
  }

  isObjEmpty() {
    return Object.keys(this.selectedBotsList).length === 0;
  }

  isSelected(id: string) {
    return this.selectedBotsList[id] ? true : false;
  }

  searchTypeChanged() {
    if (this.userListForm.value.search_type === "member_id") {
      this.userListForm
        .get("search_value")
        .setValidators([Validators.pattern(/^[0-9]*$/)]);
    } else {
      this.userListForm.get("search_value").clearValidators();
    }
    this.userListForm.get("search_value").updateValueAndValidity();
  }

  onChanges(e: any) {

  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.listBotsSubscription) {
      this.listBotsSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }
}
