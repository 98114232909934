import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Menu, Tab } from "src/app/core/services/interface";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BuilderService } from "src/app/sharedservices/builder.service";
import {
  material_icons,
  material_icons_cat,
} from "src/app/core/services/constants";

@Component({
  selector: "app-screen-selector",
  templateUrl: "./screen-selector.component.html",
  styleUrls: ["./screen-selector.component.scss"],
})
export class ScreenSelectorComponent implements OnInit, OnDestroy {
  constructor(
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() menu_group: string;
  @Input() screens: string[] = ['tabs','home','side', 'custom', 'menus','homeMenu', 'sideMenu'];
  @Output() selectedItemNotify = new EventEmitter<{
    data: any;
    type: string;
  }>();

  updateBuilderComponentSubscribtion: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  icons: any;
  modalRef?: BsModalRef;
  solidIcons = [];
  regularIcons = [];
  viewSolid = true;
  viewRegular = true;
  viewAll = true;
  matIcons;
  matCat;
  filters = [];
  showCanvas = true;
  tabs: any;
  sortedHomeList = [];
  sortedSideList = [];
  // sortedCustomList = [];
  sortedHomeMenuList = [];
  sortedSideMenuList = [];
  tabList=[];
  sortedSideListFiltered=[];
  sortedHomeListFiltered = [];
  sortedHomeMenuListFiltered = [];
  sortedSideMenuListFiltered = [];

  customMenu: any;
  fontSmaller = true;

  async ngOnInit() {
    this.matIcons = material_icons;
    this.matCat = material_icons_cat;
    await this.getHome();
    await this.getSide();
    // await this.getCustom();

  }
  async getHome() {
    const request = await this.indexDBService.getItemList(
      "tab",
      "cat",
      IDBKeyRange.only("home")
    );
    request.onsuccess = async (event) => {
      this.sortedHomeList = this.sortTabs(request.result);
      let j = 0;
      if (!this.menu_group){
        for (let i = 0; i < this.sortedHomeList.length; i++) {
          if (this.sortedHomeList[i].type === "menu") {
            this.getMenus(this.sortedHomeList[i], j, this.sortedHomeMenuList, false);
            j++;
          }
          this.sortedHomeListFiltered = this.sortedHomeList;
          this.sortedHomeMenuListFiltered =this.sortedHomeMenuList
        }
      }else {
        this.getMenus({title: null, id: this.menu_group},0, this.sortedHomeMenuList, false);
      }
    };

  }
  async getSide() {
    const request = await this.indexDBService.getItemList(
      "tab",
      "cat",
      IDBKeyRange.only("side")
    );
    request.onsuccess = async (event) => {
      this.sortedSideList = this.sortTabs(request.result);
      let j = 0;

      if (!this.menu_group){
        for (let i = 0; i < this.sortedSideList.length; i++) {
          if (this.sortedSideList[i].type === "menu") {
            this.getMenus(this.sortedSideList[i], j, this.sortedSideMenuList, true);
            j++;
          }

          this.sortedSideListFiltered = this.sortedSideList;
          this.sortedSideMenuListFiltered = this.sortedSideMenuList;
        }
      }else {
        this.getMenus({title: null, id: this.menu_group},0, this.sortedSideMenuList, true);
      }

    };
    }
  // async getCustom() {
  //   const request = await this.indexDBService.getItemList(
  //     "tab",
  //     "cat",
  //     IDBKeyRange.only("next")
  //   );
  //   request.onsuccess = (event) => {
  //     this.sortedCustomList = this.sortTabs(request.result);
  //   };
  // }

  async getMenus(tab, i, menuList, isSide: boolean =false) {
    const request = await this.indexDBService.getItemList(
      "menu",
      "grp",
      IDBKeyRange.only(tab.id)
    );
    request.onsuccess = (event) => {
      menuList[i] = { name: tab.title, menus: this.sortMenus(request.result) };
      if (!isSide){
        this.sortedHomeMenuListFiltered[i]={ name: tab.title, menus: this.sortMenus(request.result) };
      }else {
        this.sortedSideMenuListFiltered[i]={ name: tab.title, menus: this.sortMenus(request.result) };
      }

    };
  }

  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }

  sortMenus(menus: Menu[]) {
    return menus.sort((a, b) => Number(a.menu_order) - Number(b.menu_order));
  }

  openSelector(exlargeModal: any) {
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  removeFilter(cat) {
    let index = this.filters.findIndex((e) => e == cat);
    this.filters.splice(index, 1);

    let spans = document.getElementsByName("filters");
    let span;

    for (var i = 0; i < spans.length; i++) {
      if (spans[i].innerText == cat) {
        span = spans[i];
        break;
      }
    }

    if (span) {
      span.classList.remove("subcard");
      span.classList.add("bg-light");
      span.classList.add("enablePointer");
    }

    if (this.filters.length > 0) {
      let filtered = [];
      material_icons.forEach((icon) => {
        if (this.filters.includes(icon.cat)) {
          filtered.push(icon);
        }
      });
      this.matIcons = filtered;
    } else {
      this.matIcons = material_icons;
    }
  }

  onMouseEnter(e) {
    if (!this.filters.includes(e.target.innerText)) {
      e.target.classList.remove("bg-light");
      e.target.classList.add("badge-soft-success");
    }
  }

  onMouseLeave(e) {
    if (!this.filters.includes(e.target.innerText)) {
      e.target.classList.remove("badge-soft-success");
      e.target.classList.add("bg-light");
    }
  }

  TabSearch() {
    let homeFiltered = [];
    let  sideFiltered = [];

    let input: any = document.getElementById(
      "searchContact"
    ) as HTMLAreaElement;
    let filter = input.value.toUpperCase();

    if (filter !== "") {
      this.sortedHomeList.forEach((tab) => {
        if (tab.title.toUpperCase().indexOf(filter) > -1) {
          if (this.filters.length == 0) {
            homeFiltered.push(tab);
          }
        }
      });
      this.sortedHomeListFiltered = homeFiltered;


      this.sortedSideList.forEach((tab) => {
        if (tab.title.toUpperCase().indexOf(filter) > -1) {
          if (this.filters.length == 0) {
            sideFiltered.push(tab);
          }
        }
      });
     this.sortedSideListFiltered = sideFiltered;

    } else {
      this.sortedHomeListFiltered = this.sortedHomeList;
      this.sortedSideListFiltered = this.sortedSideList;

    }
  }


  MenuSearch() {
    let homeMenus=[];
    let sideMenus=[];
    let homeFilteredMenu = [];
    let  sideFilteredMenu = [];

    let input: any = document.getElementById(
      "searchContact"
    ) as HTMLAreaElement;
    let filter = input.value.toUpperCase();

    if (filter !== "") {
      this.sortedHomeMenuList.forEach((menus) => {
         let menuObj ={}
        menus.menus.forEach(menu=> {
          if (menu.menu_name.toUpperCase().indexOf(filter) > -1) {
            if (this.filters.length == 0) {
              homeMenus.push(menu);
            }
          }
        });

        menuObj['name'] = menus.name;
        menuObj['menus'] = homeMenus;

        homeFilteredMenu.push(menuObj)

      });
      this.sortedHomeMenuListFiltered = homeFilteredMenu;


      this.sortedSideMenuList.forEach((menus) => {
        let menuObj ={}
        menus.menus.forEach(menu=> {
          if (menu.menu_name.toUpperCase().indexOf(filter) > -1) {
            if (this.filters.length == 0) {
              sideMenus.push(menu);
            }
          }
        });

        menuObj['name'] = menus.name;
        menuObj['menus'] = sideMenus;
        sideFilteredMenu.push(menuObj)
      });
     this.sortedSideMenuListFiltered = sideFilteredMenu;
    } else {
      this.sortedHomeMenuListFiltered = this.sortedHomeMenuList;
      this.sortedSideMenuListFiltered = this.sortedSideMenuList;
    }
  }


  showOffcanvas() {
    const sideNave = document.querySelector("#side-nave2");
    if (sideNave.classList.contains("animate-go-in")) {
      sideNave.classList.add("animate-leave-out");
      sideNave.classList.remove("animate-go-in");
      this.showCanvas = false;
    } else if (sideNave.classList.contains("animate-leave-out")) {
      sideNave.classList.remove("animate-leave-out");
      sideNave.classList.add("animate-go-in");
      this.showCanvas = true;

      let spans = document.getElementsByName("filters");

      for (var i = 0; i < spans.length; i++) {
        if (!this.filters.includes(spans[i].innerText)) {
          spans[i].classList.remove("subcard");
          spans[i].classList.add("bg-light");
          spans[i].classList.add("enablePointer");
        }
      }
    }
  }

  // selectIcon(icon, type) {
  //   const iconFont = this.builderService.setIcon(icon, type);
  //   this.selectedIcon.emit(iconFont);
  // }

  selectTab(tab, type) {
    this.selectedItemNotify.emit({ data: tab, type });
  }
  selectMenu(menu, type) {
    this.selectedItemNotify.emit({ data: menu, type });
  }

  ngOnDestroy(): void {}
}
