import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-splashbgcolor",
  templateUrl: "./button-splashbgcolor.component.html",
  styleUrls: ["./button-splashbgcolor.component.scss"],
})

export class ButtonSplashBgColorComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService

  ) { }

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() config: any;
  imageConfig: any = {};
  button: MenuButton;
  image: string;
//
  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"
  component_id: string;
  field_id: string;
  start_color: string;
  end_color: string;
  enableCustomGradientColor: boolean = false;
  isAndroid: boolean;
  sync = true;
  colors: any;
  start_dark_color: string;
  end_dark_color: string;
  button_color_light: any = {};
  button_color_dark: any = {};



  ngOnInit() {
    this.field_id = 'button_bgstart_bgend'
    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data)

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton && this.button_id &&
          data.currentButton.button_id == this.button_id
          && data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

           this.buttonFunction(this.button)


          }
        }
    );
  }

 intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.button)
  }

  buttonFunction(button){
    this.isAndroid =  localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
    // this.start_color = this.builderService.getButtonSplashItem(button, 'button_bgstart', this.data.splashTab.param.splash.style);
    // this.end_color = this.builderService.getButtonSplashItem(button, 'button_bgend', this.data.splashTab.param.splash.style);
    if (this.config.setcolor){
      this.colors = this.isAndroid
      ? this.config.scheme_color.color_schema
      : this.config.scheme_color.color_schema_ios;
    }

    if (this.isAndroid) {
      if (
        this.button.button_color_schema &&
        this.button.button_color_schema.schemes &&
        this.button.button_color_schema.schemes.light &&
        this.button.button_color_schema.schemes.dark &&
        this.button.button_color_schema.schemes.light["bgstart"] &&
        this.button.button_color_schema.schemes.light["bgend"] &&
        this.button.button_color_schema.schemes.dark["bgstart"] &&
        this.button.button_color_schema.schemes.dark["bgend"]
      ) {
        this.start_color = this.button.button_color_schema.schemes.light["bgstart"];
        this.end_color = this.button.button_color_schema.schemes.light["bgend"];
        this.start_dark_color = this.button.button_color_schema.schemes.dark["bgstart"];
        this.end_dark_color = this.button.button_color_schema.schemes.dark["bgend"];
        this.enableCustomGradientColor = true;
      }
    } else {
      if (
        this.button.button_color_schema_ios &&
        this.button.button_color_schema_ios.schemes &&
        this.button.button_color_schema_ios.schemes.light &&
        this.button.button_color_schema_ios.schemes.dark &&
        this.button.button_color_schema_ios.schemes.light["bgstart"] &&
        this.button.button_color_schema_ios.schemes.light["bgend"] &&
        this.button.button_color_schema_ios.schemes.dark["bgstart"] &&
        this.button.button_color_schema_ios.schemes.dark["bgend"]
      ) {
        this.start_color = this.button.button_color_schema_ios.schemes.light["bgstart"];
        this.end_color = this.button.button_color_schema_ios.schemes.light["bgend"];
        this.start_dark_color = this.button.button_color_schema_ios.schemes.dark["bgstart"];
        this.end_dark_color = this.button.button_color_schema_ios.schemes.dark["bgend"];
        this.enableCustomGradientColor = true;
      }
    }
  }


  buttonBgColor(item, value, manual, mode ='color') {
    let color = null;
    let onMode : string;
    if (mode =='color'){
      onMode ='colorContainer'
    }else {
    onMode ='color'
    }

    color = (manual == true)?this.isValidHexaCode(value.target.value)? value.target.value :null : value
    if (color){
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.colors = this.isAndroid ? this.config.scheme_color.color_schema : this.config.scheme_color.color_schema_ios;
      let customColor = this.mdw.customColor(color, this.colors.source, mode);
      let onCustomColor = this.mdw.customColor(color, this.colors.source, onMode);

      // console.log(customColor);
      if (item === 'start') {
        this.start_color = customColor.light;
        this.start_dark_color = customColor.dark;

        this.end_color = onCustomColor.light;
        this.end_dark_color = onCustomColor.dark;

        if (this.isAndroid || this.sync) {
          this.button.button_color_schema.schemes.light["bgstart"] = this.start_color;
          this.button.button_color_schema.schemes.dark["bgstart"] = this.start_dark_color;
        }
        if (!this.isAndroid || this.sync) {
          this.button.button_color_schema_ios.schemes.light["bgstart"] = this.start_color;
          this.button.button_color_schema_ios.schemes.dark["bgstart"] = this.start_dark_color;
        }

        if (this.isAndroid || this.sync) {
          this.button.button_color_schema.schemes.light["bgend"] = this.end_color;
          this.button.button_color_schema.schemes.dark["bgend"] = this.end_dark_color;
        }
        if (!this.isAndroid || this.sync) {
          this.button.button_color_schema_ios.schemes.light["bgend"] = this.end_color;
          this.button.button_color_schema_ios.schemes.dark["bgend"] = this.end_dark_color;
        }
      }

      if (item === 'end') {
        this.end_color = customColor.light;
        this.end_dark_color = customColor.dark;

        this.start_color = onCustomColor.light;
        this.start_dark_color = onCustomColor.dark;

        if (this.isAndroid || this.sync) {
          this.button.button_color_schema.schemes.light["bgend"] = this.end_color;
          this.button.button_color_schema.schemes.dark["bgend"] = this.end_dark_color;
        }
        if (!this.isAndroid || this.sync) {
          this.button.button_color_schema_ios.schemes.light["bgend"] = this.end_color;
          this.button.button_color_schema_ios.schemes.dark["bgend"] = this.end_dark_color;
        }

        if (this.isAndroid || this.sync) {
          this.button.button_color_schema.schemes.light["bgstart"] = this.start_color;
          this.button.button_color_schema.schemes.dark["bgstart"] = this.start_dark_color;
        }
        if (!this.isAndroid || this.sync) {
          this.button.button_color_schema_ios.schemes.light["bgstart"] = this.start_color;
          this.button.button_color_schema_ios.schemes.dark["bgstart"] = this.start_dark_color;
        }

      }
      this.updateButtonData()

    }

  }

  isValidHexaCode(str) {
    // Regex to check valid
    // hexadecimalColor_code
    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

    // if str
    // is empty return false
    if (str == null) {
        return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
        return true;
    }
    else {
        return false;
    }
}

toggleGradientColor(event) {
  this.enableCustomGradientColor = event;
  if (this.isAndroid || this.sync) {
    /// Android Settings
    if (this.enableCustomGradientColor) {
      this.start_color = this.colors.schemes.light[this.config.init_start];
      this.start_dark_color = this.colors.schemes.dark[this.config.init_start];
      this.end_color = this.colors.schemes.light[this.config.init_end];
      this.end_dark_color = this.colors.schemes.dark[this.config.init_end];
      if (
        this.button.button_color_schema &&
        this.button.button_color_schema.schemes
      ) {
        const schemes = this.button.button_color_schema.schemes;
        this.button_color_light = schemes.light;
        this.button_color_light = {
          ...this.button_color_light,
          bgstart: this.start_color,
          bgend: this.end_color,
        };
        this.button_color_dark = schemes.dark;
        this.button_color_dark = {
          ...this.button_color_dark,
          bgstart: this.start_dark_color,
          bgend: this.end_dark_color,
        };
        this.button.button_color_schema.schemes = {
          light: { ...this.button_color_light },
          dark: { ...this.button_color_dark },
        };
      } else {
        this.button = {
          ...this.button,
          button_color_schema: {
            schemes: {
              light: {
                bgstart: this.start_color,
                bgend: this.end_color,
              },
              dark: {
                bgstart: this.start_dark_color,
                bgend: this.end_dark_color,
              },
            },
          },
        };
      }
    } else {
      this.start_color = null;
      this.start_dark_color = null;
      this.end_color = null;
      this.end_dark_color = null;
      delete this.button.button_color_schema.schemes.light["bgstart"];
      delete this.button.button_color_schema.schemes.light["bgend"];
      delete this.button.button_color_schema.schemes.dark["bgstart"];
      delete this.button.button_color_schema.schemes.dark["bgend"];
    }
  }

  if (!this.isAndroid || this.sync) {
    // IOS SETTINGS
    if (this.enableCustomGradientColor) {
      this.start_color = this.colors.schemes.light[this.config.init_start_ios];
      this.start_dark_color = this.colors.schemes.dark[this.config.init_start_ios];
      this.end_color = this.colors.schemes.light[this.config.init_end_ios];
      this.end_dark_color = this.colors.schemes.dark[this.config.init_end_ios];

      if (
        this.button.button_color_schema_ios &&
        this.button.button_color_schema_ios.schemes
      ) {
        const schemes = this.button.button_color_schema_ios.schemes;
        this.button_color_light = schemes.light;
        this.button_color_light = {
          ...this.button_color_light,
          bgstart: this.start_color,
          bgend: this.end_color,
        };
        this.button_color_dark = schemes.dark;
        this.button_color_dark = {
          ...this.button_color_dark,
          bgstart: this.start_dark_color,
          bgend: this.end_dark_color,
        };
        this.button.button_color_schema_ios.schemes = {
          light: { ...this.button_color_light },
          dark: { ...this.button_color_dark },
        };
      } else {
        this.button = {
          ...this.button,
          button_color_schema_ios: {
            schemes: {
              light: {
                bgstart: this.start_color,
                bgend: this.end_color,
              },
              dark: {
                bgstart: this.start_dark_color,
                bgend: this.end_dark_color,
              },
            },
          },
        };
      }
    } else {
      this.start_color = null;
      this.start_dark_color = null;
      this.end_color = null;
      this.end_dark_color = null;
      delete this.button.button_color_schema_ios.schemes.light["bgstart"];
      delete this.button.button_color_schema_ios.schemes.light["bgend"];
      delete this.button.button_color_schema_ios.schemes.dark["bgstart"];
      delete this.button.button_color_schema_ios.schemes.dark["bgend"];
    }
  }
  this.updateButtonData();
}

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

   this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );

  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
