<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form class="needs-validation w-100" [formGroup]="callbackForm">
      <div class="mb-3">
        <label class="offcanvas-header-text w-100" style="text-align: left">
          Cell ID
        </label>
        <input
          type="text"
          id="lablecolor"
          class="form-control input-lg offcanvas-primary border-0"
          formControlName="buttin_id"
          placeholder="Label"
          readonly
        />
      </div>
      <div>
        <label class="offcanvas-header-text w-100" style="text-align: left">
          Callback ID
        </label>
        <input
          type="text"
          id="lablecolor"
          class="form-control input-lg offcanvas-primary border-0"
          formControlName="buttin_callback"
          placeholder="Label"
          [ngClass]="{ 'is-invalid': f.buttin_callback.errors }"
          (change)="callBackIDChange()"
        />
        <div *ngIf="f.buttin_callback.errors" class="invalid-feedback">
          <span *ngIf="f.buttin_callback.errors.required">
            Field is required
          </span>
        </div>
      </div>
    </form>
  </div>
</div>
