<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form class="needs-validation w-100" [formGroup]="labelform">
      <div class="row mb-4">
        <div [ngClass]="config?.step ? 'col-4' : 'col-6'">
          <label
            for="text-color"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            Min Value
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="number"
              id="lablecolor"
              formControlName="min"
              class="form-control input-lg offcanvas-primary border-0"
              [value]="button_text"
              (change)="buttonLabel('button_min', 'min')"
              placeholder="Label"
              [ngClass]="{ 'is-invalid': f.min.errors }"
            />
            <div *ngIf="f.min.errors" class="invalid-feedback">
              <span *ngIf="f.min.errors.required"> Field is required </span>
              <span *ngIf="f.min.errors.maxlength">
                Actual length is
                {{ f.min.errors.maxlength.actualLength }} required not more than
                {{ f.min.errors.maxlength.requiredLength }}
                chars.
              </span>
            </div>
          </div>
        </div>
        <div [ngClass]="config?.step ? 'col-4' : 'col-6'">
          <label
            for="text-color"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            Max Value
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="number"
              id="lablecolor"
              formControlName="max"
              class="form-control input-lg offcanvas-primary border-0"
              [value]="button_text"
              (change)="buttonLabel('button_max', 'max')"
              placeholder="Label"
              [ngClass]="{ 'is-invalid': f.max.errors }"
            />
            <div *ngIf="f.max.errors" class="invalid-feedback">
              <span *ngIf="f.max.errors.required"> Field is required </span>
              <span *ngIf="f.max.errors.maxlength">
                Actual length is
                {{ f.max.errors.maxlength.actualLength }} required not more than
                {{ f.max.errors.maxlength.requiredLength }}
                chars.
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="config?.step" [ngClass]="config?.step ? 'col-4' : 'col-6'">
          <label
            for="text-color"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            Step
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="number"
              id="lablecolor"
              formControlName="step"
              class="form-control input-lg offcanvas-primary border-0"
              [value]="button_text"
              (change)="buttonLabel('button_step', 'step')"
              placeholder="Label"
              [ngClass]="{ 'is-invalid': f.step.errors }"
            />
            <div *ngIf="f.step.errors" class="invalid-feedback">
              <span *ngIf="f.step.errors.required"> Field is required </span>
              <span *ngIf="f.step.errors.maxlength">
                Actual length is
                {{ f.step.errors.maxlength.actualLength }} required not more
                than
                {{ f.step.errors.maxlength.requiredLength }}
                chars.
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
