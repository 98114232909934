import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-slider-component",
  templateUrl: "./button-slider-component.component.html",
  styleUrls: ["./button-slider-component.component.scss"],
})
export class ButtonSliderComponentComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  labelform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() config: any;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;
  currentMenuSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_text: string;
  row_id: string;
  labelColor: string;
  black_color: string = "#000000";
  enableCustomColor: boolean = false;
  enableCustomGradientColor: boolean = false;

  button_label_color: string;
  button_label_style: any;
  button_button_divider: number;

  data: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  modalRef?: BsModalRef;
  icon: any;
  trailing_icon: any;
  iconType: number;
  fontSize = "md";
  density = 0;
  textAlign = "left";
  isAndroid: boolean;
  light: boolean;
  text_id: any;
  colors: any;
  labelDarkColor: any;
  button_color_item: any = { schemes: { light: {}, dark: {} } };
  button_color_light: any = {};
  button_color_dark: any = {};
  sync = true;
  os_name: string;
  fontID: string;
  alginID: string;
  densityID: string;

  startColor: string;
  startDarkColor: string;
  endColor: string;
  endDarkColor: string;
  divider: number;


  ngOnInit() {
    this.field_id = this.config.id;
    this.fontID = this.mdw.makeRef(16);
    this.densityID = this.mdw.makeRef(16);
    this.alginID = this.mdw.makeRef(16);
    this.labelform = this.formBuilder.group({
      min: new UntypedFormControl(0),
      max: new UntypedFormControl(100),
      step: new UntypedFormControl(1),
    });

    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&   data.off_canvas_key === "button" &&  data.currentButton &&
          this.button_id &&   data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          this.icon;
          this.buttonFunction();
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  buttonFunction() {
    if (this.button) {
      this.field_id = this.config.id;
      this.isAndroid =  localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.os_name = this.sync ? null : this.isAndroid ? "Android" : "iOS";

      if (this.config.setcolor){
        this.colors = this.isAndroid
        ? this.config.scheme_color.color_schema
        : this.config.scheme_color.color_schema_ios;
      }
      if (this.isAndroid) {

      } else {

      }

      this.labelform = this.formBuilder.group({
        min: new UntypedFormControl(this.button.button_min || 0),
        max: new UntypedFormControl(this.button.button_max ||100),
        step: new UntypedFormControl(this.button.button_step ||1),
      });
    }
  }

  buttonLabel(key, value) {
    if (this.labelform.valid) {
      this.button[key] = this.f[value].value;
      this.updateButtonData();
    }
  }

  get f() {
    if (this.labelform && this.labelform.controls) {
      return this.labelform.controls;
    }
  }

  toggle(event) {
    this.enableCustomColor = event;

    if (this.isAndroid || this.sync) {
      /// Android Settings
      if (this.enableCustomColor) {
        this.labelColor = this.colors.schemes.light[this.config.init_color];
        this.labelDarkColor = this.colors.schemes.dark[this.config.init_color];
        if (
          this.button.button_color_schema &&
          this.button.button_color_schema.schemes
        ) {
          const schemes = this.button.button_color_schema.schemes;
          this.button_color_light = schemes.light;
          this.button_color_light = {
            ...this.button_color_light,
            [this.config.color]: this.labelColor,
          };
          this.button_color_dark = schemes.dark;
          this.button_color_dark = {
            ...this.button_color_dark,
            [this.config.color]: this.labelDarkColor,
          };
          this.button.button_color_schema.schemes = {
            light: { ...this.button_color_light },
            dark: { ...this.button_color_dark },
          };
        } else {
          this.button = {
            ...this.button,
            button_color_schema: {
              schemes: {
                light: { [this.config.color]: this.labelColor },
                dark: { [this.config.color]: this.labelDarkColor },
              },
            },
          };
        }
      } else {
        this.labelColor = null;
        this.labelDarkColor = null;
        delete this.button.button_color_schema.schemes.light[this.config.color];
        delete this.button.button_color_schema.schemes.dark[this.config.color];
      }
    }

    if (!this.isAndroid || this.sync) {
      // IOS SETTINGS
      if (this.enableCustomColor) {
        this.labelColor = this.colors.schemes.light[this.config.init_color_ios];
        this.labelDarkColor =
          this.colors.schemes.dark[this.config.init_color_ios];

        if (
          this.button.button_color_schema_ios &&
          this.button.button_color_schema_ios.schemes
        ) {
          const schemes = this.button.button_color_schema_ios.schemes;
          this.button_color_light = schemes.light;
          this.button_color_light = {
            ...this.button_color_light,
            [this.config.color]: this.labelColor,
          };
          this.button_color_dark = schemes.dark;
          this.button_color_dark = {
            ...this.button_color_dark,
            [this.config.color]: this.labelDarkColor,
          };
          this.button.button_color_schema_ios.schemes = {
            light: { ...this.button_color_light },
            dark: { ...this.button_color_dark },
          };
        } else {
          this.button = {
            ...this.button,
            button_color_schema_ios: {
              schemes: {
                light: { [this.config.color]: this.labelColor },
                dark: { [this.config.color]: this.labelDarkColor },
              },
            },
          };
        }
      } else {
        this.labelColor = null;
        this.labelDarkColor = null;
        delete this.button.button_color_schema_ios.schemes.light[
          this.config.color
        ];
        delete this.button.button_color_schema_ios.schemes.dark[
          this.config.color
        ];
      }
    }
    this.updateButtonData();
  }

  toggleGradientColor(event) {
    this.enableCustomGradientColor = event;

    if (this.isAndroid || this.sync) {
      /// Android Settings
      if (this.enableCustomGradientColor) {
        this.startColor = this.colors.schemes.light[this.config.init_start];
        this.startDarkColor = this.colors.schemes.dark[this.config.init_start];
        this.endColor = this.colors.schemes.light[this.config.init_end];
        this.endDarkColor = this.colors.schemes.dark[this.config.init_end];
        if (
          this.button.button_color_schema &&
          this.button.button_color_schema.schemes
        ) {
          const schemes = this.button.button_color_schema.schemes;
          this.button_color_light = schemes.light;
          this.button_color_light = {
            ...this.button_color_light,
            bgstart: this.startColor,
            bgend: this.endColor,
          };
          this.button_color_dark = schemes.dark;
          this.button_color_dark = {
            ...this.button_color_dark,
            bgstart: this.startDarkColor,
            bgend: this.endDarkColor,
          };
          this.button.button_color_schema.schemes = {
            light: { ...this.button_color_light },
            dark: { ...this.button_color_dark },
          };
        } else {
          this.button = {
            ...this.button,
            button_color_schema: {
              schemes: {
                light: {
                  bgstart: this.startColor,
                  bgend: this.endColor,
                },
                dark: {
                  bgstart: this.startDarkColor,
                  bgend: this.endDarkColor,
                },
              },
            },
          };
        }
      } else {
        this.startColor = null;
        this.labelDarkColor = null;
        this.endColor = null;
        this.endDarkColor = null;
        delete this.button.button_color_schema.schemes.light["bgstart"];
        delete this.button.button_color_schema.schemes.light["bgend"];
        delete this.button.button_color_schema.schemes.dark["bgstart"];
        delete this.button.button_color_schema.schemes.dark["bgend"];
      }
    }

    if (!this.isAndroid || this.sync) {
      // IOS SETTINGS
      if (this.enableCustomGradientColor) {
        this.startColor = this.colors.schemes.light[this.config.init_start_ios];
        this.startDarkColor =
          this.colors.schemes.dark[this.config.init_start_ios];
        this.endColor = this.colors.schemes.light[this.config.init_end_ios];
        this.endDarkColor = this.colors.schemes.dark[this.config.init_end_ios];

        if (
          this.button.button_color_schema_ios &&
          this.button.button_color_schema_ios.schemes
        ) {
          const schemes = this.button.button_color_schema_ios.schemes;
          this.button_color_light = schemes.light;
          this.button_color_light = {
            ...this.button_color_light,
            bgstart: this.startColor,
            bgend: this.endColor,
          };
          this.button_color_dark = schemes.dark;
          this.button_color_dark = {
            ...this.button_color_dark,
            bgstart: this.startDarkColor,
            bgend: this.endDarkColor,
          };
          this.button.button_color_schema_ios.schemes = {
            light: { ...this.button_color_light },
            dark: { ...this.button_color_dark },
          };
        } else {
          this.button = {
            ...this.button,
            button_color_schema_ios: {
              schemes: {
                light: {
                  bgstart: this.startColor,
                  bgend: this.endColor,
                },
                dark: {
                  bgstart: this.startDarkColor,
                  bgend: this.endDarkColor,
                },
              },
            },
          };
        }
      } else {
        this.startColor = null;
        this.labelDarkColor = null;
        this.endColor = null;
        this.endDarkColor = null;
        delete this.button.button_color_schema_ios.schemes.light["bgstart"];
        delete this.button.button_color_schema_ios.schemes.light["bgend"];
        delete this.button.button_color_schema_ios.schemes.dark["bgstart"];
        delete this.button.button_color_schema_ios.schemes.dark["bgend"];
      }
    }
    this.updateButtonData();
  }

  colorChange(value, manual) {
    let color = null;
    color =
      manual == true
        ? this.mdw.isValidHexaCode(value.target.value)
          ? value.target.value
          : null
        : value;
    if (color) {
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.colors = this.isAndroid
        ? this.config.scheme_color.color_schema
        : this.config.scheme_color.color_schema_ios;
      let customColor = this.mdw.customColor(color, this.colors.source);
      this.labelColor = customColor.light;
      this.labelDarkColor = customColor.dark;

      if (this.isAndroid || this.sync) {
        this.button.button_color_schema.schemes.light[this.config.color] =
          this.labelColor;
        this.button.button_color_schema.schemes.dark[this.config.color] =
          this.labelDarkColor;
      }
      if (!this.isAndroid || this.sync) {
        this.button.button_color_schema_ios.schemes.light[this.config.color] =
          this.labelColor;
        this.button.button_color_schema_ios.schemes.dark[this.config.color] =
          this.labelDarkColor;
      }
      this.updateButtonData();
    }
  }

  startColorChange(value, manual) {
    let color = null;
    color =
      manual == true
        ? this.mdw.isValidHexaCode(value.target.value)
          ? value.target.value
          : null
        : value;
    if (color) {
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.colors = this.isAndroid
        ? this.config.scheme_color.color_schema
        : this.config.scheme_color.color_schema_ios;
      let customColor = this.mdw.customColor(color, this.colors.source);
      this.startColor = customColor.light;
      this.startDarkColor = customColor.dark;

      if (this.isAndroid || this.sync) {
        this.button.button_color_schema.schemes.light["bgstart"] =
          this.startColor;
        this.button.button_color_schema.schemes.dark["bgstart"] =
          this.startDarkColor;
      }
      if (!this.isAndroid || this.sync) {
        this.button.button_color_schema_ios.schemes.light["bgstart"] =
          this.startColor;
        this.button.button_color_schema_ios.schemes.dark["bgstart"] =
          this.startDarkColor;
      }
      this.updateButtonData();
    }
  }

  endColorChange(value, manual) {
    let color = null;
    color =
      manual == true
        ? this.mdw.isValidHexaCode(value.target.value)
          ? value.target.value
          : null
        : value;
    if (color) {
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.colors = this.isAndroid
        ? this.config.scheme_color.color_schema
        : this.config.scheme_color.color_schema_ios;
      let customColor = this.mdw.customColor(color, this.colors.source);
      this.endColor = customColor.light;
      this.endDarkColor = customColor.dark;

      if (this.isAndroid || this.sync) {
        this.button.button_color_schema.schemes.light["bgend"] = this.endColor;
        this.button.button_color_schema.schemes.dark["bgend"] =
          this.endDarkColor;
      }
      if (!this.isAndroid || this.sync) {
        this.button.button_color_schema_ios.schemes.light["bgend"] =
          this.endColor;
        this.button.button_color_schema_ios.schemes.dark["bgend"] =
          this.endDarkColor;
      }
      this.updateButtonData();
    }
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );
  }

  openSelector(exlargeModal: any, type) {
    this.iconType = type;
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  iconChange(event) {
    if (this.iconType) {
      this.trailing_icon = this.builderService.getIcon(event);
      if (this.isAndroid || this.sync) {
        this.button.button_trailing_icon = event;
      }

      if (!this.isAndroid || this.sync) {
        this.button.button_trailing_icon_ios = event;
      }
    } else {
      this.icon = this.builderService.getIcon(event);
      if (this.isAndroid || this.sync) {
        this.button.button_icon = event;
      }
      if (!this.isAndroid || this.sync) {
        this.button.button_icon_ios = event;
      }
    }
    this.updateButtonData();
    this.modalRef.hide();
  }

  updateFontSize(fontSize) {
    this.fontSize = fontSize;
    if (this.isAndroid || this.sync) {
      this.button = {
        ...this.button,
        button_text_fontsize: {
          ...this.button.button_text_fontsize,
          [this.config.font_key]: fontSize,
        },
      };
    }
    if (!this.isAndroid || this.sync) {
      this.button = {
        ...this.button,
        button_text_fontsize_ios: {
          ...this.button.button_text_fontsize_ios,
          [this.config.font_key]: fontSize,
        },
      };
    }
    this.updateButtonData();
  }
  updateSegmentHeight(density) {
    this.density = density;
    if (this.isAndroid || this.sync) {
      this.button.button_density = density;
    }
    if (!this.isAndroid || this.sync) {
      this.button.button_density = density;
    }
    this.updateButtonData();
  }

  updateLapleAlignmet(alignment) {
    this.textAlign = alignment;
    if (this.isAndroid || this.sync) {
      this.button = {
        ...this.button,
        button_text_align: {
          ...this.button.button_text_align,
          [this.config.align_key]: this.textAlign,
        },
      };
      // this.button.button_text_align[this.text_id] = this.textAlign;
    }
    if (!this.isAndroid || this.sync) {
      this.button = {
        ...this.button,
        button_text_align_ios: {
          ...this.button.button_text_align_ios,
          [this.config.align_key]: this.textAlign,
        },
      };
      // this.button.button_text_align_ios[this.text_id] = this.textAlign;
    }
    this.updateButtonData();
  }

  toggleDivider(value) {
    const status = value.target.checked;
    if (status) {
      if (this.isAndroid || this.sync) {
        this.button.button_divider = 1;
        this.divider = 1;
      }
      if (!this.isAndroid || this.sync) {
        this.button.button_divider_ios = 1;
        this.divider = 1;
      }
    } else {
      if (this.isAndroid || this.sync) {
        this.button.button_divider = 0;
        this.divider = 0;
      }
      if (!this.isAndroid || this.sync) {
        this.button.button_divider_ios = 0;
        this.divider = 0;
      }
    }

    this.updateButtonData();
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
