import { Tab } from "../../core/services/interface";
import { ModalModule } from "ngx-bootstrap/modal";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { TabNames, c1, c16, common_components_map } from "src/app/core/services/constants";
import { MiddlwareService } from "src/app/core/services/middleware.service";

@Component({
  selector: "app-mobile-view-slider",
  templateUrl: "./mobile-view-slider.component.html",
  styleUrls: ["./mobile-view-slider.component.scss"],
})
export class MobileViewSliderComponent implements OnInit {
  updateBuilderComponentSubscription: any;
  tabNames = TabNames;
  mode;
  mobileViewModeMap;
  inputData;
  image: string;
  tab_image: string;
  start_color: string;
  end_color: string;
  bg_color: string;
  tab_bgimage: string;

  @Input() inputTab: any;
  @Input() fontSmaller: boolean = true;
  @Input() inputMenuId: string;
  @Input() staticMenuId: string
  @Input () enableStatic = false;
  @Input () staticTab : any;
  @Input() inputMenu: any =null;
  @Input() colors: any={};
  menu_id: string;
  tab: any;
  isInputTab: boolean = false;
  fontResize = true;
  isIOS: boolean;
  colorsComponentSubscription: any;
  getAppConfigSubscription: any;

  color_schema: any;
  color_schema_ios: any;
  light: {};
  dark: {};
isAndroid: boolean;

  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public ref: ChangeDetectorRef
  ) {}



  ngOnInit() {
    this.menu_id = this.inputMenuId;
    this.mobileViewModeMap = common_components_map;

    this.getAppConfigSubscription = this.mdw.getAppConfigContainer$.subscribe(async (data) => {
      if (data) {
        try {
          await this.colorInitialize();
        } catch (error) {
            setTimeout(async () => {
            await this.colorInitialize()
          }, 1000);
        }
      }
    });



    this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe((data) => {
      if (data  && data.color_schema  && data.color_schema_ios && data.light !=null){
       this.colors = data;
       this.light = data.light
       this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
       this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
       this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDROID? true: false
       if (this.isAndroid) {
         this.bg_color = this.color_schema.m2CSurface;
       } else {
         this.bg_color = this.color_schema_ios.surface;
       }
     }
   })


    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe(async (data) => {
        if (data) {
          if (this.inputMenuId && data.tab && (data.tab.module == "menu" || data.tab.module == "page"|| data.tab.module == "splash" || data.tab.module == "store"|| data.tab.module == "market"|| data.tab.module == "poll" )) {
            // this.inputTab = null;
            this.inputData = data.tab;
            this.menu_id = this.inputMenuId;
          }else
          if (this.inputTab) {
            this.inputData = this.inputTab;
             this.menu_id = null;
           }
          else {
            this.inputData = data.tab;
         }
          // this.image = "./assets/images/mobile-view-builder-item-empty-s.webp" ;

          this.image = this.mdw.mobileTheme(this.inputData).emptyImage

          this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDROID? true: false
          await this.colorInitialize();
          this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDROID? true: false
          this.tabBaground(this.inputData)
        }

      });
  }

  tabBaground(tab) {
    if (tab&& tab.tab_bg && tab.tab_bg.image) {
      this.tab_bgimage = tab.tab_bg.image;
    } else if (tab&& tab.tab_bg) {
      this.start_color = tab.tab_bg.start
        ? tab.tab_bg.start
        : null;
      this.end_color = tab.tab_bg.end
        ? tab.tab_bg.end
        : null;

      if (!this.end_color && this.start_color) {
        this.bg_color = this.start_color;
      } else if (!this.start_color && this.end_color) {
        this.bg_color = this.end_color;
      }
    } else {
      this.tab_bgimage = null;
      this.start_color = null;
      this.end_color = null;
    }
  }
colorInitialize() {
    const appHome = this.indexDBService.getItem("app", 'home');
    appHome.onsuccess = async (event) => {
      if (appHome.result) {
        let colors = {}

        colors['color_schema'] = appHome.result.app.tabs.color_schema;
        colors['color_schema_ios'] = appHome.result.app.tabs.color_schema_ios;
        colors['light'] = localStorage.getItem("mode")[15]==c16.LIGHT? true:false
        this.colors = colors;

        this.light = this.colors['light'];
        this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
        this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark

        this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDROID? true: false

          if (this.isAndroid) {
            this.bg_color = this.color_schema.m2CSurface;
          } else {
            this.bg_color = this.color_schema_ios.surface;
          }

        } } }



      get setTabBg() {
        if (this.tab_bgimage) {
          return {
            "background-image": `url(${this.tab_bgimage})`,
            "background-position": "center",
            "background-repeat": "no-repeat",
            "background-size": "cover",
          };
        } else if (this.start_color && this.end_color) {
          return {
            "background-image": `linear-gradient(90deg, ${this.start_color}, ${this.end_color})`,
          };
        } else if (this.bg_color) {
          return { background: this.bg_color };
        }
        return { background: "unset" };
      }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }

    if (this.getAppConfigSubscription) {
      this.getAppConfigSubscription.unsubscribe();
    }
  }
}
