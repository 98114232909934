<div class="not-allowed">
  <div *ngIf="isAndroid">
    <div
      class="card m-0 rounded bg-transparent p-0 shadow-none"
    >
      <img [src]="emptyImage" class="card-img" alt="empty" [style]="'height: ' + cellHeight" [ngStyle]="{'height': fontSmaller ? '16px': '38px'}">
      <div class="card-img-overlay p-0 d-flex justify-content-center">
        <md-slider labeled [range]="range" [ticks]="ticks" [step]="step" [min]="minValue" [max]="maxValue" [valueStart]="valueStart" [valueEnd]="valueEnd" [value]="sliderValue" class="w-100" style="cursor: not-allowed; pointer-events: none !important;" [ngStyle]="colorsMain" [ngClass]="fontSmaller ? 'position-absolute' : ''"></md-slider>
      </div>
    </div>
  </div>
  <div *ngIf="!isAndroid">
    <div
      class="card m-0 rounded bg-transparent p-0 shadow-none"
    >
      <img [src]="emptyImage" class="card-img" alt="empty" [style]="'height: ' + cellHeight" [ngStyle]="{'height': fontSmaller ? '16px': '38px'}">
      <div class="card-img-overlay p-0 d-flex justify-content-center">
        <md-slider labeled [range]="range" [ticks]="ticks" [step]="step" [min]="minValue" [max]="maxValue" [valueStart]="valueStart" [valueEnd]="valueEnd" [value]="sliderValue" class="w-100" style="cursor: not-allowed; pointer-events: none !important;" [ngStyle]="colorsMain" [ngClass]="fontSmaller ? 'position-absolute' : ''"></md-slider>
      </div>
    </div>
  </div>
</div>
