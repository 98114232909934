<div >
<div class="card p-0 m-0" *ngIf="!makeItSmaller" >
  <img [src]="theme?.emptyImageNavBar" class="card-img" alt="empty" [ngStyle]="backColors">
  <div class="card-img-overlay p-0">
    <div class="row p-0">
      <div class="col p-2" *ngFor="let tab of sortedTabList let index = index ; trackBy:tabTrackBy">
        <div class="d-flex align-items-center justify-content-center flex-column" [ngStyle]=" iconStyle(tab.id)" >
          <i  class="m-auto text-nowrap font-size-14"  [class.font-smaller]="false" *ngIf="tab?.icon && (wide || !tab.title || (tab.title && !top ))" [class]="builderService?.getIcon(tab?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >
            {{builderService?.getIcon(tab?.icon).id}}
          </i>
          <ng-container *ngIf="tab?.title && (wide || !tab.icon || (tab.icon && top))" >
            <div class=" text-truncate text-nowrap  m-auto" [class.font-smaller]="true" style="max-width: 90%" [ngStyle]="titleStyle(tab.id)">
              {{ tab?.title }}
            </div>
            </ng-container>
        </div>
      </div>
    </div>
</div>
</div>
<div class="card p-0 m-0 " *ngIf="makeItSmaller" >
  <img [src]="theme?.emptyImageNavBar" class="card-img" alt="empty" [ngStyle]="backColors">
  <div class="card-img-overlay p-0">
    <div class="row p-0">
      <div class="col-1 p-2"></div>
      <div class="col-2 p-2" *ngFor="let tab of sortedTabList let index = index ; trackBy:tabTrackBy">
        <div class="d-flex align-items-center justify-content-center flex-column" [ngStyle]=" iconStyle(tab.id)" >
          <i  class="m-auto text-nowrap font-size-12" [class.font-smaller]="false" *ngIf="tab?.icon && (wide || !tab.title || (tab.title && !top ))" [class]="builderService?.getIcon(tab?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >
            {{builderService?.getIcon(tab?.icon).id}}
          </i>
          <ng-container *ngIf="tab?.title && (wide || !tab.icon || (tab.icon && top))">
            <div class="text-truncate text-nowrap m-auto" [class.font-smaller]="true" style="max-width: 90%" [ngStyle]="titleStyle(tab.id)">
              {{ tab?.title }}
            </div>
            </ng-container>
        </div>
      </div>
      <div class="col-1 p-2" ></div>
    </div>
</div>
</div>
</div>
