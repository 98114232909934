<div class="p-0 w-100 d-flex align-items-center justify-content-center">
  <div
    class="card p-0 m-0 position-relative shadow-none w-100"
    style="max-width: 560px"
  >
    <img [src]="image" class="card-img" alt="empty" />
    <div
      class="card-img-overlay p-0"
      [ngClass]="fontSmaller ? '' : 'rounded-3'"
    >
      <div class="position-absolute start-0 end-0">
        <div class="row m-0 p-0" [ngStyle]="bgColor">
          <div class="position-relative p-0">
            <img
              src="./assets/images/mobile/splash10.webp"
              class="card-img"
              alt="empty-2"
            />
            <div class="card-img-overlay p-0">
              <div class="row m-0 p-0 px-1 h-100 align-items-center">
                <div class="col-10 p-0 chat-user-box h-100">
                  <div class="d-flex align-items-center h-100">
                    <div
                      class="flex-shrink-0 me-2 h-100 d-flex align-items-center"
                    >
                      <img
                        class="rounded-circle"
                        [src]="channel_image"
                        alt="Generic placeholder image"
                        onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
                        style="max-height: 80%"
                      />
                    </div>
                    <div class="flex-grow-1 chat-user-box">
                      <p class="user-title m-0" [ngStyle]="labelFont">
                        {{ channel_name }}
                      </p>
                      <p class="text-muted m-0" [ngStyle]="labelFont">
                        {{ currentDate | date }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-2 d-flex align-items-center justify-content-center p-0"
                >
                  <i class="mdi mdi-dots-vertical" [ngStyle]="labelFont"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="position-relative p-0">
            <img src="./assets/images/mobile/splash38.webp" class="card-img" alt="empty-2">
            <div class="card-img-overlay p-0"> -->
          <img class="img-fluid p-0" src="./assets/images/post-1.webp" />
          <!-- </div>
          </div> -->

          <div
            class="position-relative p-0"
            *ngIf="!tab?.footer?.hidden && tab?.footer?.action?.length > 0"
          >
            <img
              src="./assets/images/mobile/splash12.webp"
              class="card-img"
              alt="empty-2"
            />
            <div class="card-img-overlay p-0">
              <div
                class="row m-0 p-0 d-flex align-items-center mt-1 border-bottom border-2 border-secondary border-opacity-50 h-100"
              >
                <div class="col px-1 py-2" *ngIf="like">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      class="me-1"
                      [class]="
                        like_color?.type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                      [ngStyle]="likeColor"
                    >
                      {{ like_color?.icon }}
                    </i>
                    <div [ngStyle]="labelFooterFont">29k</div>
                  </div>
                </div>
                <div class="col px-1 py-2" *ngIf="reply">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      class="me-1"
                      [ngStyle]="labelFooterFont"
                      [class]="
                        reply?.type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                    >
                      {{ reply?.icon }}
                    </i>
                    <div [ngStyle]="labelFooterFont">853</div>
                  </div>
                </div>
                <div class="col px-1 py-2" *ngIf="view">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      class="me-1"
                      [ngStyle]="labelFooterFont"
                      [class]="
                        view?.type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                    >
                      {{ view?.icon }}
                    </i>
                    <div [ngStyle]="labelFooterFont">42k</div>
                  </div>
                </div>
                <div class="col px-1 py-2" *ngIf="share">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      class="me-1"
                      [ngStyle]="labelFooterFont"
                      [class]="
                        share?.type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                    >
                      {{ share?.icon }}
                    </i>
                    <div [ngStyle]="labelFooterFont">124</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 p-0" [ngStyle]="bgColor">
          <div class="position-relative p-0">
            <img
              src="./assets/images/mobile/splash10.webp"
              class="card-img"
              alt="empty-2"
            />
            <div class="card-img-overlay p-0">
              <div class="row m-0 p-0 px-1 h-100 align-items-center">
                <div class="col-10 p-0 chat-user-box h-100">
                  <div class="d-flex align-items-center h-100">
                    <div
                      class="flex-shrink-0 me-2 h-100 d-flex align-items-center"
                    >
                      <img
                        class="rounded-circle"
                        [src]="channel_image"
                        alt="Generic placeholder image"
                        onerror="this.onerror=null; this.src='assets/images/users/account.webp'"
                        style="max-height: 80%"
                      />
                    </div>
                    <div class="flex-grow-1 chat-user-box">
                      <p class="user-title m-0" [ngStyle]="labelFont">
                        {{ channel_name }}
                      </p>
                      <p class="text-muted m-0" [ngStyle]="labelFont">
                        {{ currentDate | date }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-2 d-flex align-items-center justify-content-center p-0"
                >
                  <i class="mdi mdi-dots-vertical" [ngStyle]="labelFont"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="position-relative p-0">
            <img src="./assets/images/mobile/splash38.webp" class="card-img" alt="empty-2">
            <div class="card-img-overlay p-0"> -->
          <img class="img-fluid p-0" src="./assets/images/post-2.webp" />
          <!-- </div>
          </div> -->

          <div
            class="position-relative p-0"
            *ngIf="!tab?.footer?.hidden && tab?.footer?.action?.length > 0"
          >
            <img
              src="./assets/images/mobile/splash12.webp"
              class="card-img"
              alt="empty-2"
            />
            <div class="card-img-overlay p-0">
              <div
                class="row m-0 p-0 d-flex align-items-center mt-1 border-bottom border-2 border-secondary border-opacity-50 h-100"
              >
                <div class="col px-1 py-2" *ngIf="like">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      class="me-1"
                      [class]="
                        like_color?.type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                      [ngStyle]="likeColor"
                    >
                      {{ like_color?.icon }}
                    </i>
                    <div [ngStyle]="labelFooterFont">29k</div>
                  </div>
                </div>
                <div class="col px-1 py-2" *ngIf="reply">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      class="me-1"
                      [ngStyle]="labelFooterFont"
                      [class]="
                        reply?.type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                    >
                      {{ reply?.icon }}
                    </i>
                    <div [ngStyle]="labelFooterFont">853</div>
                  </div>
                </div>
                <div class="col px-1 py-2" *ngIf="view">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      class="me-1"
                      [ngStyle]="labelFooterFont"
                      [class]="
                        view?.type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                    >
                      {{ view?.icon }}
                    </i>
                    <div [ngStyle]="labelFooterFont">42k</div>
                  </div>
                </div>
                <div class="col px-1 py-2" *ngIf="share">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      class="me-1"
                      [ngStyle]="labelFooterFont"
                      [class]="
                        share?.type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                    >
                      {{ share?.icon }}
                    </i>
                    <div [ngStyle]="labelFooterFont">124</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="talk_to_admin"
          class="position-absolute rounded-circle bottom-0 end-0 m-3 d-flex align-items-center justify-content-center"
          [ngStyle]="talkAdminColor"
        >
          <i
            class="bx bxs-comment-detail offcanvas-header-text"
            [class.fs-2]="talk_admin.size === 'l'"
            [class.fs-3]="talk_admin.size === 'm'"
            [class.fs-4]="talk_admin.size === 's'"
            id="float-icon"
          ></i>
        </div>
      </div>
    </div>
  </div>
</div>
