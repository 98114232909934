<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="bg-transparent p-0 mb-3">
      <h6 class="offcanvas-header-text text-start">Validations</h6>
    </div>
    <div class="row m-0">
      <div cdkDropList class="col-12 p-0" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let opt of button_option">
          <div
            class="card offcanvas-primary mb-2 flex-row"
            cdkDrag
            cdkDragLockAxis="y"
            [cdkDragDisabled]="selectedSettings"
            *ngIf="
              !selectedSettings ||
              (selectedSettings && selectedSettings.id !== opt.id)
            "
          >
            <div
              class="col-1 d-flex justify-content-center align-items-center border-end offcanvas-outline-variant"
            >
              <i
                class="font-size-20 offcanvas-drag-icon material-icons"
                style="cursor: move"
                >menu</i
              >
            </div>
            <div class="col-11">
              <div class="row m-0 no-gutters align-items-center">
                <div class="col-md-12 p-0">
                  <div class="row m-0">
                    <div
                      class="col-2 d-flex align-items-center justify-content-center"
                      *ngIf="
                        (config?.icon && (opt?.icon || opt?.icon_ios)) ||
                        (opt.image && config?.image)
                      "
                    >
                      <i
                        *ngIf="config?.icon && (opt?.icon || opt?.icon_ios)"
                        class="float-none w-auto m-0 px-1"
                        [class]="
                          getIcon(opt).type == 'mir'
                            ? 'material-icons-outlined'
                            : 'material-icons'
                        "
                      >
                        {{ getIcon(opt).id }}
                      </i>
                      <img
                        *ngIf="opt.image && config?.image"
                        [src]="opt.image"
                        alt="Card image"
                        class="card-img img-fluid object-fit-cover rounded-circle"
                        style="max-height: 60px; max-width: 100%"
                      />
                    </div>
                    <div
                      class="p-0 overflow-hidden"
                      [ngClass]="
                        (config?.icon && (opt?.icon || opt?.icon_ios)) ||
                        (opt.image && config?.image)
                          ? 'col-10 ps-1'
                          : 'col-12 ps-3'
                      "
                    >
                      <div
                        class="card-body position-relative py-3 ps-0 offcanvas-card"
                      >
                        <p
                          class="card-text text-start offcanvas-header-text m-0"
                        >
                          {{
                            opt?.type ? validationName(opt?.type) : "New Item"
                          }}
                        </p>
                        <button
                          class="btn btn-primary offcanvas-header-text p-0 position-absolute option-button"
                          style="
                            width: 32px;
                            height: 32px;
                            top: calc(50% - 16px);
                            right: -32px;
                          "
                          (click)="openSettings(opt)"
                        >
                          <i class="fas fa-cog font-size-14"></i>
                        </button>
                        <button
                          class="btn btn-danger offcanvas-header-text p-0 position-absolute option-button"
                          style="
                            width: 32px;
                            height: 32px;
                            top: calc(50% - 16px);
                            right: -69px;
                          "
                          (click)="deleteImage(opt.id)"
                        >
                          <i class="mdi mdi-delete font-size-14"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card rounded-3 bg-transparent bg-opacity-50 mb-2"
            *ngIf="selectedSettings && selectedSettings.id === opt.id"
            style="border: 1px solid"
          >
            <div class="card-body">
              <form [formGroup]="optionform" (ngSubmit)="saveChanges()">
                <div class="mb-3">
                  <label
                    for="title"
                    class="offcanvas-header-text w-100"
                    style="text-align: left"
                  >
                    Validation Type
                  </label>
                  <select
                    id="selectoption"
                    class="form-select offcanvas-primary ng-pristine ng-valid ng-touched border-0 shadow-none"
                    formControlName="type"
                    (change)="changeStyle()"
                    [ngClass]="{
                      'is-invalid': submitted && f.type.errors
                    }"
                  >
                    <option value="">
                      Select Validation
                    </option>
                    <ng-container *ngFor="let item of validatorTypes">
                      <option [value]="item?.key" [disabled]="disapleOptionifSelected(item)">
                        {{ item?.value }}
                      </option>
                    </ng-container>

                  </select>
                </div>

                <div
                  class="row"
                  *ngIf="
                    optionform?.get('type')?.value == 'range' ||
                    optionform?.get('type')?.value == 'minlength' ||
                    optionform?.get('type')?.value == 'maxlength'
                  "
                >
                  <ng-container *ngIf="inputType !== 'date'">
                    <div
                      class="mb-3 text-start"
                      *ngIf="
                        optionform?.get('type')?.value == 'minlength' ||
                        optionform?.get('type')?.value == 'range'
                      "
                      [ngClass]="
                        optionform?.get('type')?.value == 'range'
                          ? 'col-6'
                          : 'col-12'
                      "
                    >
                      <label
                        for="text-color"
                        class="offcanvas-header-text w-100"
                        style="text-align: left"
                      >
                        {{
                          optionform?.get("type")?.value == "range"
                            ? "Min"
                            : "Min Length"
                        }}
                      </label>
                      <input
                        type="number"
                        id="text-color"
                        class="form-control input-lg offcanvas-primary border-0"
                        formControlName="min"
                        [ngClass]="{
                          'is-invalid': submitted && f.min.errors
                        }"
                      />
                    </div>
                    <div
                      class="mb-3 text-start"
                      *ngIf="
                        optionform?.get('type')?.value == 'maxlength' ||
                        optionform?.get('type')?.value == 'range'
                      "
                      [ngClass]="
                        optionform?.get('type')?.value == 'range'
                          ? 'col-6'
                          : 'col-12'
                      "
                    >
                      <label
                        for="text-color"
                        class="offcanvas-header-text w-100"
                        style="text-align: left"
                      >
                        {{
                          optionform?.get("type")?.value == "range"
                            ? "Max"
                            : "Max Length"
                        }}
                      </label>
                      <input
                        type="number"
                        id="text-color"
                        class="form-control input-lg offcanvas-primary border-0"
                        formControlName="max"
                        [ngClass]="{
                          'is-invalid': submitted && f.max.errors
                        }"
                      />
                    </div>
                  </ng-container>

                  <ng-container *ngIf="inputType == 'date'">
                    <div class="mb-3 text-start col-6">
                      <label
                        for="text-color"
                        class="offcanvas-header-text w-100"
                        style="text-align: left"
                      >
                        Min Date
                      </label>
                      <input
                        class="form-control input-lg offcanvas-primary border-0"
                        [placeholder]="'GENERALS.SELECTDATE' | translate"
                        name="dp"
                        bsDatepicker
                        [bsConfig]="{
                          showWeekNumbers: false,
                          dateInputFormat: 'DD/MM/YYYY',
                          positioning: 'bottom-left'
                        }"
                        autocomplete="off"
                        formControlName="min"
                        [ngClass]="{
                          'is-invalid': submitted && f.min.errors
                        }"
                      />
                    </div>
                    <div class="mb-3 text-start col-6">
                      <label
                        for="text-color"
                        class="offcanvas-header-text w-100"
                        style="text-align: left"
                      >
                        Max Date
                      </label>
                      <input
                        class="form-control input-lg offcanvas-primary border-0"
                        [placeholder]="'GENERALS.SELECTDATE' | translate"
                        name="dp"
                        bsDatepicker
                        [bsConfig]="{
                          showWeekNumbers: false,
                          dateInputFormat: 'DD/MM/YYYY',
                          positioning: 'bottom-left'
                        }"
                        autocomplete="off"
                        formControlName="max"
                        [ngClass]="{
                          'is-invalid': submitted && f.max.errors
                        }"
                      />
                    </div>
                  </ng-container>
                </div>

                <div
                  class="mb-3 text-start"
                  *ngIf="optionform?.get('type')?.value == 'pattern'"
                >
                  <label
                    for="text-color"
                    class="offcanvas-header-text w-100"
                    style="text-align: left"
                  >
                    Pattern
                  </label>
                  <input
                    type="text"
                    id="text-color"
                    class="form-control input-lg offcanvas-primary border-0"
                    formControlName="pattern"
                    [ngClass]="{
                      'is-invalid': submitted && f.pattern.errors
                    }"
                  />
                </div>

                <div class="mb-3">
                  <label
                    for="message"
                    class="offcanvas-header-text w-100"
                    style="text-align: left"
                  >
                    Message
                  </label>
                  <div
                    class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
                    title="Using format option"
                  >
                    <textarea
                      class="form-control input-lg border-0 offcanvas-primary"
                      id="message"
                      formControlName="message"
                      rows="4"
                      [placeholder]="'Enter Message'"
                    ></textarea>
                  </div>
                </div>

                <div
                  class="d-flex flex-wrap justify-content-end align-content-center gap-2"
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="openSettings(null)"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </form>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row m-0 mb-2" *ngIf="!selectedSettings && !checkValidations()">
      <button
        class="btn btn-primary btn-sm shadow-none col-4 offset-8 d-flex align-items-center justify-content-around"
        type="button"
        (click)="addNewSwaper()"
      >
        <i class="mdi mdi-plus font-size-18"></i>
        Add
      </button>
    </div>
    <div>
      <form class="row mt-3" [formGroup]="testform" (ngSubmit)="submitTest()">
        <div class="col-10">
          <input
            *ngIf="inputType !== 'date'"
            [type]="inputType"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0 w-100"
            placeholder="Enter value"
            formControlName="testValue"
            [ngClass]="{
              'is-invalid': testSubmitted && fTest.testValue.errors
            }"
          />
          <input
            *ngIf="inputType === 'date'"
            [placeholder]="'GENERALS.SELECTDATE' | translate"
            name="dp"
            bsDatepicker
            [bsConfig]="{
              showWeekNumbers: false,
              dateInputFormat: 'DD/MM/YYYY',
              positioning: 'bottom-left'
            }"
            autocomplete="off"
            type="text"
            class="form-control input-lg offcanvas-primary border-0 w-100"
            placeholder="Enter value"
            formControlName="testValue"
            [ngClass]="{
              'is-invalid': testSubmitted && fTest.testValue.errors
            }"
          />
          <div
            *ngIf="testSubmitted && fTest.testValue.errors"
            class="invalid-feedback"
          >
            <ng-container *ngFor="let validation of button_option">
              <ng-container *ngIf="validation.type !== 'range'">
                <span *ngIf="fTest.testValue.errors[validation.type]">
                  {{ validation.message }}
                </span>
              </ng-container>
              <ng-container *ngIf="validation.type === 'range'">
                <span *ngIf="fTest.testValue.errors.min">
                  {{ validation.message }}
                </span>
                <span *ngIf="fTest.testValue.errors.max">
                  {{ validation.message }}
                </span>
                <span *ngIf="fTest.testValue.errors.dateRange">
                  {{ validation.message }}
                </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="col-2">
          <button class="btn btn-primary shadow-none" type="submit">
            Test
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
