<div class="card m-0 bg-transparent">
  <div class="card-body">
    <!-- <h5
      *ngIf="config?.title"
      class="card-title text-start offcanvas-header-text mb-3"
    >
      {{ os_name }} Main Settings
    </h5> -->
    <div class="row mb-4">
      <div class="col-12 w-100 order-first">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
          {{ config?.name }}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <form class="needs-validation w-100" [formGroup]="labelform">
            <textarea
              class="form-control input-lg border-0 offcanvas-primary"
              id="description"
              formControlName="lableControl"
              [ngClass]="{ 'is-invalid': f.lableControl.errors }"
              rows="6"
              (blur)="buttonLabel()"
              placeholder="Label"
            ></textarea>
            <div *ngIf="f.lableControl.errors" class="invalid-feedback">
              <span *ngIf="f.lableControl.errors.required">
                Field is required
              </span>
              <span *ngIf="f.lableControl.errors.maxlength">
                Actual length is
                {{ f.lableControl.errors.maxlength.actualLength }} required not
                more than
                {{ f.lableControl.errors.maxlength.requiredLength }}
                chars.
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
