<div [ngClass]="appBuilderPage ? 'appBuilderPage' : ''">
  <router-outlet></router-outlet>
</div>
<tour-step-template>
  <ng-template let-step="step">
    <div
      [style.width]="step.stepDimensions?.width"
      [style.min-width]="step.stepDimensions?.minWidth"
      [style.max-width]="step.stepDimensions?.maxWidth"
      class="main-container m-3"
    >
      <div *ngIf="!step?.useLegacyTitle && step?.title" class="title-container d-flex align-items-center justify-content-between mb-2">
        <span class="font-size-16 fw-bold">{{ step?.title }}</span>
        <div class="progress font-size-15 fw-bold">(Step {{ tourService.steps.indexOf(step) + 1 }} of {{ tourService.steps.length }})</div>
      </div>
      <span class="card-text text-muted font-size-14" [innerHTML]="step?.content"></span>
      <div class="buttons d-flex align-items-center justify-content-between mt-3" [class.no-progress]="!step.showProgress">
        <md-outlined-button (click)="tourService.end()">
          End tour
        </md-outlined-button>
        <md-filled-button
          *ngIf="tourService.hasNext(step) && !step.nextOnAnchorClick"
          class="next"
          (click)="tourService.next()"
        >
          Next
        </md-filled-button>
      </div>
    </div>
  </ng-template>
</tour-step-template>
