<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="bg-transparent p-0 mb-3">
      <h6 class="offcanvas-header-text text-start">Input Type</h6>
    </div>
    <form [formGroup]="form" (ngSubmit)="saveChanges()">
      <div class="mb-3">
        <select
          id="selectoption"
          class="form-select offcanvas-primary ng-pristine ng-valid ng-touched border-0 shadow-none"
          formControlName="valueType"
          (change)="saveChanges()"
        >
          <ng-container *ngFor="let item of typeList">
            <option [value]="item?.key">
              {{ item?.value }}
            </option>
          </ng-container>

        </select>
      </div>
    </form>
  </div>
</div>
