<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form [formGroup]="labelform">
      <div
        *ngIf="config?.style"
        class="row d-flex justify-content-between align-items-center mb-3"
      >
        <div class="col-12 m-0">
          <label
            for="text-color"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            {{ config?.name }}
          </label>
          <select
            id="selectoption"
            class="form-select offcanvas-primary ng-pristine ng-valid ng-touched border-0 shadow-none"
            formControlName="style"
            (change)="changeStyle('style', 0)"
          >
            <option *ngFor="let item of stylesChoices" [value]="item?.value">
              {{ isAndroid ? item?.label : item?.label_ios }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="row d-flex justify-content-between align-items-center mb-3"
        *ngIf="config?.mirror"
      >
        <div
          class="form-check d-flex align-items-center justify-content-between"
        >
          <label for="mirror" class="offcanvas-header-text m-0">
            {{ "GENERALS.MIRROR" | translate }}
          </label>
          <input
            type="checkbox"
            id="mirror"
            class="form-check-input offcanvas-border-primary offcanvas-primary"
            style="width: 18px; height: 18px"
            formControlName="mirror"
            (change)="changeDividerStyle('mirror', 1)"
          />
        </div>
      </div>
      <div
        class="row d-flex justify-content-between align-items-center mb-3"
        *ngIf="config?.divider"
      >
        <div
          class="form-check d-flex align-items-center justify-content-between"
        >
          <label for="divider" class="offcanvas-header-text m-0">
            {{ "GENERALS.DIVIDER" | translate }}
          </label>
          <input
            type="checkbox"
            id="divider"
            class="form-check-input offcanvas-border-primary offcanvas-primary"
            style="width: 18px; height: 18px"
            formControlName="divider"
            (change)="changeDividerStyle('divider', 2)"
          />
        </div>
      </div>
      <div
        *ngIf="config?.card"
        class="row d-flex justify-content-between align-items-center mb-3"
      >
        <div class="col-12 m-0">
          <label
            for="card-type"
            class="offcanvas-header-text w-100"
            style="text-align: left"
          >
            Card Style
          </label>
          <select
            id="card-type"
            class="form-select offcanvas-primary ng-pristine ng-valid ng-touched border-0 shadow-none"
            formControlName="cardType"
            (change)="changeCardStyle(labelform.get('cardType').value)"
          >
            <ng-container *ngFor="let item of card_types">
              <option *ngIf="config?.card_types?.includes(item?.value)" [value]="item?.value">
                {{ isAndroid ? item?.label : item?.label_ios }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
    </form>
  </div>
</div>
