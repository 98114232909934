import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, c16, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Background, MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-image-component",
  templateUrl: "./tab-image-component.component.html",
  styleUrls: ["./tab-image-component.component.scss"],
})
export class TabImageComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  @Input() config: any;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: any;
  icon_ios: any;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  currentTabSubscription: any;
  colorsComponentSubscription: any;

  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;

  off_canvas_key: string = "tab";
  newTab = false;
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  modalRef?: BsModalRef;
  isAndroid: boolean;
  data: any;
  tabList = {};
  sortedTabList = [];
  current_tab_id;
  component_id: string;
  field_id: string;
  enableCustomColor = false;
  enableCustomColorIOS = false;
  sync;
  labelColor;
  labelDarkColor;
  labelColorIOS;
  labelDarkColorIOS;
  light: boolean = true;
  colors: any = {};
  color_schema: any;
  color_schema_ios: any;
  imageConfig: any = {};
  imageDarkConfig: any = {};
  row_id: string;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  bgimage: string = null;
  toggleDarkImage = false;
  start_color: string;
  end_color: string;
  enableBackgraund: boolean;

  ngOnInit() {
    this.field_id = "tab_image";

    this.imageConfig = {
      id: this.field_id,
      title: this.config && this.config.title ? this.config.title : "Image",
      max: this.config && this.config.max ? this.config.max : 1,
      aspectRatio:
        this.config && this.config.aspectRatio
          ? this.config.aspectRatio
          : "1x1",
      resizeToWidth:
        this.config && this.config.resizeToWidth
          ? this.config.resizeToWidth
          : 260,
      resizeToHeight:
        this.config && this.config.resizeToHeight
          ? this.config.resizeToHeight
          : 260,
      cropperView:
        this.config && this.config.cropperView
          ? this.config.cropperView
          : false,
      selectedImages: [],
      removeButton: true,
      imageType:
        this.config && this.config.imageType
          ? this.config.imageType
          : "image/png,",
      imageSet: false,
      keyword:
        this.config && this.config.keyword ? this.config.keyword : "Image",
      mode: ["upload", "gallery", "url"],
      params: "",
    };

    this.imageDarkConfig = {
      id: this.field_id + "_dark",
      title: this.config && this.config.title ? this.config.title : "Image",
      max: this.config && this.config.max ? this.config.max : 1,
      aspectRatio:
        this.config && this.config.aspectRatio
          ? this.config.aspectRatio
          : "1x1",
      resizeToWidth:
        this.config && this.config.resizeToWidth
          ? this.config.resizeToWidth
          : 260,
      resizeToHeight:
        this.config && this.config.resizeToHeight
          ? this.config.resizeToHeight
          : 260,
      cropperView:
        this.config && this.config.cropperView
          ? this.config.cropperView
          : false,
      selectedImages: [],
      removeButton: true,
      imageType:
        this.config && this.config.imageType
          ? this.config.imageType
          : "image/png,",
      imageSet: false,
      keyword:
        this.config && this.config.keyword ? this.config.keyword : "Image",
      mode: ["upload", "gallery", "url"],
      params: "",
    };

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.current_tab &&
          this.tab_id &&
          data.current_tab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key &&
          data.off_canvas_key === "tab" &&
          data.tab &&
          data.tab.id &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      if (this.config.setcolor){
        this.colors = this.isAndroid
        ? this.config.scheme_color.color_schema
        : this.config.scheme_color.color_schema_ios;
      }
      if(!this.config.bg){
        if (this.tab[this.config.key]) {
          let media: any = {};
          media.permanentUrl = this.tab[this.config.key];
          this.imageConfig.selectedImages = [{ media: media }];
        }
        if (this.tab[this.config.keyDark]) {
          this.toggleDarkImage = true;
          let media: any = {};
          media.permanentUrl = this.tab[this.config.keyDark];
          this.imageDarkConfig.selectedImages = [{ media: media }];
        }
      }else{
        if (this.tab['tab_bg'] && this.tab['tab_bg'][this.config.key]) {
          let media: any = {};
          media.permanentUrl = this.tab['tab_bg'][this.config.key];
          this.imageConfig.selectedImages = [{ media: media }];
        }
        if (this.tab['tab_bg'] && this.tab['tab_bg'][this.config.keyDark]) {
          this.toggleDarkImage = true;
          let media: any = {};
          media.permanentUrl = this.tab['tab_bg'][this.config.keyDark];
          this.imageDarkConfig.selectedImages = [{ media: media }];
        }
      }
    }
  }

  buttonImage(value) {
    if(!this.config.bg){
      if (value && value.length > 0) {
        this.tab[this.config.key] = value[0].media.permanentUrl;
      } else {
        this.tab[this.config.key] = null;
      }
    }else{
      const bg: Background = {...this.tab['tab_bg']};
      if (value && value.length > 0) {
        bg[this.config.key] = value[0].media.permanentUrl;
        this.tab['tab_bg'] = bg;
      } else {
        delete this.tab['tab_bg'][this.config.key];
      }
    }

    this.updateTabData();
  }

  buttonImageDark(value) {
    if(!this.config.bg){
      if (value && value.length > 0) {
        this.tab[this.config.keyDark] = value[0].media.permanentUrl;
      } else {
        delete this.tab[this.config.keyDark];
      }
    }else{
      const bg: Background = {...this.tab['tab_bg']};
      if (value && value.length > 0) {
        bg[this.config.keyDark] = value[0].media.permanentUrl;
        this.tab['tab_bg'] = bg;
      } else {
        delete this.tab['tab_bg'][this.config.keyDark];
      }
    }

    this.updateTabData();
  }


  enableDarkImageMode(e) {
    this.toggleDarkImage = e.target.checked;
    if (!this.toggleDarkImage) {
      delete this.tab[this.config.keyDark];
      this.updateTabData();
    }
  }

  toggle(status: boolean){
    this.enableBackgraund = status;

  }

  buttonBgColor(item, value, manual) {
    // let color = null;
    // color = (manual == true)?this.isValidHexaCode(value.target.value)? value.target.value :null : value
    // if (color){
    //   if (item === 'start') {
    //     this.button.button_bgstart = color;
    //     this.start_color = color;
    //   }
    //   if (item === 'end') {
    //     this.button.button_bgend = color;
    //     this.end_color =color;
    //   }
    //   this.updateButtonData()

    // }

  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data["target"] = "tabSetting";

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
  }
}
