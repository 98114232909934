import { Component , OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WebsocketService } from './core/services/websocket.service';
import { LoaderService } from './core/services/loader.service';
import { EventService } from './core/services/event.service';
import { IndexDBService } from './core/services/indexDB.service';
import { ImagePreloaderService } from './core/services/preloader.service';
import { MiddlwareService } from './core/services/middleware.service';
import { TourService } from 'ngx-ui-tour-ngx-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  refreshSubscription: any;
  tourSubscription: any;
  appBuilderPage = false;
  routeExclude = []
  constructor(
    private router: Router,
    private ws: WebsocketService,
    private load: LoaderService,
    private es: EventService,
    private route: ActivatedRoute,
    private mdw: MiddlwareService,
    private preload: ImagePreloaderService,
    private tourService: TourService
  ){}

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.refreshSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if(!this.router.navigated && event.url.slice(0, 4) !== "/hub"){
          // console.log("LOADER TRUE");
          this.load.isLoading.next(true);
          // console.log("reconnect app");
          this.ws.reconnect(1);

          let mode = localStorage.getItem("light");
          if(mode && mode == "true"){
            this.es.broadcast("changeMode", "light");
          } else if (mode && mode == "false"){
            this.es.broadcast("changeMode", "dark");
          }
        }
        if(!this.router.navigated && event.url.includes("appbuilder")){
          this.appBuilderPage = true;
        } else {
          this.appBuilderPage = false;
        }
      }
      if (event instanceof NavigationEnd) {
        if(this.router.url.slice(0, 4) !== "/hub" && !(this.routeExclude.includes(this.router.url))){
          localStorage.setItem("returnUrl", this.router.url);
        }
        if(this.router.url.includes("appbuilder") || this.router.url.includes("market/market") || this.router.url.includes("stores/store") || this.router.url.includes("polls/poll")){
          this.appBuilderPage = true;
        } else {
          this.appBuilderPage = false;
        }

        let expire = this.mdw.getPlanDetails()?.expire;
        let cancelEnd = this.mdw.getPlanDetails()?.cancel_end;
        const currentDate = new Date().getTime();
        if ((expire || (cancelEnd && cancelEnd < currentDate)) && !this.router.url.includes('billings')) {
          this.router.navigate(["/billings/expire"]);
        }
        this.route.queryParams.subscribe((params) => {
          if(this.router.url.includes("/hub/signup")){
            if (params && params['id']){
              localStorage.removeItem("start");
              localStorage.setItem("template_id", params['id'])
            } else {
              localStorage.removeItem("template_id");
              localStorage.setItem("start", "true");
            }
          }
        });
      }
    });

    this.tourService.initialize([
      {
        anchorId: 'navigationIntro',
        title: 'Welcome to the Navigation Page!',
        content: `
          On this page, you can design how users navigate your app.
          Let’s walk through the key elements that help you preview
          and customize your app’s navigation.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        disableScrollToAnchor: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'phonePreview',
        title: 'App Simulator',
        content: `
          This phone mockup updates in real time as you adjust your configuration.
          It’s a simulator that gives you a feel for how your app will look on a device.
          Please note: This is not the actual app—you need to build it to experience the native version.
        `,
        placement: 'right',
        enableBackdrop: true,
        disableScrollToAnchor: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'deviceSwitch',
        title: 'Switch Device View',
        content: `
          Use these tabs to toggle between iOS and Android views.
          This lets you preview your app’s navigation in different platform styles.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        disableScrollToAnchor: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'orientationToggle',
        title: 'Change Navigation Orientation',
        content: `
          Click the gear icon to toggle between top and bottom tab placements.
          This setting helps you customize where the navigation tabs appear on your app.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        disableScrollToAnchor: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'navItems',
        title: 'Manage Your Components',
        content: `
          Here you can add new tabs or rearrange existing ones.
          Customize each section to guide users seamlessly through your app.
        `,
        placement: 'left',
        enableBackdrop: true,
        disableScrollToAnchor: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'addNativeComponent',
        title: 'Add Native Components',
        content: `
          Need more features? Click this button to open a side menu with native components
          you can drag and drop or click to add directly to your app.
          This is a quick way to enhance your app with additional functionality.
        `,
        enableBackdrop: true,
        placement: 'left',
        disableScrollToAnchor: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'saveRestore',
        title: 'Save & Restore',
        content: `
          Use these buttons to save your current navigation setup or restore
          a previous configuration. Saving helps you keep your changes,
          and Restore can quickly revert any unwanted edits.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        route: "/appbuilder/navigation",
        disableScrollToAnchor: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'brandingIntro',
        title: 'Welcome to the Branding Page!',
        content: `
          Here, you can define your app’s visual identity.
          Customize your app name, upload your logo, and set a background color.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        route: "/appbuilder/branding",
        disableScrollToAnchor: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'brandingDetails',
        title: 'Customize Your Brand',
        content: `
          Enter your app name, upload a logo, and choose a background color for your logo.
          These details define your app’s unique identity.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'themeCustomization',
        title: 'Set Your App’s Theme',
        content: `
          Select your primary colors and experiment with different styles.
          These options let you see how your brand translates across different themes.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'colorCustomization',
        title: 'Set Your App’s Theme',
        content: `
          Select your primary colors and experiment with different styles.
          These options let you see how your brand translates across different themes.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'onboardingPages',
        title: 'Continue to Onboarding',
        content: `
          Once you’re happy with your branding, click on the Onboarding tab in the sidebar.
          This lets you customize the welcome screens that guide new users through your app.
        `,
        placement: 'right',
        enableBackdrop: true,
        route: "/appbuilder/branding",
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'sideMenuIntro',
        title: 'Side Menu Overview',
        content: `
          This page lets you manage the items in your app’s side menu.
          Each item can link to different features or screens in your app.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        route: "/appbuilder/side",
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'sideMenuItems',
        title: 'Manage Existing Items',
        content: `
          Rearrange, rename, or remove items in your side menu.
          For example, "User Profile" can link to account settings,
          and "Invitations" might open a special features screen.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'sideMenuDrawer',
        title: 'Navigation Drawer Theme',
        content: `
          Click the gear button to configure your side menu’s appearance.
          Here, you can enable or disable the navigation drawer and show
          or hide user profile details in the menu.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'addSideMenuComponent',
        title: 'Add Native Components',
        content: `
          Use this button to add new functionality to your side menu.
          You can choose from various native components to enrich your app.
        `,
        placement: 'left',
        enableBackdrop: true,
        route: "/appbuilder/side",
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'settingsIntro',
        title: 'Settings Overview',
        content: `
          Here, you can fine-tune your app’s behavior—from language and theme
          to user registration and notifications.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        route: "/appbuilder/settings",
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'notificationsRegistration',
        title: 'Notifications & Registration',
        content: `
          Enable or disable push notifications, and decide how users
          can sign up or log in (e.g., phone or email verification).
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'languageSettings',
        title: 'Language',
        content: `
          Choose your default language and enable multi-language support
          if you want to localize your app for different regions.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'themeSettings',
        title: 'Theme',
        content: `
          Select between light and dark modes, or any additional themes
          you want to offer. This helps users personalize their experience.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'paymentMethods',
        title: 'Payment Methods',
        content: `
          Set the default currency and enable or disable each payment option,
          like credit cards, PayPal, cash on delivery, or in-app purchases.
          This defines how users will pay for products or services in your app.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'adsConfig',
        title: 'Ads',
        content: `
          Integrate Google Ads (or other providers) to monetize your app.
          Enabling this option will show ads in various sections of your app.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'channelSettings',
        title: 'Channels',
        content: `
          Customize labels for chat or other communication channels.
          This helps you keep messaging features consistent with your brand.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'changeRequest',
        title: 'Change Request',
        content: `
          Need a unique feature or a special customization?
          Click here to contact support and discuss your requirements.
        `,
        placement: 'left',
        enableBackdrop: true,
        route: "/appbuilder/settings",
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'buildIntro',
        title: 'Build Overview',
        content: `
          Welcome to the Build page! Here, you’ll prepare and publish
          your app to various platforms like Google Play and the App Store.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        route: "/appbuilder/build",
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'companyDetails',
        title: 'Company Details',
        content: `
          Provide or update your company’s information here.
          This data may appear in store listings and helps
          users identify your app’s publisher.
        `,
        placement: 'bottom',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'googlePlaySetup',
        title: 'Google Play Setup',
        content: `
          Enter your Google Play credentials or keys required
          to publish your app on the Play Store. Make sure all
          fields are correct before building your release.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'iosSetup',
        title: 'iOS Setup',
        content: `
          Provide your Apple Developer credentials to publish
          your app on the App Store. Double-check these details
          to ensure a smooth release process.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'buildForm',
        title: 'Build Form',
        content: `
          Select the operating system, build format (APK, AAB, IPA, etc.),
          and enter a release name or notes. This step packages your app
          for submission to your chosen store.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'releaseList',
        title: 'Release List',
        content: `
          Each release you build appears here, showing its OS, version,
          status, and progress. Use this list to track or manage all
          your submitted builds.
        `,
        placement: 'left',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      },
      {
        anchorId: 'releaseButton',
        title: 'Generate Your App',
        content: `
          Ready to see your app in action? Click the Release button
          to build your app. Once the build is complete, you can
          download it for testing or publish it to the store.
        `,
        placement: 'right',
        enableBackdrop: true,
        smoothScroll: true,
        popoverClass: "popover-wide",
        stepDimensions: { minWidth: "425px" },
        centerAnchorOnScroll: false
      }
    ]);
    // this.tourService.disableHotkeys();

    this.tourSubscription = this.mdw.tourContainer$.subscribe((data) => {
      if(data){
        // this.tourService.start();
      }
    })

    // this.indexDBService.connect();
    let imagesToPreload = [
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxxhdpi.jpg?temp_url_sig=015541611a83b3cb6efff805b350f718b02f47f1&temp_url_expires=2029425688",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_hdpi.jpg?temp_url_sig=38c218e7554e592beab71fa7b2488f57e749efb8&temp_url_expires=2029425680",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_1x.jpg?temp_url_sig=59234fb47b3127fb420078a6ab5f23d2205a61aa&temp_url_expires=2029425690",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_2x.jpg?temp_url_sig=d614c6cdc80b76ce06815ab7e251d4b23389484a&temp_url_expires=2029425693",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_3x.jpg?temp_url_sig=a23c901a689c967870579ae73422569d1372e814&temp_url_expires=2029425696",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_mdpi.jpg?temp_url_sig=3c2090d18487060a395b700a75e378593c834061&temp_url_expires=2029425682",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xhdpi.jpg?temp_url_sig=852a0c4e46da7e504f64fbbc4c08ccdcf9cd13c4&temp_url_expires=2029425684",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxhdpi.jpg?temp_url_sig=3575b13ff913aa291bf670a294af18ccad5e2270&temp_url_expires=2029425686",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxxhdpi.jpg?temp_url_sig=015541611a83b3cb6efff805b350f718b02f47f1&temp_url_expires=2029425688",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684293000559_6323a65cbe6461e1f8aff1521f8e593638a5565b93fcd239eb0017cab677afd6.jpg?temp_url_sig=d73ac5a2aba2cbf466059477ab838322e33814ac&temp_url_expires=2028055587",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxxhdpi.png?temp_url_sig=67d62b05aeea186a84aef6a36b60a17c276dca96&temp_url_expires=2029422073",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_hdpi.png?temp_url_sig=4e14bda3c06e8f9dce9db0e71ce038d14efc1369&temp_url_expires=2029422062",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_1x.png?temp_url_sig=44f44c731fbd7d7179216e137f8609d35e95b6c1&temp_url_expires=2029422079",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_2x.png?temp_url_sig=896b75c4afc29a856ecdb6b1a077c49eb55756a3&temp_url_expires=2029422080",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_3x.png?temp_url_sig=f622588b4a841b764152f5165538fae4765d9246&temp_url_expires=2029422083",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_mdpi.png?temp_url_sig=ee29be9fee7b770b800a968329b363b2c0eddd65&temp_url_expires=2029422064",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xhdpi.png?temp_url_sig=493db2153fee482d0b9757b432744f5396efc1ba&temp_url_expires=2029422066",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxhdpi.png?temp_url_sig=ea1570d96091bb691950387ba74bb1805ae6d85c&temp_url_expires=2029422067",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxxhdpi.png?temp_url_sig=67d62b05aeea186a84aef6a36b60a17c276dca96&temp_url_expires=2029422073",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxxhdpi.jpg?temp_url_sig=0d0cfdb8136554d2c9b711c7a5413865ba1ebfe0&temp_url_expires=2029430455",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_hdpi.jpg?temp_url_sig=e3a0940c8f9a5f04034dcad3d7aaa7138b549efa&temp_url_expires=2029430445",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_1x.jpg?temp_url_sig=70c0fb410bd5add69d256298ce3b8d8d3d443ec5&temp_url_expires=2029430457",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_2x.jpg?temp_url_sig=aee737323f64ad9975871ccc58f4491ce027869d&temp_url_expires=2029430460",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_3x.jpg?temp_url_sig=9ad573b3368f69bb5c4406abf3ae9d4ca26c7586&temp_url_expires=2029430465",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_mdpi.jpg?temp_url_sig=f1c0d5a582713da9a932615f39b209b3e4231a07&temp_url_expires=2029430447",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xhdpi.jpg?temp_url_sig=f18a66138829a6747ef6e7384f330941f018aed1&temp_url_expires=2029430450",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxhdpi.jpg?temp_url_sig=c73b97fc0be16e139ea03a6c6d9c9d376bd3c514&temp_url_expires=2029430452",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxxhdpi.jpg?temp_url_sig=0d0cfdb8136554d2c9b711c7a5413865ba1ebfe0&temp_url_expires=2029430455",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxxhdpi.png?temp_url_sig=11ab0a15e94c652b6b714a159d4a77910645a80f&temp_url_expires=2029422498",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_hdpi.png?temp_url_sig=2351bc984524b86cfff487b2f6736a569bdf92f9&temp_url_expires=2029422489",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_1x.png?temp_url_sig=7b77e200bb6e54126ad068276a53e73d518f5edb&temp_url_expires=2029422500",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_2x.png?temp_url_sig=cef5d78dffdef9bdc2ebbb70d126655aa3aede57&temp_url_expires=2029422501",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_3x.png?temp_url_sig=500fb231f1d6cedf6df0e37739aa59f6a78df96b&temp_url_expires=2029422504",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_mdpi.png?temp_url_sig=6cdbeaed82abb0c87ad590421bd21b6b7f5e1cc0&temp_url_expires=2029422492",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xhdpi.png?temp_url_sig=a7e1b5778de40c498ab11f8274111c5f13f8ec8e&temp_url_expires=2029422493",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxhdpi.png?temp_url_sig=c091a289be8e5c94cb8a271f469adc0a5bde48a3&temp_url_expires=2029422497",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxxhdpi.png?temp_url_sig=11ab0a15e94c652b6b714a159d4a77910645a80f&temp_url_expires=2029422498",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxxhdpi.png?temp_url_sig=412969a90811990b3a4088614db1c6c33f754549&temp_url_expires=2029422714",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_hdpi.png?temp_url_sig=a6e2a8c50d83181753bce18eb7a297651421192e&temp_url_expires=2029422709",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_1x.png?temp_url_sig=1fcb01c466ad3648d982fb37f66909f2d9c57d82&temp_url_expires=2029422716",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_2x.png?temp_url_sig=deab9c10f1bc09780e20c7816f3939bf5173cb4c&temp_url_expires=2029422717",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_3x.png?temp_url_sig=3a71eee768113e645023d0a875a039a39cfb51f3&temp_url_expires=2029422719",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_mdpi.png?temp_url_sig=d937e3636c76d61052c76a6d781d08db2d442e4e&temp_url_expires=2029422710",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xhdpi.png?temp_url_sig=0858505b0fcf60f346fe6f2a8e87b3051a1f0057&temp_url_expires=2029422711",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxhdpi.png?temp_url_sig=3acaa76cd1b303763e60eb951b7d38157e8a73fa&temp_url_expires=2029422712",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxxhdpi.png?temp_url_sig=412969a90811990b3a4088614db1c6c33f754549&temp_url_expires=2029422714",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxxhdpi.png?temp_url_sig=85a2393c57aa14d006f05fcd8daa91af2d8a07de&temp_url_expires=2029423833",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_hdpi.png?temp_url_sig=70af0e76b1f7b501d22de7a8f905c1b40bce6371&temp_url_expires=2029423824",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_1x.png?temp_url_sig=5a9eb3c6fac1956f5b968af738c6e87c704ddaa8&temp_url_expires=2029423834",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_2x.png?temp_url_sig=88fd7c326d0ea46eca4d63bae8a0f88169b312ca&temp_url_expires=2029423836",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_3x.png?temp_url_sig=c3dda49f2092d6da5a05c3f817f16adb61a5c9fc&temp_url_expires=2029423837",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_mdpi.png?temp_url_sig=e60611011847fbf8bb95d4390f13c6f3bb9eb28c&temp_url_expires=2029423826",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xhdpi.png?temp_url_sig=d92cd07d7b4a23b687d6f4b2f42412894581b651&temp_url_expires=2029423829",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxhdpi.png?temp_url_sig=24d08bc8033daaba80ff42db30623e858ef7cf81&temp_url_expires=2029423831",
      "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxxhdpi.png?temp_url_sig=85a2393c57aa14d006f05fcd8daa91af2d8a07de&temp_url_expires=2029423833",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxxhdpi.png?temp_url_sig=f1615d81dc44c10094ee6cc84b8f6ca157c0ec5c&temp_url_expires=2029424078",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_hdpi.png?temp_url_sig=b70f25aa2ae025f5703c8e70d3edb1b9cbb4fd21&temp_url_expires=2029424073",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_1x.png?temp_url_sig=1142c67ac4f61913526e218599f90b0bddc5374b&temp_url_expires=2029424080",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_2x.png?temp_url_sig=f4500e300b7e644e1009b670aefd799088f7112b&temp_url_expires=2029424082",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_3x.png?temp_url_sig=caab83be3afd33c3064162b9ad3abab548a5deb7&temp_url_expires=2029424085",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_mdpi.png?temp_url_sig=ee4b1fd3088fb15b591907339979da1ab42a990b&temp_url_expires=2029424074",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xhdpi.png?temp_url_sig=809ac57010906b7a7276d601bc547decb7a4ab32&temp_url_expires=2029424075",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxhdpi.png?temp_url_sig=f1fc71bf16f958486c0e7aca0a7651a0968868c2&temp_url_expires=2029424077",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxxhdpi.png?temp_url_sig=f1615d81dc44c10094ee6cc84b8f6ca157c0ec5c&temp_url_expires=2029424078",
      "https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/image-headline-subhead.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/card-colorbackground-headline-subhead-1.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/card-background-headline-subhead.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/yoga.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/yoga.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/card-middle-image-subhead.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/yoga-2.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/yoga-2.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/small-card-headline-subhead.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/fitness.webp",
      "https://nandbox.com/wp-content/uploads/2022/12/fitness.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/card-image-headline-subhead.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-7.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-7.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-7.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-8.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-8.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-8.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-1.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-2.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-2.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-2.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-3.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-3.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-3.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-4.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-4.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-4.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-5.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-5.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-5.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-6.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-6.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-6.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-9.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-10.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-10.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-10.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-11.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-11.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-11.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-12.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-12.png",
      "https://nandbox.com/wp-content/uploads/2024/10/collection-12.png",
      "https://nandbox.com/wp-content/uploads/2024/10/label-input-text.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/label-input-text-white.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/input-text.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/L-headline-subhead-card.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/headline-subhead-card.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/headline-subhead-valueText-card.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/radio-Button-card.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/radio-Button-card-icon.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/check-card-icon.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/check-card-image.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/check-card.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/Toggle-Button-card-icon.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/Toggle-Button-card.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/elevated@3x.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/filled@3x.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/tonal@3x.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/outlined@3x.webp",
      "https://nandbox.com/wp-content/uploads/2024/10/text-px@3x.webp",
      "https://st2.depositphotos.com/2306293/7723/i/950/depositphotos_77237624-stock-photo-happy-face-mug-on-the.jpg",
      "https://st3.depositphotos.com/2306293/15341/i/1600/depositphotos_153418610-stock-photo-sign-happy-on-the-miami.jpg",
      "https://st2.depositphotos.com/4215343/10981/i/950/depositphotos_109810160-stock-photo-wish-you-were-here.jpg",
      "https://st2.depositphotos.com/2306293/7723/i/950/depositphotos_77237624-stock-photo-happy-face-mug-on-the.jpg",
      "https://st3.depositphotos.com/2306293/15341/i/1600/depositphotos_153418610-stock-photo-sign-happy-on-the-miami.jpg",
      "https://st2.depositphotos.com/4215343/10981/i/950/depositphotos_109810160-stock-photo-wish-you-were-here.jpg",
      "https://images.pexels.com/photos/20145987/pexels-photo-20145987/free-photo-of-a-person-riding-a-motorcycle-on-a-road.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/16113289/pexels-photo-16113289/free-photo-of-close-up-of-reed.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/20001995/pexels-photo-20001995/free-photo-of-a-black-and-white-photo-of-a-roller-coaster.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/20084774/pexels-photo-20084774/free-photo-of-a-cloud-in-the-sky-over-some-rocks.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/20103012/pexels-photo-20103012/free-photo-of-a-snowy-forest-with-trees-and-sun-shining-through.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/16575290/pexels-photo-16575290/free-photo-of-view-from-car-window-on-road-in-forest-in-rain.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://images.pexels.com/photos/16575290/pexels-photo-16575290/free-photo-of-view-from-car-window-on-road-in-forest-in-rain.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Channels-Dashboard_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Active-_Channels_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Active-_Channels_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Active-_Channels_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Active-Channels_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger__AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger__AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger__AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger__Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger__Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger__Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Messenger.__Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts__iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts__iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts__iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User-Contacts__Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User-Contacts__Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User-Contacts__Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/User__Contacts_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-_Dialer_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-_Dialer_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-_Dialer_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Calls-Dialer_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-_History_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-_History_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-_History_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Call-History_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups-list_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups-list_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups-list_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups-_list_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups-_list_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups-_list_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Group-list_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Group-list_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Group-list_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-list_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-_Channels-_List_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Channels-List_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-_Calendar_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-_Dashboard_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-_Dashboard_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-_Dashboard_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Invitation-Dashboard_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code-Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-_Code_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-__Code_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-__Code_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-__Code_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/QR-Code_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/myqr-an-L.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/myqr-AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/qr-link-AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/qr-link-an-s.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/MyQRCode_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/MyQRCode_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/QRCodeLink_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/QRCodeLink_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/01/video_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-_Search_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-_Search_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-_Search_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Map-Search_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-_Search-_Page_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-_Search-_Page_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-_Search-_Page_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/New-Search-Page_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings-Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings__Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings__Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/App-Settings__Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders-Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/my-orders_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-_Groups_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Messages_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Messages_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Messages_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat-Groups_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment-Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/payment_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login-_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login-_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login-_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login-_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login-_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login-_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Android-Login-Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Android-Login-Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Android-Login-Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/login_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/All-Groups_Dark_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Chat_Groups_Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat__Dark_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/Bot__Chat_Dark_iOS_S.webp",
      "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_c_xxxhdpi.png?temp_url_sig=15b8f28c7aa8669edf34fc277e601240a5ec05b2&temp_url_expires=2039097296",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2024/12/My-Calendar_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_Messenger_chat-group_AN_L-1.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_create_type_chat-group_info_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_create_chat-group_info_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_create_chat-group_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_text_message_background_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_text_message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_send_photo_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_select_message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_notification_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_info_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat-group_emoje_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-voice-message_check_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-voice-message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group-attach_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_user_Permissions_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_share_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_send_voice-message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_replies_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_QR_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_chat_group_lock_voice-message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/WF_Chat_Group_invite_admin-participants_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Messenger_chat-group_AN_L-1.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_type_chat-group_info_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_chat-group_info_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_create_chat-group_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_text_message_background_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_text_message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_send_photo_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_select_message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_notification_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_info_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat-group_emoje_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-voice-message_check_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-voice-message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group-attach_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_user_Permissions_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_share_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_send_voice-message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_replies_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_QR_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_chat_group_lock_voice-message_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/01/Dark_WF_Chat_Group_invite_admin-participants_AN_L.webp",

      "https://nandbox.com/wp-content/uploads/2025/02/Collection_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Collection_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Collection_AN_S.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Collection_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Collection_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Collection_iOS_S.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_AN_F.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_AN_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_iOS_F.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_iOS_L.webp",
      "https://nandbox.com/wp-content/uploads/2025/02/Dark_Collection_iOS_S.webp",

    ]
    this.preload.preloadImagesInChunks(imagesToPreload, 20);
  }

  ngOnDestroy(): void {
    if(this.refreshSubscription){
      this.refreshSubscription.unsubscribe();
    }
    if(this.tourSubscription){
      this.tourSubscription.unsubscribe();
    }
  }
}
