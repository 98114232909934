import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-tabsplash-button",
  templateUrl: "./button-tabsplash-button.component.html",
  styleUrls: ["./button-tabsplash-button.component.scss"],
})

export class ButtonTabSplashButtonComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) { }

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() tabData: Tab;
  @Input() config: any;
  imageConfig: any = {};
  button: MenuButton;
  image: string;
//
  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"
  component_id: string;
  field_id: string;
  tab: Tab;
  textColor: string;
  textDarkColor: string;
  bgColor: string;
  bgDarkColor: string;
  button_title: string;
  enableCustomColor = false;
  isAndroid: boolean;
  sync = true;
  colors: any;
  button_color_light: any = {};
  button_color_dark: any = {};


  ngOnInit() {
    this.field_id = 'tabsplashbutton'
    this.sortedButtonList = [];
    this.buttonList = {};
    this.lableform = this.formBuilder.group({
    lableControl: new UntypedFormControl(''),
   });

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data)
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {

        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton && this.button_id &&
          data.currentButton.button_id == this.button_id
          && data["component_id"] !== this.component_id
        ) {

          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          let tab = data["splash_tab"]
          this.buttonFunction(tab)


          }
        }
    );
  }

 intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.tabData)
  }

  buttonFunction(tabData) {
    if (tabData && tabData.param && tabData.param.splash) {
      this.tab = tabData;
      // this.button_title = this.tab.param.splash.button_title;
      this.button_title = this.builderService.getTabSplashItem(this.tab.param.splash, 'button_title', this.tab.param.splash.style);

      // this.textColor = this.tab.param.splash.button_text_color;
      // this.textColor = this.builderService.getTabSplashItem(this.tab.param.splash, 'button_text_color', this.tab.param.splash.style);

      // this.bgColor = this.tab.param.splash.button_bgcolor;
      // this.bgColor = this.builderService.getTabSplashItem(this.tab.param.splash, 'button_bgcolor', this.tab.param.splash.style);

      if (this.button_title) {
        this.lableform = this.formBuilder.group({
          lableControl: new UntypedFormControl(this.button_title),
        });
      }

      if (this.config.setcolor){
        this.colors = this.isAndroid
        ? this.config.scheme_color.color_schema
        : this.config.scheme_color.color_schema_ios;
      }

      if (this.isAndroid) {
        if (
          this.tab.param&&
          this.tab.param.splash&&
          this.tab.param.splash.color_schema &&
          this.tab.param.splash.color_schema.schemes &&
          this.tab.param.splash.color_schema.schemes.light &&
          this.tab.param.splash.color_schema.schemes.dark &&
          this.tab.param.splash.color_schema.schemes.light["btn_text"] &&
          this.tab.param.splash.color_schema.schemes.light["btn_bg"] &&
          this.tab.param.splash.color_schema.schemes.dark["btn_text"] &&
          this.tab.param.splash.color_schema.schemes.dark["btn_bg"]
        ) {
          this.textColor = this.tab.param.splash.color_schema.schemes.light["btn_text"];
          this.bgColor = this.tab.param.splash.color_schema.schemes.light["btn_bg"];
          this.textDarkColor = this.tab.param.splash.color_schema.schemes.dark["btn_text"];
          this.bgDarkColor = this.tab.param.splash.color_schema.schemes.dark["btn_bg"];
          this.enableCustomColor = true;
        }
      } else {
        if (
          this.tab.param&&
          this.tab.param.splash&&
          this.tab.param.splash.color_schema_ios &&
          this.tab.param.splash.color_schema_ios.schemes &&
          this.tab.param.splash.color_schema_ios.schemes.light &&
          this.tab.param.splash.color_schema_ios.schemes.dark &&
          this.tab.param.splash.color_schema_ios.schemes.light["btn_text"] &&
          this.tab.param.splash.color_schema_ios.schemes.light["btn_bg"] &&
          this.tab.param.splash.color_schema_ios.schemes.dark["btn_text"] &&
          this.tab.param.splash.color_schema_ios.schemes.dark["btn_bg"]
        ) {
          this.textColor = this.tab.param.splash.color_schema_ios.schemes.light["btn_text"];
          this.bgColor = this.tab.param.splash.color_schema_ios.schemes.light["btn_bg"];
          this.textDarkColor = this.tab.param.splash.color_schema_ios.schemes.dark["btn_text"];
          this.bgDarkColor = this.tab.param.splash.color_schema_ios.schemes.dark["btn_bg"];
          this.enableCustomColor = true;
        }
      }
    }
  }

  buttonLabel() {
    if (this.f && this.f.lableControl.valid) {
      this.button_title= this.f.lableControl.value;
      this.tab.param.splash.button_title= this.button_title;
      this.updateButtonData(this.tab)
    }
  }

  get f() {
    if (this.lableform &&  this.lableform.controls) {
      return this.lableform.controls;
    }

  }


  buttonBgColor(item, value, manual, mode:string ='color') {
  let onMode : string;

    if (mode=='color'){
      onMode = 'onColor'
    }else {
      onMode ='color'
    }
    let color = null;
    color = (manual == true)?this.isValidHexaCode(value.target.value)? value.target.value :null : value

    if (color){
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.colors = this.isAndroid ? this.config.scheme_color.color_schema : this.config.scheme_color.color_schema_ios;
      let customColor = this.mdw.customColor(color, this.colors.source, mode );
      let onCustomColor = this.mdw.customColor(color, this.colors.source, onMode );

      if (item === 'text') {
        this.textColor = customColor.light;
        this.textDarkColor = customColor.dark;

        this.bgColor = onCustomColor.light;
        this.bgDarkColor = onCustomColor.dark;

        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["btn_text"] = this.textColor;
          this.tab.param.splash.color_schema.schemes.dark["btn_text"] = this.textDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["btn_text"] = this.textColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["btn_text"] = this.textDarkColor;
        }

        // set the bg
        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["btn_bg"] = this.bgColor;
          this.tab.param.splash.color_schema.schemes.dark["btn_bg"] = this.bgDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["btn_bg"] = this.bgColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["btn_bg"] = this.bgDarkColor;
        }


      }
      if (item === 'bg') {
        this.bgColor = customColor.light;
        this.bgDarkColor = customColor.dark;

        this.textColor = onCustomColor.light;
        this.textDarkColor = onCustomColor.dark;


        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["btn_bg"] = this.bgColor;
          this.tab.param.splash.color_schema.schemes.dark["btn_bg"] = this.bgDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["btn_bg"] = this.bgColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["btn_bg"] = this.bgDarkColor;
        }

        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["btn_text"] = this.textColor;
          this.tab.param.splash.color_schema.schemes.dark["btn_text"] = this.textDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["btn_text"] = this.textColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["btn_text"] = this.textDarkColor;
        }




      }
      this.updateButtonData(this.tab)

    }

  }


  isValidHexaCode(str) {
    // Regex to check valid
    // hexadecimalColor_code
    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

    // if str
    // is empty return false
    if (str == null) {
        return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
        return true;
    }
    else {
        return false;
    }
}


toggleGradientColor(event) {
  this.enableCustomColor = event;

  if (this.isAndroid || this.sync) {
    /// Android Settings
    if (this.enableCustomColor) {
      this.textColor = this.colors.schemes.light[this.config.init_text];
      this.textDarkColor = this.colors.schemes.dark[this.config.init_text];
      this.bgColor = this.colors.schemes.light[this.config.init_bg];
      this.bgDarkColor = this.colors.schemes.dark[this.config.init_bg];
      if (
        this.tab.param.splash.color_schema &&
        this.tab.param.splash.color_schema.schemes
      ) {
        const schemes = this.tab.param.splash.color_schema.schemes;
        this.button_color_light = schemes.light;
        this.button_color_light = {
          ...this.button_color_light,
          btn_text: this.textColor,
          btn_bg: this.bgColor
        };
        this.button_color_dark = schemes.dark;
        this.button_color_dark = {
          ...this.button_color_dark,
          btn_text: this.textDarkColor,
          btn_bg: this.bgDarkColor
        };
        this.tab.param.splash.color_schema.schemes = {
          light: { ...this.button_color_light },
          dark: { ...this.button_color_dark },
        };
      } else {
        this.tab.param.splash = {
          ...this.tab.param.splash,
          color_schema: {
            schemes: {
              light: {
                btn_text: this.textColor,
                btn_bg: this.bgColor
              },
              dark: {
                btn_text: this.textDarkColor,
                btn_bg: this.bgDarkColor
              },
            },
          },
        };
      }
    } else {
      this.textColor = null;
      this.textDarkColor = null;
      this.bgColor = null;
      this.bgDarkColor = null;
      delete this.tab.param.splash.color_schema.schemes.light["btn_text"];
      delete this.tab.param.splash.color_schema.schemes.light["btn_bg"];
      delete this.tab.param.splash.color_schema.schemes.dark["btn_text"];
      delete this.tab.param.splash.color_schema.schemes.dark["btn_bg"];
    }
  }
  if (!this.isAndroid || this.sync) {
    /// Android Settings
    if (this.enableCustomColor) {
      this.textColor = this.colors.schemes.light[this.config.init_text];
      this.textDarkColor = this.colors.schemes.dark[this.config.init_text];
      this.bgColor = this.colors.schemes.light[this.config.init_bg];
      this.bgDarkColor = this.colors.schemes.dark[this.config.init_bg];
      if (
        this.tab.param.splash.color_schema_ios &&
        this.tab.param.splash.color_schema_ios.schemes
      ) {
        const schemes = this.tab.param.splash.color_schema_ios.schemes;
        this.button_color_light = schemes.light;
        this.button_color_light = {
          ...this.button_color_light,
          btn_text: this.textColor,
          btn_bg: this.bgColor
        };
        this.button_color_dark = schemes.dark;
        this.button_color_dark = {
          ...this.button_color_dark,
          btn_text: this.textDarkColor,
          btn_bg: this.bgDarkColor
        };
        this.tab.param.splash.color_schema_ios.schemes = {
          light: { ...this.button_color_light },
          dark: { ...this.button_color_dark },
        };
      } else {
        this.tab.param.splash = {
          ...this.tab.param.splash,
          color_schema_ios: {
            schemes: {
              light: {
                btn_text: this.textColor,
                btn_bg: this.bgColor
              },
              dark: {
                btn_text: this.textDarkColor,
                btn_bg: this.bgDarkColor
              },
            },
          },
        };
      }
    } else {
      this.textColor = null;
      this.textDarkColor = null;
      this.bgColor = null;
      this.bgDarkColor = null;
      delete this.tab.param.splash.color_schema_ios.schemes.light["btn_text"];
      delete this.tab.param.splash.color_schema_ios.schemes.light["btn_bg"];
      delete this.tab.param.splash.color_schema_ios.schemes.dark["btn_text"];
      delete this.tab.param.splash.color_schema_ios.schemes.dark["btn_bg"];
    }
  }

  this.updateButtonData(this.tab);
}




  // buttonBgColor(item,value) {
  //   if (item==='text') {
  //     this.textColor = value
  //     this.tab.param.splash.button_text_color = this.textColor;
  //     }
  //   if (item=== 'bg'){
  //     this.bgColor =value;
  //     this.tab.param.splash.button_bgcolor = this.bgColor;
  //    }
  //   this.updateButtonData(this.tab)

  // }

  updateButtonData(tab?) {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;
    this.data["splash_tab"] = this.tab;


    this.mdw._currentMenuContainer.next(this.data);

if (tab){
  this.mdw.changeTab(
    { button: this.button, tab: this.tab },
    this.mode,
    false
  );

}else {
  this.mdw.changeTab(
    { button: this.button, tab: { module: "menu" } },
    this.mode,
    true
  );
}


  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
