import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, c16 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-offcanvas-tab-settings",
  templateUrl: "./offcanvas-tab-settings.component.html",
  styleUrls: ["./offcanvas-tab-settings.component.scss"],
})
export class OffcanvasTabSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private ref: ChangeDetectorRef
  ) { }

  @Input() data: Tab;
  @Input() mode: string;
  @Input() offcanvas_data: any[] = [];

  offCanvasSubscription: any;
  updateBuilderComponentSubscription: any;
  colorsComponentSubscription: any;

  // default values
  colors: any= {};
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "tab";
  tab_id: string;
  title: string;
  hideoffcanvas: boolean;
  light: boolean = true;
  color_schema: any;
  color_schema_ios: any;


  ngOnInit() {

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (data && data.off_canvas_key == 'tab'){
          this.data = data
          this.OffCanvasData(data);
          this.colorInitialize();
        }
     });

    this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe((data) => {
      if (
        data &&
        data.color_schema &&
        data.color_schema.schemes &&
        data.color_schema.schemes.light
      ) {
        this.colors = data;
        this.light = data.light;
        this.color_schema = this.light
          ? this.colors.color_schema.schemes.light
          : this.colors.color_schema.schemes.dark;
        this.color_schema_ios = this.light
          ? this.colors.color_schema_ios.schemes.light
          : this.colors.color_schema_ios.schemes.dark;
      }
    });
  }
  OffCanvasData(data) {
    if (data) {
      if (data.off_canvas_key === "tab" && data.show==1) {
        this.tab_id = data.tab.id;
        this.title = data.tab.title;
        this.hideoffcanvas = true;
        if (data.colors) {
          this.colors = data.colors;
        }
        console.log(data.tab.module);
        switch (data.tab.module) {
          case TabNames.CHANNEL:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }],
                  },

                  {
                    cat: "Google Ads",
                    show: false,
                    list: [{ enableAds: true }],
                  },

                ],
              },

              {
                tab: "Advanced",
                data: [
                  {
                    cat: "Common Features",
                    show: false,
                    list: [{ channel_footer: true }, { channel_context_menu: true }],
                  },

                  {
                    cat: "Invitation",
                    show: false,
                    list: [{ enable_invitation_links: true }],
                  },

                ],
              },

            ];
            break;
          case TabNames.VIDEO:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                      { tabImage: true, config: {
                      id: "tab_image",
                      name: "Option",
                      image: true,
                      imageType: "image/jpeg,image/pjpeg,image/png",
                      aspectRatio: '0.5x1',
                      resizeToWidth:360,
                      resizeToHeight:680,
                      cropperView: true,
                      keyword:"video",
                      title: "Video Image",
                      key: 'image_url',
                      keyDark: 'image_url_dark',
                      removeButton: true,
                      isDark: false,
                    }, }, {  tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },{ video: true }],
                  },
                ],
              },
            ];
            break;
          case TabNames.FEED:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { chatLabel: true },{ postFooter: true }, { stickyPost: true }, { postTags: true }, { chatWithAdmin: true }, { chatWithAdminIcon: true }, { contextMenu: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.CHANNELLIST:
          case TabNames.GROUPLIST:

            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableAds: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.ONLINECHANNEL:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableAds: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.CHAT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableAds: true }, { enableVideoCall: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.SPEEDDIALER:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }],
                  },

                ],
              },
            ];
            break;
          case TabNames.CALL_LOG:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }],
                  },

                ],
              },
            ];
            break;
          case TabNames.CONTACT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { invitationLink: true }, { enableAds: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.OPENBOT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableSetBot: true, config: { key: 'chat_id' } }],
                  },

                ],
              },
            ];
            break;
          case TabNames.GROUP:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableAds: true }, { enable_invitation_links: true }, {enable_public_group: true}],
                  },

                ],
              },
            ];
            break;
          case TabNames.ONLINEGROUP:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableAds: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.OPENGROUP:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableSetGroup: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.ORDERLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }],
                  },

                ],
              },
            ];
            break;
          case TabNames.BOOKING:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableAds: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.BOOKINGLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enableAds: true }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break;
          case TabNames.QUEUINGLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }],
                  },

                ],
              },
            ];
            break;
          case TabNames.QR_PAGE:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }],
                  },

                ],
              },
            ];
            break
          case TabNames.EVENT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.EVENTLIST:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.CALENDAR:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { enable_invitation_links: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.INVITATION:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }],
                  },

                ],
              },
            ];
            break
          case TabNames.QR:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { qr: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.WEBVIEW:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { webView: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.WEBVIEW:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { webView: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.MAP_SEARCH:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { mapSearch: true }],
                  },

                ],
              },
            ];
            break
          case TabNames.SEARCH:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } }, { search: true }],
                  },

                ],
              },
            ];
            break;
            case TabNames.STORE:
            case TabNames.MARKET:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                        { tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },
                        { tabImage: true, config: {
                          id: "tab_image",
                          name: "Option",
                          image: true,
                          imageType: "image/jpeg,image/pjpeg,image/png",
                          aspectRatio: '0.5x1',
                          resizeToWidth:360,
                          resizeToHeight:651,
                          cropperView: true,
                          keyword:data.tab.module === TabNames.STORE ? TabNames.STORE : TabNames.MARKET,
                          title: "Backgroud Image",
                          key: 'image',
                          keyDark: 'image_dark',
                          removeButton: true,
                          isDark: false,
                          bg: true
                        }, },

                      ],
                  },
                ],
              },
            ];
            break;
          case TabNames.PRODUCT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                    { tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },
                    {
                      onClickSelector: true,
                      config: {
                        id: "button_label",
                        name: 'Product',
                        color: "label",
                        init_color: "primary",
                        init_color_ios: "primary",
                        scheme_color: this.colors,
                        isCollection: false,
                        isProuduct: true,
                        category: ["product"]
                      },
                    },],
                  },
                ],
              },
            ];
            break;
          case TabNames.COLLECTION:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                    { tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },
                    {
                      onClickSelector: true,
                      config: {
                        id: "button_label",
                        name: 'Collection',
                        color: "label",
                        init_color: "primary",
                        init_color_ios: "primary",
                        scheme_color: this.colors,
                        isCollection: true,
                        isProuduct: false,
                        category: ["product", "digital"]
                      },
                    },],
                  },
                ],
              },
            ];
            break;
          case TabNames.CATEGORY:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                    { tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },
                    {
                      onClickSelector: true,
                      config: {
                        id: "button_label",
                        name: 'Category',
                        color: "label",
                        init_color: "primary",
                        init_color_ios: "primary",
                        scheme_color: this.colors,
                        isCollection: true,
                        isProuduct: false,
                        category: ["booking", "event"]
                      },
                    },],
                  },
                ],
              },
            ];
            break;
          case TabNames.BOOKINGPRODUCT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                    { tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },
                    {
                      onClickSelector: true,
                      config: {
                        id: "button_label",
                        name: 'Booking Product',
                        color: "label",
                        init_color: "primary",
                        init_color_ios: "primary",
                        scheme_color: this.colors,
                        isCollection: false,
                        isProuduct: true,
                        category: ["booking"]
                      },
                    },],
                  },
                ],
              },
            ];
            break;
          case TabNames.EVENTPRODUCT:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                    { tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },
                    {
                      onClickSelector: true,
                      config: {
                        id: "button_label",
                        name: 'Event',
                        color: "label",
                        init_color: "primary",
                        init_color_ios: "primary",
                        scheme_color: this.colors,
                        isCollection: false,
                        isProuduct: true,
                        category: ["event"]
                      },
                    },],
                  },
                ],
              },
            ];
            break;
          case TabNames.CART:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },],
                  },
                ],
              },
            ];
            break;
          case TabNames.MENU:
          this.offcanvas_data = [
            {
              tab: "Basic",
              data: [
                {
                  cat: "Basic",
                  show: false,
                  list: [

                  { tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },
                  { enableSetBot: true, config: { key: 'api_id' } },
                  { tabImage: true, config: {
                    id: "tab_image",
                    name: "Option",
                    image: true,
                    imageType: "image/jpeg,image/pjpeg,image/png",
                    aspectRatio: '0.5x1',
                    resizeToWidth:360,
                    resizeToHeight:651,
                    cropperView: true,
                    keyword:"menu",
                    title: "Backgroud Image",
                    key: 'image',
                    keyDark: 'image_dark',
                    removeButton: true,
                    isDark: false,
                    setcolor: false,
                    bg: true,
                    init_color: "primary",
                    init_color_ios: "primary",
                    colors: this.colors
                  }, },

                ],

                },
              ],
            },
          ];
          break;
          case TabNames.POLL:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabPollInfo: true }],
                  },
                ],
              },
            ];
            break;
          case TabNames.MYORDERS:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [{ tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },],
                  },
                ],
              },
            ];
            break;
          case TabNames.QUERY:
            this.offcanvas_data = [
              {
                tab: "Basic",
                data: [
                  {
                    cat: "Basic",
                    show: false,
                    list: [
                      { tabInfo: true, config: { id: 'icon-color', setcolor: data.tab.cat == 'side' ? true : false, colors: this.colors } },
                      { enableSetBot: true, config: { key: 'api_id' } },
                      {
                        search_query: true,
                        config: {
                          id: "query",
                          name: "Query",
                          validation: { required: false },
                        },
                      },
                    ],
                  },
                ],
              },
            ];
            break;
            default: {
              this.hideoffcanvas = true;
            }
                 break;


        }
        if (this.hideoffcanvas && data.show){
            this.showOffcanvas();
            this.hideoffcanvas = false;
            } else {
            this.hideOffcanvas();
            }
        this.off_canvas_key = data.off_canvas_key;

      } else {
        this.hideOffcanvas();
      }
    }
  }


  drag(event: any, i: any) {
    let data = i;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
      case "store":
      case "poll":
        event.dataTransfer.setData("module", data.module);
        break;
      case "menu":
      case "page":
      case "store":
      case "poll":
        event.dataTransfer.setData("button", JSON.stringify(data));
        break;
    }
  }

  onClick(item) {
    let data = item;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
      case "store":
      case "poll":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: data.module,
        });
        break;
      case "menu":
      case "page":
      case "store":
      case "poll":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: JSON.stringify(data),
        });
        break;
    }
  }

  hideOffcanvas() {
    document
      .querySelector("#offcanvasScrollingTabsConf")
      .classList.remove("show");
  }

  showOffcanvas() {
    document.querySelector("#offcanvasScrollingTabsConf").classList.add("show");
  }

  colorInitialize() {
    try {
      const appHome = this.indexDBService.getItem("app", "home");
      appHome.onsuccess = async (event) => {
        if (appHome.result) {
          this.colors["color_schema"] = appHome.result.app.tabs.color_schema;
          this.colors["color_schema_ios"] =  appHome.result.app.tabs.color_schema_ios;
          this.colors["light"] = localStorage.getItem("mode")[15] == c16.LIGHT ? true : false;
        }
      };
    } catch (error) {}
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}
