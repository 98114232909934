import { BuilderService } from 'src/app/sharedservices/builder.service';
import { Menu } from "../../core/services/interface";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, common_components_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-offcanvas-tab-drag",
  templateUrl: "./offcanvas-tab-drag.component.html",
  styleUrls: ["./offcanvas-tab-drag.component.scss"],
})
export class OffcanvasTabDragComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}

  @Input() data: Tab;
  @Input() mode: string;
  @Input() offcanvas_data: any[] = [];

  offCanvasSubscription: any;

  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "home";

  ngOnInit() {

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (data) {
          switch (data.off_canvas_key) {
            case "home":
              let name = common_components_map[TabNames.OPENGROUP].web.name
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                    {
                      cat: "Custom Component",
                      show: true,
                      icon_color: "#4ecb69",
                      item: this.TabComponent(TabNames.MENU)
                    },
                    {
                      cat: "Components",
                      show: false,
                      list: [
                        {
                          id: "Channel Features",
                          name: "Channel Features",
                          hint: "Enable news feeds, dashboards, and channel communication",
                          icon: "rss_feed",
                          icon_color: "#f08542",
                          options: [
                            this.TabComponent(TabNames.FEED),
                            this.TabComponent(TabNames.CHANNEL),
                            this.TabComponent(TabNames.CHANNELLIST),
                            this.TabComponent(TabNames.ONLINECHANNEL),
                          ],
                        },
                        {
                          id: "Messaging Features",
                          name: "Messaging Features",
                          hint:"Add messaging, bot chat, and contact features to your app.",
                          icon: "chat",
                          icon_color: "#ffd96a",
                          options: [
                            this.TabComponent(TabNames.CHAT),
                            // this.TabComponent(TabNames.VIDEOAUDIO),
                            this.TabComponent(TabNames.OPENBOT),
                            this.TabComponent(TabNames.CONTACT),
                            this.TabComponent(TabNames.SPEEDDIALER),
                            this.TabComponent(TabNames.CALL_LOG),
                          ],
                        },
                        {
                          id: "Group Messaging",
                          name: "Group Messaging",
                          hint:"Facilitate group chats and create group lists",
                          icon: "groups",
                          icon_color: "#3ce7ea",
                          options: [
                            this.TabComponent(TabNames.OPENGROUP),
                            this.TabComponent(TabNames.GROUP),
                            this.TabComponent(TabNames.ONLINEGROUP),
                            // this.TabComponent(TabNames.ONBOARDING),
                          ],
                        },
                        {
                          id: "Commerce",
                          name: "Commerce",
                          hint:"Add a native shopping experience to your app.",
                          icon: "shopping_cart",
                          icon_color: "#fd8888",
                          options: [
                            this.TabComponent(TabNames.STORE),
                            this.TabComponent(TabNames.COLLECTION),
                            this.TabComponent(TabNames.PRODUCT),

                          ],
                        },
                        {
                          id: "Booking & Events",
                          name: "Booking & Events",
                          hint:"Enable event bookings and manage marketplaces",
                          icon: "confirmation_number",
                          icon_color: "#349fff",
                          options: [
                            this.TabComponent(TabNames.MARKET),
                            this.TabComponent(TabNames.CATEGORY),
                            this.TabComponent(TabNames.BOOKINGPRODUCT),
                            this.TabComponent(TabNames.EVENTPRODUCT),


                            // this.TabComponent(TabNames.BOOKINGLIST),
                            // this.TabComponent(TabNames.QUEUINGLIST),
                             // this.TabComponent(TabNames.EVENTLIST),
                            // this.TabComponent(TabNames.VALIDATE_TICKET),
                            // this.TabComponent(TabNames.REDEEM_TICKET),

                          ],
                        },
                        {
                          id: "Payment Components",
                          name: "Payment Components",
                          icon: "paid",
                          icon_color: "#FF9F33",
                          options: [
                            this.TabComponent(TabNames.MYORDERS),
                            this.TabComponent(TabNames.CART),
                          ],
                        },
                        // {
                        //   id: "Lists & Search",
                        //   name: "Lists & Search",
                        //   icon: "search",
                        //   icon_color: "#FFA500",
                        //   options: [
                        //     this.TabComponent(TabNames.QUERY),
                        //   ],
                        // },
                         {
                          id: "Essential Features",
                          name: "Essential Features",
                          hint:"Add essential tools like calendars, QR scanners, and webviews",
                          icon: "web",
                          icon_color: "#349fff",
                          options: [
                            this.TabComponent(TabNames.CALENDAR),
                            this.TabComponent(TabNames.INVITATION),
                            this.TabComponent(TabNames.QR),
                            this.TabComponent(TabNames.WEBVIEW),
                            this.TabComponent(TabNames.VIDEO),

                           ],
                        },
                        // {
                        //   id: "Search Tools",
                        //   name: "Search Tools",
                        //   hint:"Implement advanced search capabilities and filters",
                        //   icon: "search",
                        //   icon_color: "#9774ff",
                        //   options: [
                        //     this.TabComponent(TabNames.MAP_SEARCH),
                        //     // this.TabComponent(TabNames.SEARCH),
                        //     // this.TabComponent(TabNames.QUERY),
                        //   ],
                        // },
                      ]
                    }
                  ],
                },
              ];

              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;
            case "side":
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                    {
                      cat: "Components",
                      show: true,
                      icon_color: "#4ecb69",
                      item: this.TabComponent(TabNames.MENU)
                    },
                    {
                      cat: "Components",
                      show: false,
                      list: [
                        {
                          id: "Channel Components",
                          name: "Channel Components",
                          icon: "rss_feed",
                          icon_color: "#f08542",
                          options: [
                            this.TabComponent(TabNames.CHANNEL),
                            this.TabComponent(TabNames.CHANNELLIST),
                            this.TabComponent(TabNames.ONLINECHANNEL),
                          ],
                        },
                        {
                          id: "Chat Components",
                          name: "Chat Components",
                          icon: "chat",
                          icon_color: "#ffd96a",
                          options: [
                            this.TabComponent(TabNames.CHAT),
                            // this.TabComponent(TabNames.VIDEOAUDIO),
                            this.TabComponent(TabNames.OPENBOT),
                            this.TabComponent(TabNames.CONTACT),
                            this.TabComponent(TabNames.SPEEDDIALER),
                            this.TabComponent(TabNames.CALL_LOG),
                          ],
                        },
                        {
                          id: "Chat Groups",
                          name: "Chat Groups",
                          icon: "groups",
                          icon_color: "#3ce7ea",
                          options: [
                            this.TabComponent(TabNames.OPENGROUP),
                            this.TabComponent(TabNames.GROUP),
                            this.TabComponent(TabNames.ONLINEGROUP),
                            // this.TabComponent(TabNames.ONBOARDING),
                          ],
                        },

                        {
                          id: "Mobile Commerce",
                          name: "Mobile Commerce",
                          icon: "shopping_cart",
                          icon_color: "#fd8888",
                          options: [
                            this.TabComponent(TabNames.STORE),
                            this.TabComponent(TabNames.COLLECTION),
                            this.TabComponent(TabNames.PRODUCT),

                            // this.TabComponent(TabNames.ORDERLIST),
                          ],
                        },

                        {
                          id: "Booking and Events",
                          name: "Booking and Events",
                          icon: "confirmation_number",
                          icon_color: "#349fff",
                          options: [
                            this.TabComponent(TabNames.MARKET),
                            this.TabComponent(TabNames.CATEGORY),
                            this.TabComponent(TabNames.BOOKINGPRODUCT),
                            this.TabComponent(TabNames.EVENTPRODUCT),
                            // this.TabComponent(TabNames.BOOKINGLIST),
                            // this.TabComponent(TabNames.QUEUINGLIST),
                             // this.TabComponent(TabNames.EVENTLIST),
                            // this.TabComponent(TabNames.VALIDATE_TICKET),
                            // this.TabComponent(TabNames.REDEEM_TICKET),

                          ],
                        },

                        {
                          id: "Payment Components",
                          name: "Payment Components",
                          icon: "paid",
                          icon_color: "#FF9F33",
                          options: [
                            this.TabComponent(TabNames.MYORDERS),
                            this.TabComponent(TabNames.CART),
                          ],
                        },
                        // {
                        //   id: "Lists & Search",
                        //   name: "Lists & Search",
                        //   icon: "search",
                        //   icon_color: "#FFA500",
                        //   options: [
                        //     this.TabComponent(TabNames.QUERY),
                        //   ],
                        // },
                         {
                          id: "Essentials Components",
                          name: "Essentials Components",
                          icon: "web",
                          icon_color: "#349fff",
                          options: [

                            this.TabComponent(TabNames.CALENDAR),
                            this.TabComponent(TabNames.INVITATION),
                            this.TabComponent(TabNames.QR),
                            this.TabComponent(TabNames.WEBVIEW),
                            this.TabComponent(TabNames.VIDEO),
                            this.TabComponent(TabNames.SETTINGS),
                           ],
                        },
                        // {
                        //   id: "Search Components",
                        //   name: "Search Components",
                        //   icon: "search",
                        //   icon_color: "#9774ff",
                        //   options: [
                        //     this.TabComponent(TabNames.MAP_SEARCH),
                        //     // this.TabComponent(TabNames.SEARCH),
                        //     // this.TabComponent(TabNames.QUERY),
                        //   ],
                        // },
                      ]
                    }
                  ],
                },
              ];

              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;
            case "next":
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                      {
                      cat: "Components",
                      show: false,
                      list: [
                        {
                          id: "Channel Components",
                          name: "Channel Components",
                          icon: "rss_feed",
                          icon_color: "#f08542",
                          options: [
                            this.TabComponent(TabNames.FEED),
                            this.TabComponent(TabNames.CHANNEL),
                            this.TabComponent(TabNames.CHANNELLIST),
                            this.TabComponent(TabNames.ONLINECHANNEL),
                          ],
                        },
                        {
                          id: "Chat Components",
                          name: "Chat Components",
                          icon: "chat",
                          icon_color: "#ffd96a",
                          options: [
                            this.TabComponent(TabNames.CHAT),
                            // this.TabComponent(TabNames.VIDEOAUDIO),
                            this.TabComponent(TabNames.OPENBOT),
                            this.TabComponent(TabNames.CONTACT),
                            this.TabComponent(TabNames.SPEEDDIALER),
                            this.TabComponent(TabNames.CALL_LOG),
                          ],
                        },
                        {
                          id: "Chat Groups",
                          name: "Chat Groups",
                          icon: "groups",
                          icon_color: "#3ce7ea",
                          options: [
                            this.TabComponent(TabNames.OPENGROUP),
                            this.TabComponent(TabNames.GROUP),
                            this.TabComponent(TabNames.ONLINEGROUP),
                            // this.TabComponent(TabNames.ONBOARDING),
                          ],
                        },

                        {
                          id: "Mobile Commerce",
                          name: "Mobile Commerce",
                          icon: "shopping_cart",
                          icon_color: "#fd8888",
                          options: [
                            this.TabComponent(TabNames.STORE),
                            this.TabComponent(TabNames.COLLECTION),
                            this.TabComponent(TabNames.PRODUCT),
                          ],
                        },

                        {
                          id: "Booking & Events",
                          name: "Booking and Events",
                          icon: "confirmation_number",
                          icon_color: "#349fff",
                          options: [
                            this.TabComponent(TabNames.MARKET),
                            this.TabComponent(TabNames.CATEGORY),
                            this.TabComponent(TabNames.BOOKINGPRODUCT),
                            this.TabComponent(TabNames.EVENTPRODUCT),

                          ],
                        },
                        {
                          id: "Payment Components",
                          name: "Payment Components",
                          icon: "paid",
                          icon_color: "#FF9F33",
                          options: [
                            this.TabComponent(TabNames.MYORDERS),
                            this.TabComponent(TabNames.CART),
                          ],
                        },
                        // {
                        //   id: "Lists & Search",
                        //   name: "Lists & Search",
                        //   icon: "search",
                        //   icon_color: "#FFA500",
                        //   options: [
                        //     this.TabComponent(TabNames.QUERY),
                        //   ],
                        // },

                         {
                          id: "Essentials Components",
                          name: "Essentials Components",
                          icon: "web",
                          icon_color: "#349fff",
                          options: [
                            this.TabComponent(TabNames.CALENDAR),
                            this.TabComponent(TabNames.INVITATION),
                            this.TabComponent(TabNames.QR),
                            this.TabComponent(TabNames.WEBVIEW),
                            this.TabComponent(TabNames.VIDEO),
                            this.TabComponent(TabNames.SETTINGS),
                           ],
                        },
                        // {
                        //   id: "Search Components",
                        //   name: "Search Components",
                        //   icon: "search",
                        //   icon_color: "#9774ff",
                        //   options: [
                        //     this.TabComponent(TabNames.MAP_SEARCH),
                        //     // this.TabComponent(TabNames.SEARCH),
                        //     // this.TabComponent(TabNames.QUERY),
                        //   ],
                        // },
                      ]
                    }
                  ],
                },
              ];

              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;
           }
        }
      }
    );
  }

  drag(event: any, i: any) {
    let data = i;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
      case "next":
        event.dataTransfer.setData("module", data.module);
        break;
      case "menu":
       case "page":
        event.dataTransfer.setData("button", JSON.stringify(data));
        break;
    }
  }


  onClick(item) {
    let data = item;
     switch (this.off_canvas_key) {
      case "home":
      case "side":
      case "next":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: data.module,
        });
        break;
      case "menu":
      case "page":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: JSON.stringify(data),
        });
        break;
    }
  }

  hideOffcanvas() {
    const faide = document.querySelector("#faide");
    if(faide){
      document.querySelector("#offcanvasScrolling").classList.remove("show");
      faide.classList.remove("animate-fede-out");
      faide.classList.add("animate-fede-in");
    }
  }

  showOffcanvas() {
    document.querySelector("#offcanvasScrolling").classList.add("show");
  }


  show(id) {
    const parentElement = document.getElementById(id);
    if (!parentElement.classList.contains("mm-active")) {
      parentElement.classList.add("mm-active");
      parentElement.getElementsByTagName("ul")[0].classList.add("mm-show");
    } else {
      parentElement.classList.remove("mm-active");
      parentElement.getElementsByTagName("ul")[0].classList.remove("mm-show");
    }
  }


  TabComponent(module) {
    let component =
      {
        module: module,
        name: common_components_map[module].web.name,
        icon: common_components_map[module].icon,
        hint:common_components_map[module].hint,
      }
    return component
  }
  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
