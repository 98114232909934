<div class="row z-0 m-0">
  <div class="col-12 p-0 simplebar-content-wrapper">
    <div class="card p-0 m-0">
      <img [src]="image" class="card-img img-fluid" alt="empty">
      <div class="card-img-overlay p-0" [ngStyle]="setTabBg">
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.OPENCHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.CHANNEL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.OPENBOT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.CALL_LOG" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.VIDEOAUDIO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.CONTACT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.CHANNELLIST" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.ONLINEGROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.MAP_SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.BOOKINGLIST" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.GROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.BOOKING" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.CALENDAR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.VALIDATE_TICKET" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.REDEEM_TICKET" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.INVITATION" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.OPENGROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.ONLINECHANNEL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.EMPTY" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.SPEEDDIALER" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.MYORDERS" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.CHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.QR" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.SETTINGS" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.PRODUCT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.COLLECTION" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.CATEGORY" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.BOOKINGPRODUCT" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.EVENTPRODUCT" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.CART" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>


        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.PAYMENT_VIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.LOGIN_VIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>

        <app-common-tab-component *ngIf="inputData && inputData?.module === tabNames?.SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>


        <app-feeds *ngIf="inputData && inputData?.module === tabNames?.FEED" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-feeds>
        <app-web-view *ngIf="inputData && inputData?.module === tabNames?.WEBVIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-web-view>
        <app-video-view *ngIf="inputData && inputData?.module === tabNames?.VIDEO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-video-view>
        <app-search-view *ngIf="inputData && inputData?.module === tabNames?.SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-search-view>

        <app-menus *ngIf="!enableStatic && inputData && inputData?.module === tabNames?.MENU || inputData?.module === tabNames?.PAGE || inputData?.module === tabNames?.SPLASH || inputData?.module === tabNames?.STORE || inputData?.module === tabNames?.MARKET ||inputData?.module === tabNames?.POLL || inputTab?.module === tabNames?.QUERY" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" [inputMenuId]="menu_id"  [mobViewBuilder]="true" [fixedTop]=true [colors]="colors" [inputMenu]="inputMenu"></app-menus>
        <app-menus *ngIf="enableStatic && inputData && (inputData?.module === tabNames?.MENU || inputData?.module === tabNames?.PAGE || inputData?.module === tabNames?.SPLASH || inputData?.module === tabNames?.STORE || inputData?.module === tabNames?.MARKET ||inputData?.module === tabNames?.POLL || inputTab?.module === tabNames?.QUERY) " [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" [inputMenuId]="menu_id" [enableStaic]="enableStatic" [staticTab]="staticTab" [staticMenuId]="staticMenuId" [mobViewBuilder]="true" [fixedTop]=true [colors]="colors" [inputMenu]="inputMenu"></app-menus>

        <!-- <app-qr-view *ngIf="inputData && inputData?.module === tabNames?.QR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-view>
        <app-qr-page-view *ngIf="inputData && inputData?.module === tabNames?.QR_PAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-page-view> -->
        <!-- <app-chat-view-component *ngIf="inputTab && inputTab?.module === tabNames?.CHAT" [fontResize]="fontResize" [inputTab]="inputTab"></app-chat-view-component> -->
      </div>
    </div>
  </div>
</div>
