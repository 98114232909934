<app-image-uploader
  [data]="imageConfig"
  [bg]="bgcolor"
  (images)="buttonImage($event)"
  (imageSet)="buttonImageSet($event)"
></app-image-uploader>

<ng-container *ngIf="config?.isDark">
  <div class="card m-0 bg-transparent">
    <div class="card-body pt-0" [class.pb-0]="toggleDarkImage">
      <div
        class="form-check d-flex align-items-center justify-content-between p-0"
      >
        <label
          class="offcanvas-header-text w-100 m-0"
          [for]="button?.id + 'dark'"
        >
          Dark Mode Image
        </label>
        <input
          class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
          type="checkbox"
          [checked]="toggleDarkImage"
          (change)="enableDarkImageMode($event)"
          style="width: 18px; height: 18px"
          [id]="button?.id + 'dark'"
        />
      </div>
    </div>
  </div>
  <app-image-uploader
    *ngIf="toggleDarkImage"
    [data]="imageDarkConfig"
    [bg]="bgcolor"
    (images)="buttonImageDark($event)"
    (imageSet)="buttonImageDarkSet($event)"
  ></app-image-uploader>
</ng-container>
