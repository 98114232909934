import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MENU_BUTTON_STYLE, MENU_CELL_STYLE, c1 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-slider",
  templateUrl: "./button-slider.component.html",
  styleUrls: ["./button-slider.component.scss"],
})
export class ButtonSliderComponent implements OnInit, OnDestroy {
  constructor(
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) { }

  selectform: UntypedFormGroup; // bootstrap validation form

  @Input() data: Tab;
  @Input() mode: string;
  @Input() fontResize= false;
  @Input() fontSmaller= false;
  @Input() fixedTop : boolean = false;
  @Input() button: MenuButton;
  @Input() row_id: string;
  @Input() colors:any;
  @Input() no_buttons = 1;
  @Input() mobileView= false;
  no_cells=2;
//////////////////// what have been used //////////////
textAlign: any = {};
textFontsize: any = {};

currentMenuSubscription: any;
updateBuilderComponentSubscription: any;
offCanvasSubscription: any;
colorsComponentSubscription: any;

helper:string ;// = 'Helper Text';
error: string= null //'error';
label_color: string;

helper_color: string //='#8e8e93'
error_color: string //= '#FF0000' ;

leading_icon: string = null // 'fas fa-heart' ;   //must have a value to enable it used here as flag to enable and disable only
trailing_icon: string = 'bx bx-caret-down' //'fas fa-angle-down';

radio_andriod = 'mdi mdi-checkbox-blank-outline';            //checkbox not a radio
radioSelected_andriod = 'mdi mdi-checkbox-marked'   // checkbox not a radio

radio_ios = 'mdi mdi-checkbox-blank-circle-outline';
radioSelected_ios ='mdi mdi-check-circle'
sublabelSetting: any
labelSetting: any;

// right sign
// radio_ios = '';
// radioSelected_ios ='mdi mdi-check'

upArrow  = 'bx bx-caret-up' ;
downArrow  = 'bx bx-caret-down' ;
downArrow_ios ='mdi mdi-chevron-down-box';
option_selected_ios = 'fas fa-check';

error_icon: string = 'fas fa-exclamation-circle';
leading_icon_color: string //='#676767';
leading_icon_color_selected: string //='#676767';
trailing_icon_color: string //='#676767';
trailing_icon_selected_color: string

border_default_color: string //= '#B8B8B8';

suffix: string;
suffix_color: string
prefix : string;
prefix_color : string;

border_color : string;
selected_border_color : string;
border_color_ios: string;
option_active_bg_color: string //= '#F2F2F2';


bg_image: string
bg_color: string ;
start_color: string;
end_color: string;
image: string;
album_button: any;
albums: any;
emptyImage: string;
option_color: string;
primary_color: string;
active_icon_color_ios: string;
cellHeight: string;
padding: string;
album_input_map: any;
isAndroid : boolean;
current_button: any;
isActive: boolean;
optionSetting: any;
optionPadding: any;
optionborder: any;
options : any [];
activeOption: any={};
noOptions: number;
outlined_border: string;

divider: boolean;
outlined: boolean;
mirror: boolean;
shadow: boolean;

headline_color: string;
subhead_color: string;
heading: string
subheading: string

heading_color: string;
subheading_color: string;

radio: string;
radioSelected: string;
settings: any;

label_size: string;
sublabel_size: string;

label_align: string;
sublabel_align: string;
/// colors to be common into the tab section///
light: boolean = true;
color_schema: any;
color_schema_ios: any;
selected_bg_color: string;
style: string;
showDoneIcon = false;
box_shadow = 'none';
sliderValue: number = 50;  // Initial value of the slider
  minValue: number = 0;      // Minimum value of the range
  maxValue: number = 100;    // Maximum value of the range
  activeColor: string = "#65558f";  // Active color (color for the selected portion)
  inactiveColor: string = "#e8def8";
  dots= [{id: 0, postion: 0}, {id: 0, postion: 100}]
  range1Value=0;
  range2Value=0;
  range = false;
  step=1;
  ticks=false;
  valueStart = -50;
  valueEnd = 50
//x000
//x   : content style
//2nd : mirror
//3rd : divider
//4th : card type
option_label_selected_color: string;
rangeId= 'inputRange';
ngOnInit() {
  this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDROID? true: false;
  let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
  this.initialize(style,this.no_buttons)

  this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
    if (data && !data.fromButton){
      if (data["sortedButtonList"][this.row_id]){
        this.no_buttons = data["sortedButtonList"][this.row_id].length >0 ? data["sortedButtonList"][this.row_id].length: 1
        this.current_button =data["currentButton"];
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
      }

      if (data['colors']) {
        this.colors = data["colors"];
        this.light = data["light"];
        this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
        this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark
      }

      let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
      this.initialize(style, this.no_buttons)
      }

  })

  this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
    (data) => {
      if (data  && data.color_schema  && data.color_schema_ios && data.light !=null) {
        this.colors = data;
        this.light = data.light
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
      }
    })



  this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
    (data) => {
      // only for getting default button to change style for active or none active button style

       if (data && data.button){

        this.current_button =data.button;
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
         }
    }
  );

  this.updateBuilderComponentSubscription =
  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {
        // only to get different views for button for Android and iOS. if both same view we do not need this subscription.
        this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDROID? true: false;
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
      }
  });

}

initialize(style, no_button){
  this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDROID? true: false;
  if (this.colors && this.button) {
    this.no_cells=this.button.button_option.length;
    this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
    this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark;
    this.emptyImage = './assets/images/mobile/splash20.webp';

     // this.label = this.button.button_label
     this.helper = this.error ? this.button.button_error : this.button.button_helper;
     this.options = this.button.button_option;   // all option values
     this.noOptions = this.options.length;



     for(let i=0; i < this.button.button_value.length; i++){
      this.activeOption[this.button.button_value[i].id]= this.button.button_value[i];  // the initial default value
     }
     this.bg_image = null;
     this.start_color = null;
     this.end_color = null;
     this.radio = this.isAndroid ? this.radio_andriod : this.radio_ios
     this.radioSelected = this.isAndroid ? this.radioSelected_andriod : this.radioSelected_ios;
    // style configuration params
    // this.border_default_color = '#B8B8B8';
    // this.option_active_bg_color = '#F2F2F2'

    // Theme Settings
    let cellStyle = this.convertStyle(style);
    this.album_button = album_input_map[cellStyle];
    this.settings = this.isAndroid ? this.album_button.settings : this.album_button.settings_ios;
    this.optionSetting = this.isAndroid ? this.album_button.options : this.album_button.options_ios;
    this.cellHeight = this.fontSmaller ? this.settings.cellHeight_s : this.settings.cellHeight;
    this.optionPadding = this.fontSmaller ? this.optionSetting.padding_s : this.no_buttons === 3 ? this.optionSetting.padding['3'] : this.optionSetting.padding['default'];
    this.optionborder = this.fontSmaller ? this.album_button.border.borderRadius.rounded_s : this.album_button.border.borderRadius.default;
    this.labelSetting = this.isAndroid ? this.album_button.label : this.album_button.label_ios;
    this.sublabelSetting = this.isAndroid ? this.album_button.sublabel : this.album_button.sublabel_ios;
    this.showDoneIcon = this.button.button_style !== '0200' && this.button.button_style !== '0202' ? true : false;
    // Colors
    this.themeColors(style);

    // Colors
    this.helper_color = this.isAndroid? this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'helper', 'onSurfaceVariant', this.light)
    :this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'helper', 'onSurfaceVariant', this.light)

    this.error_color = this.isAndroid ? //this.color_schema.error : this.color_schema_ios.error
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'error', 'error', this.light)
    :this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'error', 'error', this.light)
    // unselected outline color
    this.border_color = this.error ? this.error_color : this.isActive ?  // this.color_schema.primary :  this.color_schema.outline;   // Android
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'outline', this.light):
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'outline', this.light)
    // selected outline color
    this.selected_border_color = this.error ? this.error_color : this.isActive ?  // this.color_schema.primary :  this.color_schema.outline;   // Android
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'nochange', 'secondaryContainer', this.light):
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'nochange', 'secondaryContainer', this.light)

    this.border_color_ios = this.error ? this.error_color : //this.color_schema_ios.outline;   // iOS
    this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'outline', 'outline', this.light)


    // Selected color
    this.selected_bg_color =  this.isAndroid ?
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'nochange', 'secondaryContainer', this.light)
   : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'nochange', 'secondaryContainer', this.light)


    // // option Normal bg color (normal)
    // this.bg_color = this.isAndroid ? this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'nochange', 'surfaceContainerLow', this.light)
    // : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'nochange', 'n2SurfaceContainerHigh', this.light);



  // option selected label color
    this.label_color = this.isAndroid ?
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'nochange', 'onSecondaryContainer', this.light)
    : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'nochange', 'onSecondaryContainer', this.light);

  // option unselected Label color
  this.option_color = this.isAndroid ?
  this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onSurfaceVariant', this.light)
  : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'onSurfaceVariant', this.light);

  // option selected icon  color
  this.leading_icon_color_selected = this.isAndroid ?
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'nochange', 'onSecondaryContainer', this.light)
    : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'nochange', 'onSecondaryContainer', this.light);
  // option unselected icon  color
  this.leading_icon_color = this.isAndroid ?
    this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'primary', this.light)
    : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'primary', this.light);



      this.heading_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onSurface', this.light)

      this.subheading_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onSurface', this.light)


    this.trailing_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onSurface', this.light)
      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;

        this.headline_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onSurfaceVariant', this.light)

      this.subhead_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onSurfaceVariant', this.light);

      this.activeColor = this.isAndroid ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'active', 'primary', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'active', 'primary', this.light);
      this.inactiveColor = this.isAndroid ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'inactive', 'secondaryContainer', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'inactive', 'secondaryContainer', this.light);


     this.heading = this.isAndroid? this.button.button_headline:this.button.button_headline
     this.subheading =  this.isAndroid? this.button.button_subhead:this.button.button_subhead

     this.textAlign = this.isAndroid?this.button.button_text_align:this.button.button_text_align_ios

     this.label_align = this.textAlign && this.textAlign.headline? this.textAlign.headline : null;
     this.sublabel_align = this.textAlign && this.textAlign.subhead ? this.textAlign.subhead: null;

     this.textFontsize =this.isAndroid?this.button.button_text_fontsize:this.button.button_text_fontsize_ios
     this.label_size = this.textFontsize && this.textFontsize.headline? this.textFontsize.headline : null;
     this.sublabel_size = this.textFontsize && this.textFontsize.subhead ? this.textFontsize.subhead: null;


    // this.outlined_border = this.outlined ? 'solid 1px;' : '';
    // this.border_default_color = this.error ? this.error_color : this.color_schema.outline;

    this.cardStyle(style);
    if(this.fontSmaller){
      this.rangeId = 'screenRangeId'+ this.button.button_id;
    }else if(this.fontResize){
      this.rangeId = 'mobRangeId'+ this.button.button_id;
    }else{
      this.rangeId = 'inputRange' + this.button.button_id;
    }

    this.outlined_border = this.outlined ? 'solid 1px;' : '';
    this.box_shadow = this.shadow ? '0px 3px 9px -5px rgba(66, 68, 90, 1)' : 'none';
  }
}
themeColors(cellStyle){
  switch (Number(cellStyle[3])){
  // with Elevated style: 00
  case MENU_CELL_STYLE.STYLE_0:
    this.image = null
    this.leading_icon = 'true';
    this.trailing_icon ="true";
    this.bg_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerLow', this.light)
       :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerLow', this.light);
    break;
    // with outline  Style 02
  case MENU_CELL_STYLE.STYLE_2:
      this.image = this.button.button_img_url? this.button.button_img_url: null;
      this.leading_icon = 'true'
      this.trailing_icon ="true"
      this.bg_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surface', this.light)
       :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surface', this.light);
      break;
  }
}
get helperColor() {
  return {
  "color": this.helper_color? this.helper_color: '#EBEBEB',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'8px':'10px',
};
}
get errorColor() {
  return {
  "color": this.error_color? this.error_color: '#FF0000',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'10px':'12px',
};
}
get optionTextColor() {
  return {
    'color': this.option_color,
    "font-size": this.fontSmaller ? "4px" : "12px",
  };
}

get optionBgColor() {
  return {
    "background-color": this.bg_color ? this.bg_color : 'transparent',
    "border-color": this.border_color,
    };
}

get optionSelectedBgColor() {
  return {
    "background-color": this.selected_bg_color,
    "border-color": this.selected_border_color,
    };
}

get leadingSelectedIconColor() {
  return {
    "color": this.leading_icon_color_selected? this.leading_icon_color_selected: '#676767',
    'font-size': this.fontSmaller ? '4px' : '18px',
  };
}
get leadingIconColor() {
  return {
    "color": this.leading_icon_color? this.leading_icon_color: '#676767',
    'font-size': this.fontSmaller ? '4px' : '18px',
  };
}


get labelColor() {
  return {
    "color": this.label_color,
    'font-size': this.fontSmaller ? '4px' : '12px',
  }
}

get traillingIconColor() {
  return {
  "color": this.trailing_icon_color? this.isAndroid? this.isActive? this.primary_color: this.trailing_icon_color: '#676767': this.trailing_icon_color,
  'font-size': this.fontSmaller ? '4px' : this.isAndroid? '18px': '20px',
};
}

get borderColor() {
    return {
      "background-color": "solid 1px " + this.primary_color,
      "height": "40px"
    };
  }

  borderColorSelected(id) {
    return this.activeOption[id] ? this.selected_border_color : this.border_color
  }

  get headlineColor() {
    return {
      "color": this.headline_color,
      'font-size': this.fontSmaller ? '4px' : this.no_buttons == 2 ? '12px' : this.no_buttons == 3 ? '10px' : (this.labelSetting && this.labelSetting.titleSize) ? this.fontScale('headline', this.labelSetting.titleSize) : this.fontScale('headline', '12px'),
    };
  }


  get subheadColor() {
    return {
      "color": this.subhead_color,
      'font-size': this.fontSmaller ? '4px' : this.no_buttons == 2 ? '12px' : this.no_buttons == 3 ? '10px' : (this.sublabelSetting && this.sublabelSetting.titleSize) ? this.fontScale('subhead', this.sublabelSetting.titleSize) : this.fontScale('subhead', '12px'),
    };
  }


  get noDivider(){
    return {
      "height": this.cellHeight,
    }
    };
  get colorsMain(){
    return {
      "--md-slider-handle-color": this.activeColor,
      "--md-slider-active-track-color": this.button.button_style === '0201' ?this.inactiveColor : this.activeColor,
      "--md-sys-color-primary": this.activeColor,
      "--md-slider-inactive-track-color": this.inactiveColor,
      "transform" : this.fontSmaller ? 'scale(0.5)' : 'none'
    }
  }

get optionDivider(){
  return {
    "height": this.cellHeight,
    "border-bottom": 'solid 1px',
    "border-color": this.isAndroid? this.border_color: this.border_color_ios,
  }
  };

  get setButtonBg() {
    if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    }
    else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      // console.log("bg_color", this.bg_color);
      return {
        'background': this.bg_color,  };
    }else {
      return { background: "unset" };
    }
  }

  convertStyle (style){
    let cell_style = style.slice(0,2);
    switch(style){
      case '0100':
        this.sliderValue = 0;  // Initial value of the slider
        this.minValue = this.button.button_min;      // Minimum value of the range
        this.maxValue = this.button.button_max;    // Maximum value of the range
        this.step=this.button.button_step;
        this.range = false;
        this.ticks= false;
        break;
      case '0101':
        this.sliderValue = 0;  // Initial value of the slider
        this.minValue = this.button.button_min;      // Minimum value of the range
        this.maxValue = this.button.button_max;    // Maximum value of the range
        this.step=  this.button.button_step;
        this.range = false;
        this.ticks=true;
        break;
      case '0200':
        this.sliderValue = 0;  // Initial value of the slider
        this.minValue = this.button.button_min;      // Minimum value of the range
        this.maxValue = this.button.button_max;    // Maximum value of the range
        this.step=  this.button.button_step;
        this.range = true;
        this.ticks= false;
        this.valueStart = 0;
        this.valueEnd = 0;
        break;
      case '0201':
        this.sliderValue = 0;  // Initial value of the slider
        this.minValue = this.button.button_min;      // Minimum value of the range
        this.maxValue = this.button.button_max;    // Maximum value of the range
        this.step=  this.button.button_step;
        this.range = false;
        this.ticks= false;
        break;
    }

    return cell_style;

}

cardStyle (style){
  switch (Number(style[3])){
    case  0:
      // elevated card
      this.outlined = false;
      this.shadow  = true;
      this.start_color = null;
      this.end_color = null;
      this.bg_color = this.isAndroid ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerLow', this.light)
     :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerLow', this.light);
    break;
    case 1:
      // filled card
    this.outlined = false;
    this.shadow  = false
    this.start_color = null;
    this.end_color = null;
    this.bg_color = this.isAndroid ?
     this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerHighest', this.light)
    :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerHighest', this.light)
    break;

   case 2:
      // outlined card
      this.outlined = true;
      this.shadow  = false
      this.start_color = null;
      this.end_color = null;
      this.bg_color = this.isAndroid ?
       this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surface', this.light)
      :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surface', this.light)
      break;
    case 4:
        this.outlined = false;
        this.shadow  = false;
        this.start_color = null;
        this.end_color = null;
        this.bg_color = null;
        break;

      default:
        break;

  }

}

ngOnDestroy(): void {
  if (this.updateBuilderComponentSubscription) {
    this.updateBuilderComponentSubscription.unsubscribe();
  }
  if (this.currentMenuSubscription) {
    this.currentMenuSubscription.unsubscribe();
  }

  if (this.offCanvasSubscription) {
    this.offCanvasSubscription.unsubscribe();
  }

  if (this.colorsComponentSubscription) {
    this.colorsComponentSubscription.unsubscribe();
  }
}

fontScale(item, value) {
  let result: number;

  result = value.replace("px", "");

  // large is greater +2 and smaller is less -2
  if (this.textFontsize && this.textFontsize[item]) {

    switch (this.textFontsize[item]) {
      case 'sm':
        result = Number(result) - 2;
        return result + 'px'
      case 'md':
        return Number(result) + 'px'
      case 'lg':
        result = Number(result) + 2;
        return result + 'px'
    }
  } else {
    return value
  }
}

}


export const album_input_map = {

  [MENU_BUTTON_STYLE?.STYLE_01]: {
  settings:{cellHeight:'32px', cellHeight_s:'14px', style: {elvated: 'shadow-sm', outline: 'border border-1 shadow-none'}},
  border: { borderRadius: {default:'rounded-3', rounded_s: 'rounded-2'}},
  options: {padding:{default:'p-2'}, padding_s:'p-0',},
  label: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '16px', titleSize_s: '4px' },
  sublabel: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '12px', titleSize_s: '4px' },


 settings_ios:{cellHeight:'32px', cellHeight_s:'14px', style: {elvated: 'shadow-sm', outline: 'border border-1 shadow-none'}},
 options_ios: {padding:{default:'px-2', 3:'px-1'}, padding_s:'p-0',},
 label_ios: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '14px', titleSize_s: '4px' },
 sublabel_ios: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '12px', titleSize_s: '4px' },

},
[MENU_BUTTON_STYLE?.STYLE_02]: {
  settings:{cellHeight:'32px',  cellHeight_s:'14px',style: {elvated: 'shadow-sm', outline: 'border border-1 shadow-none'}},
  border: {borderRadius: {default:'rounded-3', rounded_s: 'rounded-2'}},
  options: {padding:{default:'px-2',}, padding_s:'p-0',},
  label: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '16px', titleSize_s: '4px' },
  sublabel: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '12px', titleSize_s: '4px' },

  settings_ios:{cellHeight:'36px',  cellHeight_s:'14px', style: {elvated: 'shadow-sm', outline: 'border border-1 shadow-none'}},
  options_ios: {padding:{default:'px-2',}, padding_s:'p-0',},
  label_ios: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '14px', titleSize_s: '4px' },
    sublabel_ios: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '12px', titleSize_s: '4px' },
},

[MENU_BUTTON_STYLE?.STYLE_03]: {
  settings:{cellHeight:'36px',  cellHeight_s:'14px',},
  options: {padding:{default:'px-2', }, padding_s:'p-0',},
  label: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '16px', titleSize_s: '4px' },
  sublabel: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '12px', titleSize_s: '4px' },

  settings_ios:{cellHeight:'36px',  cellHeight_s:'14px', },
  options_ios: {padding:{default:'px-2',}, padding_s:'p-0',},
  label_ios: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '14px', titleSize_s: '4px' },
  sublabel_ios: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '12px', titleSize_s: '4px' },
}
}



