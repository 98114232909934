import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { c1, offcanvasColors, splash_config_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-tabsplash-tnc",
  templateUrl: "./button-tabsplash-tnc.component.html",
  styleUrls: ["./button-tabsplash-tnc.component.scss"],
})

export class ButtonTabSplashTncComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) { }

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() tabData: Tab;
  @Input() config: any;
  @Input() colors: any;

  imageConfig: any = {};
  button: MenuButton;
  image: string;
//
  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"
  component_id: string;
  field_id: string;
  tab: Tab;
  textColor: string;
  textDarkColor: string;
  linkColor: string;
  linkDarkColor: string;
  enableBg: boolean;
  bgColor: string;
  bgDarkColor: string;
  enableCustomColor =false;
  isAndroid: boolean;
  light: boolean = true;
  sync = true;
  btn_bg: string;
  labelColor: string;
  button_color_light: any = {};
  button_color_dark: any = {};
  ngOnInit() {
    this.enableBg= this.config.bg? true: false


    this.field_id = 'tabsplashtnc'
    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data)

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton && this.button_id &&
          data.currentButton.button_id == this.button_id
          && data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          let tab = data["splash_tab"]
          this.buttonFunction(tab)

          }
        }
    );
  }

 intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.tabData)
  }

  buttonFunction(tabData){
    if (tabData && tabData.param && tabData.param.splash){
      this.tab= tabData;

      // this.textColor = this.tab.param.splash.tnc_text_color;
        // this.textColor = this.builderService.getTabSplashItem(this.tab.param.splash, 'tnc_text_color', this.tab.param.splash.style);
        // this.linkColor = this.tab.param.splash.tnc_link_color;
        // this.linkColor = this.builderService.getTabSplashItem(this.tab.param.splash, 'tnc_link_color', this.tab.param.splash.style);

        // this.bgColor = this.tab.param.splash.tnc_link_color;
        // this.bgColor = this.builderService.getTabSplashItem(this.tab.param.splash, 'tnc_bgcolor', this.tab.param.splash.style);

        this.isAndroid =  localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      if (this.config.setcolor){
        this.colors = this.isAndroid
        ? this.config.scheme_color.color_schema
        : this.config.scheme_color.color_schema_ios;
      }

      if (this.isAndroid) {
        if (
          this.tab.param &&
          this.tab.param.splash &&
          this.tab.param.splash.color_schema &&
          this.tab.param.splash.color_schema.schemes &&
          this.tab.param.splash.color_schema.schemes.light &&
          this.tab.param.splash.color_schema.schemes.dark &&
          this.tab.param.splash.color_schema.schemes.light["tnc_text"] &&
          this.tab.param.splash.color_schema.schemes.light["tnc_link"] &&
          this.tab.param.splash.color_schema.schemes.dark["tnc_text"] &&
          this.tab.param.splash.color_schema.schemes.dark["tnc_link"]
        ) {
          this.textColor = this.tab.param.splash.color_schema.schemes.light["tnc_text"];
          this.linkColor = this.tab.param.splash.color_schema.schemes.light["tnc_link"];
          this.textDarkColor = this.tab.param.splash.color_schema.schemes.dark["tnc_text"];
          this.linkDarkColor = this.tab.param.splash.color_schema.schemes.dark["tnc_link"];
          this.enableCustomColor = true;
        }
        if (
          this.enableBg &&
          this.tab.param &&
          this.tab.param.splash &&
          this.tab.param.splash.color_schema &&
          this.tab.param.splash.color_schema.schemes &&
          this.tab.param.splash.color_schema.schemes.light &&
          this.tab.param.splash.color_schema.schemes.dark &&
          this.tab.param.splash.color_schema.schemes.light["tnc_bg"] &&
          this.tab.param.splash.color_schema.schemes.dark["tnc_bg"]
        ) {
          this.bgColor = this.tab.param.splash.color_schema.schemes.light["tnc_bg"];
          this.bgDarkColor = this.tab.param.splash.color_schema.schemes.dark["tnc_bg"];
          this.enableCustomColor = true;
        }

      } else {
        if (
          this.tab.param &&
          this.tab.param.splash &&
          this.tab.param.splash.color_schema_ios &&
          this.tab.param.splash.color_schema_ios.schemes &&
          this.tab.param.splash.color_schema_ios.schemes.light &&
          this.tab.param.splash.color_schema_ios.schemes.dark &&
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_text"] &&
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_link"] &&
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_text"] &&
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_link"]
        ) {
          this.textColor = this.tab.param.splash.color_schema_ios.schemes.light["tnc_text"];
          this.linkColor = this.tab.param.splash.color_schema_ios.schemes.light["tnc_link"];
          this.textDarkColor = this.tab.param.splash.color_schema_ios.schemes.dark["tnc_text"];
          this.linkDarkColor = this.tab.param.splash.color_schema_ios.schemes.dark["tnc_link"];
          this.enableCustomColor = true;
        }

        if (
          this.enableBg &&
          this.tab.param.splash.color_schema_ios &&
          this.tab.param.splash.color_schema_ios.schemes &&
          this.tab.param.splash.color_schema_ios.schemes.light &&
          this.tab.param.splash.color_schema_ios.schemes.dark &&
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_bg"] &&
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_bg"]
        ) {
          this.bgColor = this.tab.param.splash.color_schema_ios.schemes.light["tnc_bg"];
          this.bgDarkColor = this.tab.param.splash.color_schema_ios.schemes.dark["tnc_bg"];
          this.enableCustomColor = true;
        }
      }

    }
  }

  buttonBgColor(item, value, manual, mode ='color') {
    let onMode : string;
    let linkContainer : string;
    if (mode=='color'){
      onMode ='onColor'
    }else {
      onMode ='color'
    }

    let color = null;
    color = (manual == true)?this.isValidHexaCode(value.target.value)? value.target.value :null : value
    if (color){
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.colors = this.isAndroid ? this.config.scheme_color.color_schema : this.config.scheme_color.color_schema_ios;
      let customColor = this.mdw.customColor(color, this.colors.source, mode);
      let onCustomColor = this.mdw.customColor(color, this.colors.source, onMode);

      // let linkCustomColor = this.mdw.customColor(color, this.colors.source);

      if (item === 'text') {
        this.textColor = customColor.light;
        this.textDarkColor = customColor.dark;

        this.bgColor = onCustomColor.light;
        this.bgDarkColor = onCustomColor.dark;

        let linkCustomColor = this.mdw.customColor(color, this.colors.source, 'colorContainer');

        this.linkColor = this.colors.schemes.light.hint
        this.linkDarkColor = this.colors.schemes.dark.hint


        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["tnc_text"] = this.textColor;
          this.tab.param.splash.color_schema.schemes.dark["tnc_text"] = this.textDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_text"] = this.textColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_text"] = this.textDarkColor;
        }

        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["tnc_bg"] = this.bgColor;
          this.tab.param.splash.color_schema.schemes.dark["tnc_bg"] = this.bgDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_bg"] = this.bgColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_bg"] = this.bgDarkColor;
        }


        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["tnc_link"] = this.linkColor;
          this.tab.param.splash.color_schema.schemes.dark["tnc_link"] = this.linkDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_link"] = this.linkColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_link"] = this.linkDarkColor;
        }


      }
      if (item === 'link') {
        this.linkColor = customColor.light;
        this.linkDarkColor = customColor.dark;

        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["tnc_link"] = this.linkColor;
          this.tab.param.splash.color_schema.schemes.dark["tnc_link"] = this.linkDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_link"] = this.linkColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_link"] = this.linkDarkColor;
        }
      }

      if (item=== 'background'){
        this.bgColor = customColor.light;
        this.bgDarkColor = customColor.dark;

        this.textColor = onCustomColor.light;
        this.textDarkColor = onCustomColor.dark;

        let linkCustomColor = this.mdw.customColor(color, this.colors.source, 'onColorContainer');

        this.linkColor = linkCustomColor.light;
        this.linkDarkColor = linkCustomColor.dark;


        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["tnc_bg"] = this.bgColor;
          this.tab.param.splash.color_schema.schemes.dark["tnc_bg"] = this.bgDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_bg"] = this.bgColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_bg"] = this.bgDarkColor;
        }

        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["tnc_text"] = this.textColor;
          this.tab.param.splash.color_schema.schemes.dark["tnc_text"] = this.textDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_text"] = this.textColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_text"] = this.textDarkColor;
        }

        if (this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema.schemes.light["tnc_link"] = this.linkColor;
          this.tab.param.splash.color_schema.schemes.dark["tnc_link"] = this.linkDarkColor;
        }
        if (!this.isAndroid || this.sync) {
          this.tab.param.splash.color_schema_ios.schemes.light["tnc_link"] = this.linkColor;
          this.tab.param.splash.color_schema_ios.schemes.dark["tnc_link"] = this.linkDarkColor;
        }

      }
      this.updateButtonData(this.tab)

    }

  }

  toggleGradientColor(event) {
    this.enableCustomColor = event;

    if (this.isAndroid || this.sync) {
      /// Android Settings
      if (this.enableCustomColor) {
        this.textColor = this.colors.schemes.light[this.config.init_text];
        this.textDarkColor = this.colors.schemes.dark[this.config.init_text];
        this.linkColor = this.colors.schemes.light[this.config.init_link];
        this.linkDarkColor = this.colors.schemes.dark[this.config.init_link];
        if(this.enableBg){
          this.bgColor = this.colors.schemes.light[this.config.init_bg];
          this.bgDarkColor = this.colors.schemes.dark[this.config.init_bg];
      }
        if (
          this.tab.param.splash.color_schema &&
          this.tab.param.splash.color_schema.schemes
        ) {
          const schemes = this.tab.param.splash.color_schema.schemes;
          this.button_color_light = schemes.light;
          this.button_color_light = {
            ...this.button_color_light,
            tnc_text: this.textColor,
            tnc_link: this.linkColor,
          };
          this.button_color_dark = schemes.dark;
          this.button_color_dark = {
            ...this.button_color_dark,
            tnc_text: this.textDarkColor,
            tnc_link: this.linkDarkColor,
          };
          if(this.enableBg) {
            this.button_color_light = {
              ...this.button_color_light,
              tnc_bg: this.bgColor,
            };
            this.button_color_dark = {
              ...this.button_color_dark,
              tnc_bg: this.bgDarkColor,
            };
          }
          this.tab.param.splash.color_schema.schemes = {
            light: { ...this.button_color_light },
            dark: { ...this.button_color_dark },
          };
        } else {
          this.tab.param.splash = {
            ...this.tab.param.splash,
            color_schema: {
              schemes: {
                light: {
                  tnc_text: this.textColor,
                  tnc_link: this.linkColor,
                },
                dark: {
                  tnc_text: this.textDarkColor,
                  tnc_link: this.linkDarkColor,
                },
              },
            },
          };
          if(this.enableBg){
            this.tab.param.splash.color_schema.schemes.light['tnc_bg'] = this.bgColor;
            this.tab.param.splash.color_schema.schemes.dark['tnc_bg'] = this.bgColor;
          }
        }
      } else {
        this.textColor=null;
        this.textDarkColor=null;
        this.linkColor=null;
        this.linkDarkColor=null;
        delete this.tab.param.splash.color_schema.schemes.light["tnc_text"];
        delete this.tab.param.splash.color_schema.schemes.light["tnc_link"];
        delete this.tab.param.splash.color_schema.schemes.dark["tnc_text"];
        delete this.tab.param.splash.color_schema.schemes.dark["tnc_link"];
        if(this.enableBg){
          this.bgColor = null;
          this.bgDarkColor = null;
          delete this.tab.param.splash.color_schema.schemes.light['tnc_bg'];
          delete this.tab.param.splash.color_schema.schemes.dark['tnc_bg'];
        }
      }
    }

    if (!this.isAndroid || this.sync) {
      // IOS SETTINGS
      if (this.enableCustomColor) {
        this.textColor = this.colors.schemes.light[this.config.init_text_ios];
        this.textDarkColor = this.colors.schemes.dark[this.config.init_text_ios];
        this.linkColor = this.colors.schemes.light[this.config.init_link_ios];
        this.linkDarkColor = this.colors.schemes.dark[this.config.init_link_ios];
        if(this.enableBg){
            this.bgColor = this.colors.schemes.light[this.config.init_bg_ios];
            this.bgDarkColor = this.colors.schemes.dark[this.config.init_bg_ios];
        }

        if (
          this.tab.param.splash.color_schema_ios &&
          this.tab.param.splash.color_schema_ios.schemes
        ) {
          const schemes = this.tab.param.splash.color_schema_ios.schemes;
          this.button_color_light = schemes.light;
          this.button_color_light = {
            ...this.button_color_light,
            tnc_text: this.textColor,
            tnc_link: this.linkColor,
          };
          this.button_color_dark = schemes.dark;
          this.button_color_dark = {
            ...this.button_color_dark,
            tnc_text: this.textDarkColor,
            tnc_link: this.linkDarkColor,
          };
          if(this.enableBg) {
            this.button_color_light = {
              ...this.button_color_light,
              tnc_bg: this.bgColor,
            };
            this.button_color_dark = {
              ...this.button_color_dark,
              tnc_bg: this.bgDarkColor,
            };
          }
          this.tab.param.splash.color_schema_ios.schemes = {
            light: { ...this.button_color_light },
            dark: { ...this.button_color_dark },
          };
        } else {
          this.tab.param.splash = {
            ...this.tab.param.splash,
            color_schema_ios: {
              schemes: {
                light: {
                  tnc_text: this.textColor,
                  tnc_link: this.linkColor,
                },
                dark: {
                  tnc_text: this.textDarkColor,
                  tnc_link: this.linkDarkColor,
                },
              },
            },
          };
          if(this.enableBg){
            this.tab.param.splash.color_schema_ios.schemes.light['tnc_bg'] = this.bgColor;
            this.tab.param.splash.color_schema_ios.schemes.dark['tnc_bg'] = this.bgColor;
          }
        }
      } else {
        this.textColor=null;
        this.textDarkColor=null;
        this.linkColor=null;
        this.linkDarkColor=null;
        delete this.tab.param.splash.color_schema_ios.schemes.light["tnc_text"];
        delete this.tab.param.splash.color_schema_ios.schemes.light["tnc_link"];
        delete this.tab.param.splash.color_schema_ios.schemes.dark["tnc_text"];
        delete this.tab.param.splash.color_schema_ios.schemes.dark["tnc_link"];
        if(this.enableBg){
          this.bgColor = null;
          this.bgDarkColor = null;
          delete this.tab.param.splash.color_schema_ios.schemes.light['tnc_bg'];
          delete this.tab.param.splash.color_schema_ios.schemes.dark['tnc_bg'];
        }
      }
    }
    this.updateButtonData(this.tab);
  }



  isValidHexaCode(str) {
    // Regex to check valid
    // hexadecimalColor_code
    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

    // if str
    // is empty return false
    if (str == null) {
        return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
        return true;
    }
    else {
        return false;
    }
}

  updateButtonData(tab?) {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;
    this.data["splash_tab"] = this.tab;

    this.mdw._currentMenuContainer.next(this.data);

if (tab){
  this.mdw.changeTab(
    { button: this.button, tab: this.tab },
    this.mode,
    false
  );

}else {
  this.mdw.changeTab(
    { button: this.button, tab: { module: "menu" } },
    this.mode,
    true
  );
}


  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
