import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { offcanvasColors, splash_config_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-splashbgimage",
  templateUrl: "./button-splashbgimage.component.html",
  styleUrls: ["./button-splashbgimage.component.scss"],
})

export class ButtonSplashBGImageComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) { }

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() config: any;
  imageConfig: any = {};
  imageDarkConfig: any = {};
  button: MenuButton;
  image: string;
  imageDark: string;

  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"
  component_id: string;
  field_id: string;
  bgcolor: string = null;
  toggleDarkImage = false;



  ngOnInit() {
    this.field_id = 'button_img_url'

    this.imageConfig = {
      id: this.button_id,
      title: 'Background Image',
      max: 1,
      aspectRatio: "360x738",
      cropperView: true,
      resizeToWidth: 360,
      resizeToHeight: 738,
      keyword: 'Background Image',
      mode: ['upload'],
      params: 'splash',
      selectedImages: [],

    };
    this.imageDarkConfig = {
      id: this.button_id,
      title: 'Dark Background Image',
      max: 1,
      aspectRatio: "360x738",
      cropperView: true,
      resizeToWidth: 360,
      resizeToHeight: 738,
      keyword: 'Dark Background Image',
      mode: ['upload'],
      params: 'splash',
      selectedImages: [],

    };

    this.sortedButtonList = [];
    this.buttonList = {};


    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data)

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton && this.button_id &&
          data.currentButton.button_id == this.button_id
          && data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          this.buttonFunction(this.button)


        }
      }
    );
  }

  intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.button)
  }

  buttonFunction(button) {
    // this.imageConfig.aspectRatio    = splash_config_map[this.data.splashTab.param.splash.style].button_part.images[this.data.splashTab.param.splash.style].aspect;
    // this.imageConfig.resizeToWidth  = splash_config_map[this.data.splashTab.param.splash.style].button_part.images[this.data.splashTab.param.splash.style].width;
    // this.imageConfig.resizeToHeight = splash_config_map[this.data.splashTab.param.splash.style].button_part.images[this.data.splashTab.param.splash.style].height;
    // this.imageConfig.imageType = splash_config_map[this.data.splashTab.param.splash.style].button_part.images[this.data.splashTab.param.splash.style].type;

       this.image = this.builderService.getButtonSplashItem(button, 'button_bgimage', this.data.splashTab.param.splash.style);
       this.imageDark = this.builderService.getButtonSplashItem(button, 'button_bgimage_dark', this.data.splashTab.param.splash.style);
    if (this.image) {
      let media: any = {}
      media.permanentUrl = this.image;
      this.imageConfig.selectedImages = [{ media: media }];
    }
    if (this.imageDark) {
      let media: any = {}
      media.permanentUrl = this.imageDark;
      this.imageDarkConfig.selectedImages = [{ media: media }];
      this.toggleDarkImage=true;
    }
  }

  buttonImage(value) {
    if (value && value.length > 0) {
      this.button.button_bgimage = value[0].media.permanentUrl;
    } else {
      this.button.button_bgimage = '';
    }

    this.updateButtonData()

  }
  buttonImageDark(value) {
    if (value && value.length > 0) {
      this.button.button_bgimage_dark = value[0].media.permanentUrl;
    } else {
      this.button.button_bgimage_dark = '';
    }

    this.updateButtonData()

  }

  buttonImageSet(value) {
    if (value) {
      const buttonImageSet: any = {};
      buttonImageSet.hdpi = value[0].media.permanentUrl;
      buttonImageSet.mdpi = value[1].media.permanentUrl;
      buttonImageSet.xhdpi = value[2].media.permanentUrl;
      buttonImageSet.xxhdpi = value[3].media.permanentUrl;
      buttonImageSet.xxxhdpi = value[4].media.permanentUrl;
      buttonImageSet.ios1x = value[5].media.permanentUrl;
      buttonImageSet.ios2x = value[6].media.permanentUrl;
      buttonImageSet.ios3x = value[7].media.permanentUrl;

      this.button.button_bgimageSet = buttonImageSet

    } else {
      this.button.button_bgimageSet = null;

    }

    this.updateButtonData()
  }
  buttonImageDarkSet(value) {
    if (value) {
      const buttonImageSet: any = {};
      buttonImageSet.hdpi = value[0].media.permanentUrl;
      buttonImageSet.mdpi = value[1].media.permanentUrl;
      buttonImageSet.xhdpi = value[2].media.permanentUrl;
      buttonImageSet.xxhdpi = value[3].media.permanentUrl;
      buttonImageSet.xxxhdpi = value[4].media.permanentUrl;
      buttonImageSet.ios1x = value[5].media.permanentUrl;
      buttonImageSet.ios2x = value[6].media.permanentUrl;
      buttonImageSet.ios3x = value[7].media.permanentUrl;

      this.button.button_bgimageSet_dark = buttonImageSet

    } else {
      this.button.button_bgimageSet_dark = null;

    }

    this.updateButtonData()
  }

  enableDarkImageMode(e) {
    this.toggleDarkImage = e.target.checked;
    if(!this.toggleDarkImage) {
      this.button.button_bgimage_dark = null;
      this.button.button_bgimageSet_dark = null;
      this.updateButtonData();
    }
  }


  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );

  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
