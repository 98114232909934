import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Media, MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { CommonService } from "src/app/sharedservices/common.service";

@Component({
  selector: "app-button-validations-component",
  templateUrl: "./button-validations-component.component.html",
  styleUrls: ["./button-validations-component.component.scss"],
})
export class ButtonValidationsComponentComponent implements OnInit, OnDestroy {
  constructor(
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService
  ) {}

  @Input() button_id: string;
  button: MenuButton;
  mode: string;
  @Input() data: any;
  @Input() config: any;
  @ViewChild("largeDataModal") myModal;

  verifySubscription: any;
  selectedImages: any[] = [];
  swaperForm: UntypedFormGroup;
  submitted = false;
  testSubmitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;
  isCollapsed = true;

  component_id: string;

  selectedSettings: any;
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_bgcolor: string;
  row_id: string;
  button_option_color = "#ffffff";
  button_option = [];
  button_value = [];
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  selectedIcon = {};
  selected_button_value = {};
  isAndroid: boolean;
  field_id: string;
  iconType: number;

  icon: any;
  trailing_icon: any;

  text_id: any;

  imageUploaderConfig: any = {
    id: "swaper",
    title: "Image",
    max: 1,
    selectedImages: [],
    cropperView: false,
    resizeToWidth: 370,
    resizeToHeight: 370,
    removeButton: true,
    removePadding: true,
    aspectRatio: "1x1",
    fullImage: true,
    mode: ["upload", "gallery"],
  };

  optionform: UntypedFormGroup;
  testform: UntypedFormGroup;

  sync = true;

  inputType = "text";

  validatorTypes = [
    { key: "required", value: "Required" },
    { key: "range", value: "Range" },
    { key: "pattern", value: "Pattern" },
  ];

  ngOnInit() {
    this.field_id = "label";
    this.testform = this.formBuilder.group({
      testValue: new UntypedFormControl(""),
    });
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );
    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          this.icon;
          this.buttonFunction();
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  get f() {
    return this.optionform.controls;
  }
  get fTest() {
    return this.testform.controls;
  }

  buttonFunction() {
    if (this.button) {
      this.text_id = this.config.id.split(/_(.*)/s)[1];
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.inputType = this.button.button_value_type ? this.button.button_value_type : "text";
      switch (this.inputType) {
        case "number":
          this.validatorTypes = [
            { key: "required", value: "Required" },
            { key: "range", value: "Range" },
            { key: "pattern", value: "Pattern" },
          ];
          break;
        case "email":
          this.validatorTypes = [
            { key: "required", value: "Required" },
            { key: "pattern", value: "Pattern" },
          ];
          break;
        case "url":
          this.validatorTypes = [
            { key: "required", value: "Required" },
            { key: "pattern", value: "Pattern" },
          ];
          break;
        case "date":
          this.validatorTypes = [
            { key: "required", value: "Required" },
            { key: "range", value: "Date Range" },
            // { key: "pattern", value: "Pattern" },
          ];
          break;
        case "time":
          this.validatorTypes = [
            { key: "required", value: "Required" },
            { key: "range", value: "Time Range" },
            // { key: "pattern", value: "Pattern" },
          ];
          break;
        default:
          this.validatorTypes = [
            { key: "required", value: "Required" },
            { key: "minlength", value: "Min Length" },
            { key: "maxlength", value: "Max Length" },
            { key: "pattern", value: "Pattern" },
          ];
          break;
      }
      if (
        this.button.button_validations &&
        this.button.button_validations.length > 0
      ) {
        this.button_option = structuredClone(this.button.button_validations);
        this.addVlidations();
      }
    }
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );
  }

  addNewSwaper() {
    const swaper: any = {
      id: "o_" + this.mdw.makeRef(16),
      type: "",
    };
    if (this.config.icon) {
      swaper.icon = "";
    }
    if (this.config.image) {
      swaper.image = "";
    }
    this.button_option.push(swaper);
    this.openSettings(swaper);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.button_option,
      event.previousIndex,
      event.currentIndex
    );
    this.button_option = this.button_option.map((image, index) => {
      image.order = index;
      return image;
    });
    this.button.button_validations = this.button_option;
    this.updateButtonData();
  }

  openSettings(option) {
    this.icon = null;
    this.trailing_icon = null;
    this.submitted = false;
    let min = option && option.min ? option.min : "";
    let max = option && option.max ? option.max : "";
    if (this.inputType === "date") {
      min = new Date(min);
      max = new Date(max);
    }
    this.optionform = this.formBuilder.group({
      id: new UntypedFormControl((option && option.id) || ""),
      type: new UntypedFormControl((option && option.type) || "", [Validators.required]),
      min: new UntypedFormControl(min || "", [Validators.required]),
      max: new UntypedFormControl(max || "", [Validators.required]),
      pattern: new UntypedFormControl((option && option.pattern) || "", [
        Validators.required,
      ]),
      message: new UntypedFormControl((option && option.message) || ""),
    });

    switch (this.optionform.value.type) {
      case "required":
        this.optionform.get("min").disable();
        this.optionform.get("max").disable();
        this.optionform.get("pattern").disable();
        break;
      case "range":
        this.optionform.get("min").enable();
        this.optionform.get("max").enable();
        this.optionform.get("pattern").disable();
        break;
      case "pattern":
        this.optionform.get("min").disable();
        this.optionform.get("max").disable();
        this.optionform.get("pattern").enable();
        break;
      case "minlength":
        this.optionform.get("min").enable();
        this.optionform.get("max").disable();
        this.optionform.get("pattern").disable();
        break;
      case "maxlength":
        this.optionform.get("min").disable();
        this.optionform.get("max").enable();
        this.optionform.get("pattern").disable();
        break;
    }

    this.selectedSettings = structuredClone(option);
  }

  saveChanges() {
    this.submitted = true;
    if (this.optionform.valid) {
      this.submitted = false;
      this.selectedSettings = {
        ...this.optionform.value,
      };
      if (this.inputType === "date") {
        this.selectedSettings = {
          ...this.selectedSettings,
          min: this.commonService.dateFormat(this.selectedSettings.min),
          max: this.commonService.dateFormat(this.selectedSettings.max),
        };
      }
      this.button_option = this.button_option.map((image) =>
        image.id === this.selectedSettings.id ? this.selectedSettings : image
      );
      this.button.button_validations = this.button_option;
      // update default value if original object has been changed.

      this.updateButtonData();
      this.openSettings(null);
    } else {
      return;
    }
  }

  changeStyle() {
    const type = this.optionform.get("type").value;
    switch (type) {
      case "required":
        this.optionform.get("min").disable();
        this.optionform.get("max").disable();
        this.optionform.get("pattern").disable();
        break;
      case "range":
        this.optionform.get("min").enable();
        this.optionform.get("max").enable();
        this.optionform.get("pattern").disable();
        break;
      case "pattern":
        this.optionform.get("min").disable();
        this.optionform.get("max").disable();
        this.optionform.get("pattern").enable();
        break;
      case "minlength":
        this.optionform.get("min").enable();
        this.optionform.get("max").disable();
        this.optionform.get("pattern").disable();
        break;
      case "maxlength":
        this.optionform.get("min").disable();
        this.optionform.get("max").enable();
        this.optionform.get("pattern").disable();
        break;
    }
  }

  validationName(type) {
    if (type) {
      return this.validatorTypes.find((val) => val.key == type).value;
    }
    return "";
  }

  submitTest() {
    this.testSubmitted = true;
    if (this.testform.valid) {
      this.testSubmitted = false;
    } else {
      return;
    }
  }

  deleteImage(id: string) {
    this.button_option = this.button_option.filter((image) => image.id !== id);
    this.button.button_validations = this.button_option;
    this.updateButtonData();
  }

  addVlidations() {
    let validators = [];
    this.testSubmitted = false;
    for (let i = 0; this.button_option.length > i; i++) {
      switch (this.button_option[i].type) {
        case "required":
          validators.push(Validators.required);
          break;
        case "minlength":
          validators.push(Validators.minLength(this.button_option[i].min));
          break;
        case "maxlength":
          validators.push(Validators.maxLength(this.button_option[i].max));
          break;
        case "range":
          if (this.inputType === "date") {
              validators.push(this.dateValidator(new Date(this.button_option[i].min),new Date(this.button_option[i].max)));
          } else {
            if (this.button_option[i].min) {
              validators.push(Validators.min(this.button_option[i].min));
            }
            if (this.button_option[i].max) {
              validators.push(Validators.max(this.button_option[i].max));
            }
          }
          break;
        case "pattern":
          validators.push(
            Validators.pattern(new RegExp(this.button_option[i].pattern))
          );
          break;
      }
    }
    this.testform.get("testValue").setValidators(validators);
  }

   // Custom validator for min and max date range
   dateValidator(minDate: Date, maxDate: Date) {
    return (control: any) => {
      const value = new Date(new Date(control.value).setHours(0, 0, 0, 0));  // Get the selected date
      const min_date = new Date(minDate.setHours(0, 0, 0, 0));
      const max_date = new Date(maxDate.setHours(0, 0, 0, 0));
      // Convert to date in correct format
      if (control.value && (value < min_date || value > max_date)) {
        return { 'dateRange': true };  // Custom error for date range violation
      }
      return null;
    };
  }

  checkValidations(): boolean {
    return this.validatorTypes.every(validation => {
      const isValid = this.button_option.some(validator => validator.type === validation.key);
      return isValid;
    });
  }

  disapleOptionifSelected(item){
    return this.button_option.some(validation => validation.type === item.key)
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
