<div class="not-allowed">
  <div *ngIf="isAndroid">
    <div
      class="card m-0 rounded bg-transparent p-0 shadow-none"
    >
      <img [src]="emptyImage" class="card-img" alt="empty" [style]="'height: ' + cellHeight" [ngStyle]="{'height': fontSmaller ? '16px': '38px'}">
      <div class="card-img-overlay p-0">
        <div class="w-100 h-100">
          <div class="d-flex align-items-center" style="scrollbar-width: thin;" [ngClass]="this.fontSmaller || this.fontResize ? 'overflow-hidden' : 'overflow-auto'">
            <ng-container *ngFor="let option of options; index as index; let last = last">
              <div
                class="px-1 py-1 d-flex align-items-center me-2"
                [ngClass]="optionborder"
                [ngStyle]="activeOption[option.id] ? optionSelectedBgColor: optionBgColor"
                [style]="'border:' + outlined_border + 'pointer-events: none;' + 'height: ' + cellHeight + '; box-shadow:' + box_shadow"
              >
              <i *ngIf="activeOption[option.id] && showDoneIcon" [ngStyle]="activeOption[option.id]? leadingSelectedIconColor: leadingIconColor" class="float-none m-0 ms-1" [ngStyle]="activeOption[option.id]? leadingIconColor: optionTextColor"  [class]="builderService?.getIcon('done').type=='mir'?'material-icons-outlined':'material-icons'" >done</i>
              <i *ngIf="option?.icon && (!activeOption[option.id] || !showDoneIcon)" class="float-none m-0 ms-1" [ngStyle]="activeOption[option.id]? leadingSelectedIconColor: leadingIconColor"  [class]="builderService?.getIcon(option?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >{{builderService?.getIcon(option?.icon).id}}</i>
              <p class="m-0 px-2 text-truncate" [ngStyle]="activeOption[option.id] ? labelColor : optionTextColor">
                  {{ option.label }}
                </p>
                <!-- <i *ngIf="option?.icon" class="float-none m-0 me-1" [ngStyle]="activeOption[option.id]? leadingSelectedIconColor: leadingIconColor"  [class]="builderService?.getIcon('close').type=='mir'?'material-icons-outlined':'material-icons'" >close</i> -->
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isAndroid">
    <div
      class="card m-0 rounded bg-transparent p-0 shadow-none"
    >
      <img [src]="emptyImage" class="card-img" alt="empty" [style]="'height: ' + cellHeight" [ngStyle]="{'height': fontSmaller ? '16px': '38px'}">
      <div class="card-img-overlay p-0">
        <div class="w-100 h-100">
          <div class="d-flex align-items-center" style="scrollbar-width: thin;" [ngClass]="this.fontSmaller || this.fontResize ? 'overflow-hidden' : 'overflow-auto'">
            <ng-container *ngFor="let option of options; index as index; let last = last">
              <div
                class="px-1 py-1 d-flex align-items-center me-2"
                [ngClass]="optionborder"
                [ngStyle]="activeOption[option.id] ? optionSelectedBgColor: optionBgColor"
                [style]="'border:' + outlined_border + 'pointer-events: none;' + 'height: ' + cellHeight + '; box-shadow:' + box_shadow"
              >
                <i *ngIf="activeOption[option.id] && showDoneIcon" [ngStyle]="activeOption[option.id]? leadingSelectedIconColor: leadingIconColor" class="float-none m-0 ms-1" [ngStyle]="activeOption[option.id]? leadingIconColor: optionTextColor"  [class]="builderService?.getIcon('done').type=='mir'?'material-icons-outlined':'material-icons'" >done</i>
                <i *ngIf="option?.icon && (!activeOption[option.id] || !showDoneIcon)" class="float-none m-0 ms-1" [ngStyle]="activeOption[option.id]? leadingSelectedIconColor: leadingIconColor"  [class]="builderService?.getIcon(option?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >{{builderService?.getIcon(option?.icon).id}}</i>
                <p class="m-0 px-2 text-truncate" [ngStyle]="activeOption[option.id] ? labelColor : optionTextColor">
                  {{ option.label }}
                </p>
                <!-- <i *ngIf="option?.icon" class="float-none m-0 me-1" [ngStyle]="activeOption[option.id]? leadingSelectedIconColor: leadingIconColor"  [class]="builderService?.getIcon('close').type=='mir'?'material-icons-outlined':'material-icons'" >close</i> -->
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
