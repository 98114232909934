<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="bg-transparent p-0 mb-3">
      <h6 class="offcanvas-header-text text-start">
        {{ "GENERALS.OPTIONS" | translate }}
      </h6>
    </div>
    <div class="row m-0">
      <div cdkDropList class="col-12 p-0" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let opt of button_option">
          <div
            class="card offcanvas-primary mb-2 flex-row"
            cdkDrag
            cdkDragLockAxis="y"
            [cdkDragDisabled]="selectedSettings"
            *ngIf="
              !selectedSettings ||
              (selectedSettings && selectedSettings.id !== opt.id)
            "
          >
            <div
              class="col-1 d-flex justify-content-center align-items-center border-end offcanvas-outline-variant"
            >
              <i
                class="font-size-20 offcanvas-drag-icon material-icons"
                style="cursor: move"
                >menu</i
              >
            </div>
            <div class="col-11">
              <div class="row m-0 no-gutters align-items-center">
                <div class="col-md-12 p-0">
                  <div class="row m-0">
                    <div
                      class="col-2 d-flex align-items-center justify-content-center"
                      *ngIf="
                        (config?.icon && (opt?.icon || opt?.icon_ios)) ||
                        (opt.image && config?.image)
                      "
                    >
                      <i
                        *ngIf="config?.icon && (opt?.icon || opt?.icon_ios)"
                        class="float-none w-auto m-0 px-1"
                        [class]="
                          getIcon(opt).type == 'mir'
                            ? 'material-icons-outlined'
                            : 'material-icons'
                        "
                      >
                        {{ getIcon(opt).id }}
                      </i>
                      <img
                        *ngIf="opt.image && config?.image"
                        [src]="opt.image"
                        alt="Card image"
                        class="card-img img-fluid object-fit-cover rounded-circle"
                        style="max-height: 60px; max-width: 100%"
                      />
                    </div>
                    <div
                      class="p-0 overflow-hidden"
                      [ngClass]="
                        (config?.icon && (opt?.icon || opt?.icon_ios)) ||
                        (opt.image && config?.image)
                          ? 'col-10 ps-1'
                          : 'col-12 ps-3'
                      "
                    >
                      <div
                        class="card-body position-relative py-3 ps-0 offcanvas-card"
                      >
                        <p
                          class="card-text text-start offcanvas-header-text m-0"
                        >
                          {{ opt.label ? opt.label : "New Item" }}
                        </p>
                        <button
                          class="btn btn-primary offcanvas-header-text p-0 position-absolute option-button"
                          style="
                            width: 32px;
                            height: 32px;
                            top: calc(50% - 16px);
                            right: -32px;
                          "
                          (click)="openSettings(opt)"
                        >
                          <i class="fas fa-cog font-size-14"></i>
                        </button>
                        <button
                          class="btn btn-danger offcanvas-header-text p-0 position-absolute option-button"
                          style="
                            width: 32px;
                            height: 32px;
                            top: calc(50% - 16px);
                            right: -69px;
                          "
                          (click)="deleteImage(opt.id)"
                        >
                          <i class="mdi mdi-delete font-size-14"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card rounded-3 bg-transparent bg-opacity-50 mb-2"
            *ngIf="selectedSettings && selectedSettings.id === opt.id"
            style="border: 1px solid"
          >
            <div class="card-body">
              <form [formGroup]="optionform" (ngSubmit)="saveChanges()">
                <div class="mb-3 text-start">
                  <label
                    for="text-color"
                    class="offcanvas-header-text w-100"
                    style="text-align: left"
                  >
                    {{ "GENERALS.OPTIONID" | translate }}
                  </label>
                  <input
                    readonly
                    type="text"
                    id="text-color"
                    class="form-control input-lg offcanvas-primary border-0"
                    formControlName="id"
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="title"
                    class="offcanvas-header-text w-100"
                    style="text-align: left"
                  >
                    {{ "GENERALS.LABEL" | translate }}
                  </label>
                  <input
                    type="text"
                    id="optTitle"
                    #optTitle
                    class="form-control input-lg offcanvas-primary border-0"
                    formControlName="label"
                  />
                </div>
                <div class="mb-3" *ngIf="config?.sublabel">
                  <label
                    for="sublabel"
                    class="offcanvas-header-text w-100"
                    style="text-align: left"
                  >
                    {{ "GENERALS.SUBLABEL" | translate }}
                  </label>
                  <input
                    type="text"
                    id="sublabel"
                    #optTitle
                    class="form-control input-lg offcanvas-primary border-0"
                    formControlName="sublabel"
                  />
                </div>
                <div
                  class="row d-flex justify-content-between align-items-center mb-3"
                  *ngIf="config?.icon || config?.trailing_icon"
                >
                  <div class="col-6" *ngIf="config?.icon">
                    <label
                      class="offcanvas-header-text w-100"
                      style="text-align: left"
                    >
                      Leading Icon
                    </label>
                    <div
                      class="input-group offcanvas-border-primary border rounded-2"
                      title="Using format option"
                    >
                      <button
                        type="button"
                        class="form-control input-lg offcanvas-primary border-0 d-flex align-items-center justify-content-center"
                        (click)="openSelector(selectorOption, 0)"
                      >
                        <i
                          class="fs-4"
                          [class]="
                            icon?.type == 'mir'
                              ? 'material-icons-outlined'
                              : 'material-icons'
                          "
                        >
                          {{ icon?.id }}
                        </i>
                      </button>
                      <button
                        type="button"
                        class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
                        (click)="openSelector(selectorOption, 0)"
                      >
                        <i class="mdi mdi-chevron-down"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mb-3" *ngIf="config?.image">
                  <app-image-uploader
                    [data]="imageUploaderConfig"
                    [removePadding]="true"
                    (images)="saveImages($event)"
                  ></app-image-uploader>
                </div>

                <div class="mb-3">
                  <label
                    for="title"
                    class="offcanvas-header-text w-100"
                    style="text-align: left"
                  >
                    {{ "GENERALS.VALUE" | translate }}
                  </label>
                  <input
                    type="text"
                    id="optValue"
                    #optValue
                    class="form-control input-lg offcanvas-primary border-0"
                    formControlName="value"
                  />
                </div>
                <div class="mb-3" *ngIf="config?.divider">
                  <div
                    class="d-flex align-items-center justify-content-between p-0"
                  >
                    <label class="offcanvas-header-text m-0"> Divider </label>
                    <input
                      *ngIf="isAndroid"
                      class="form-check-input offcanvas-border-primary offcanvas-primary"
                      type="checkbox"
                      id="divider"
                      #divider
                      style="width: 18px; height: 18px"
                      formControlName="divider"
                      (change)="toggleDivider($event)"
                    />
                    <input
                      *ngIf="!isAndroid"
                      class="form-check-input offcanvas-border-primary offcanvas-primary"
                      type="checkbox"
                      id="divider"
                      #divider
                      style="width: 18px; height: 18px"
                      formControlName="divider_ios"
                      (change)="toggleDivider($event)"
                    />
                  </div>
                </div>
                <div
                  class="d-flex flex-wrap justify-content-end align-content-center gap-2"
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="openSettings(null)"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </form>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="row m-0 mb-2"
      *ngIf="
        !selectedSettings &&
        (config?.max == 0 ||
          (config?.max > 0 && config.max > button_option.length))
      "
    >
      <button
        class="btn btn-primary btn-sm shadow-none col-4 offset-8 d-flex align-items-center justify-content-around"
        type="button"
        (click)="addNewSwaper()"
      >
        <i class="mdi mdi-plus font-size-18"></i>
        Add Option
      </button>
    </div>
    <div class="row m-0 mb-2" *ngIf="!config?.hideValue">
      <div class="col-6 ps-0 d-flex align-items-center">
        <label class="offcanvas-header-text m-0" for="formReply">
          {{ "GENERALS.DEFAULTVALUES" | translate }}
        </label>
        <i
          class="mdi mdi-information-outline offcanvas-header-text font-size-16 ms-1"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="You can set the default values"
        ></i>
      </div>
    </div>
    <div *ngIf="!config?.hideValue" class="row m-0" [ngClass]="isCollapsed ? 'collapsed' : ''">
      <div
        class="col-12 pb-0 offcanvas-header-bg d-flex justify-content-between align-items-center"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        (click)="isCollapsed = !isCollapsed"
        [ngClass]="isCollapsed ? 'rounded-3' : 'rounded-top-3'"
      >
        <span class="ps-1 font-size-13 offcanvas-header-text">Default</span>
        <i class="dropdown-icon font-size-22 mdi mdi-chevron-up"></i>
      </div>
      <div
        class="col-12 pt-3 offcanvas-submenu rounded-bottom-3 collapse"
        id="collapseExample"
      >
        <div class="p-0 overflow-auto" style="max-height: 200px">
          <div class="mb-3" *ngFor="let opt of button_option; let i = index">
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
                type="checkbox"
                [id]="'divider' + i"
                #divider
                style="width: 18px; height: 18px"
                (change)="addButtonToValue(opt)"
                [checked]="getSelectedButtonValue(opt)"
              />
              <label
                class="offcanvas-header-text w-100 m-0"
                [for]="'divider' + i"
              >
                {{ opt?.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- <accordion
        [isAnimated]="true"
        class="accordion custom-accordion offcanvas-border-primary offcanvas-primary overflow-hidden"
        style="border-radius: 0.25rem"
      >
        <accordion-group
          heading="{{ 'GENERALS.SETDEFAULTVALUES' | translate }}"
          class="accordion-item offcanvas-border-primary offcanvas-primary overflow-hidden"
          #flush1
          style="border-radius: 0.25rem"
        >
          <span accordion-heading class="float-end fs-5">
            <i
              class="mdi"
              [ngClass]="
                !flush1?.isOpen
                  ? 'mdi mdi-chevron-down accor-plus-icon'
                  : 'mdi mdi-chevron-up accor-plus-icon'
              "
            ></i>
          </span>
          <div class="simplebar-content-wrapper" style="max-height: 200px;">
            <div class="mb-3" *ngFor="let opt of button_option; let i = index">
              <div class="form-check d-flex align-items-center">
                <input
                  class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
                  type="checkbox"
                  [id]="'divider' + i"
                  #divider
                  style="width: 18px; height: 18px"
                  (change)="addButtonToValue(opt)"
                  [checked]="getSelectedButtonValue(opt)"
                />
                <label class="offcanvas-header-text w-100 m-0" [for]="'divider' + i">
                  {{ opt?.label }}
                </label>
              </div>
            </div>
          </div>
        </accordion-group>
      </accordion> -->
    </div>
  </div>

  <ng-template #selectorOption let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="selector">Choose Option Icon</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      ></button>
    </div>
    <div class="modal-body">
      <app-icons-selector
        (selectedIcon)="changeIcon($event)"
      ></app-icons-selector>
    </div>
  </ng-template>
</div>
