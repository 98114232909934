<div class="not-allowed">
  <div
    *ngIf="heading"
    class="col-12 m-0 p-0 d-flex align-items-center"
    [ngClass]="label_align == 'center' ? 'justify-content-center' : label_align == 'right' ? 'justify-content-end' : 'justify-content-start'"
  >
    <div class="m-0 p-0">
      <p
        class="float-none w-auto m-0 d-flex align-items-center"
        [ngStyle]="headlineColor"
        [ngClass]="labellPadding"
      >
        {{ heading }}
      </p>
    </div>
  </div>
  <div
    class="card p-0 m-0 position-relative shadow-none w-100 bg-transparent"
    style="max-width: 560px"
  >
    <img [src]="emptyImage" class="card-img" alt="empty" />
    <div *ngIf="isAndroid" class="card-img-overlay p-0">
      <div
        #scrollContainer
        class="simplebar-content-wrapper h-100 w-100 d-flex align-items-center"
        style="scroll-snap-type: x mandatory"
      >
        <ng-container *ngFor="let option of options; let i = index">
          <!-- <img class="rounded-4 object-fit-cover h-100 me-2" [src]="img" alt=""> -->
          <div
            [ngClass]="fontSmaller ? 'rounded-3 me-1' : fontResize ? 'rounded-4 me-2' : 'rounded-5 me-2'"
            [id]="i"
            class="h-100 overflow-hidden p-0 text-white"
            [style]="'background-image: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%), url(' + option?.image +'); background-size: cover; min-width: ' + (widths[i] ? widths[i] : widths[2]) + 'px'"
          >
            <div class="w-100 h-100 position-relative">
              <div
                class="position-absolute bottom-0 start-0 end-0"
                [ngClass]="fontSmaller ? 'p-0' : fontResize ? 'p-2' : 'p-3'"
                *ngIf="hideText?.includes(i)"
              >

                  <div class="d-flex" [ngClass]="option_text_align==='center'? 'justify-content-center align-items-center':option_text_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'">
                    <p
                      type="text"
                      class="card-title p-0 m-0 shadow-none border-0 text-white"
                      [ngStyle]="labelColor"
                    >
                      {{ option?.label }}
                    </p>
                  </div>
                  <div class="d-flex" [ngClass]="option_subtext_align==='center'? 'justify-content-center align-items-center':option_subtext_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'">
                    <p class="p-0 m-0 text-wrap text-white" [ngStyle]="sublableColor">
                      {{ option?.sublabel }}
                    </p>
                  </div>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!isAndroid" class="card-img-overlay p-0">
      <div
        #scrollContainer
        class="simplebar-content-wrapper h-100 w-100 d-flex align-items-center"
        style="scroll-snap-type: x mandatory"
      >
        <ng-container *ngFor="let option of options; let i = index">
          <!-- <img class="rounded-4 object-fit-cover h-100 me-2" [src]="img" alt=""> -->
          <div
            [ngClass]="fontSmaller ? 'rounded-3 me-1' : fontResize ? 'rounded-4 me-2' : 'rounded-5 me-2'"
            [id]="i"
            class="h-100 overflow-hidden p-0 text-white"
            [style]="'background-image: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%), url(' + option?.image +'); background-size: cover; min-width: ' + (widths[i] ? widths[i] : widths[2]) + 'px'"
          >
            <div class="w-100 h-100 position-relative">
              <div
                class="position-absolute bottom-0 start-0 end-0"
                [ngClass]="fontSmaller ? 'p-0' : fontResize ? 'p-2' : 'p-3'"
                *ngIf="hideText?.includes(i)"
              >

                  <div class="d-flex" [ngClass]="option_text_align==='center'? 'justify-content-center align-items-center':option_text_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'">
                    <p
                      type="text"
                      class="card-title p-0 m-0 shadow-none border-0 text-white"
                      [ngStyle]="labelColor"
                    >
                      {{ option?.label }}
                    </p>
                  </div>
                  <div class="d-flex" [ngClass]="option_subtext_align==='center'? 'justify-content-center align-items-center':option_subtext_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'">
                    <p class="p-0 m-0 text-wrap text-white" [ngStyle]="sublableColor">
                      {{ option?.sublable }}
                    </p>
                  </div>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="trailing_text"
    class="col-12 m-0 p-0 d-flex align-items-center justify-content-end"
  >
    <div class="m-0 p-0 ">
      <p
        class="float-none w-auto m-0 "
        [ngStyle]="trailingTextColor"
      >
        {{ trailing_text }}
      </p>
    </div>
  </div>
</div>
<!--
<div class="d-flex align-items-center" *ngIf="!fontSmaller && !fontResize">
  <button class="btn btn-dark" (click)="wipeNextBtn()">next</button>
  <button class="btn btn-dark" (click)="wipePrevBtn()">prev</button>
</div> -->
