<div *ngIf="tab_style">
  <div class="p-0 w-100 d-flex align-items-center justify-content-center">
    <div class="card p-0 m-0 position-relative shadow-none" style="max-width: 260px">
      <img [src]="emptyImage" class="card-img" alt="empty">
      <div class="card-img-overlay p-0" [ngStyle]="setTabBg">
        <div class="position-absolute start-0 end-0">

          <div *ngIf="albums[0]?.en" class="card m-0 -p0 shadow-none rounded-0"
            style="background-color: rgba(0,0,0,{{albums[0].opacity}}); max-width: 260px ; backdrop-filter: blur({{albums[0].blur}})">
            <img [src]="albums[0]?.emptyImage" alt="empty" class="card-img">
            <div class=" card-img-overlay border overflow-hidden m-0 p-0"
              [ngStyle]="{'padding': fontSmaller?albums[0].padding:''}" [ngClass]="debug? 'border-1':'border-0'">
              <ng-template [ngTemplateOutlet]="Splash_p1"></ng-template>

            </div>
          </div>

          <div *ngIf="albums[1]?.en" class="card m-0 -p0 shadow-none rounded-0"
            style="background-color: rgba(0,0,0,{{albums[1].opacity}}); max-width: 260px; backdrop-filter: blur({{albums[1].blur}})">
            <img [src]="albums[1]?.emptyImage" alt="empty" class="card-img">
            <div class="card-img-overlay border overflow-hidden m-0 p-0"
              [ngStyle]="{'padding': fontSmaller?albums[1].padding:''}" [ngClass]="debug? 'border-1':'border-0'">
              <ng-template [ngTemplateOutlet]="Splash_p2"></ng-template>

            </div>
          </div>

          <div *ngIf="albums[2]?.en" class="card m-0 p-0 shadow-none" ngClass="{{albums[2].round}}"
            style="background-color: rgba(0,0,0,{{albums[2].opacity}}); max-width: 260px ; backdrop-filter: blur({{albums[2].blur}})"
            [ngStyle]="setTitleBg">
            <img [src]="albums[2]?.emptyImage" alt="empty" class="card-img">
            <div class=" card-img-overlay border overflow-hidden m-0 p-0"
              [ngStyle]="{'padding': fontSmaller?albums[2].padding:''}" [ngClass]="debug? 'border-1':'border-0'">
              <ng-template [ngTemplateOutlet]="Splash_p3"></ng-template>

            </div>
          </div>

          <div *ngIf="albums[3]?.en" class="card m-0 p-0 shadow-none" ngClass="{{albums[3].round}}"
            style="background-color: rgba(0,0,0,{{albums[3].opacity}}) ; max-width: 260px; backdrop-filter: blur({{albums[3].blur}})"
            [ngStyle]="setTncbBg" >
            <img [src]="albums[3]?.emptyImage" alt="empty" class="card-img" >
            <div class=" card-img-overlay border overflow-hidden m-0 p-0"
              [ngStyle]="{'padding': fontSmaller?albums[3].padding:''}" [ngClass]="debug? 'border-1':'border-0'">
              <ng-template [ngTemplateOutlet]="Splash_p4"></ng-template>
            </div>
          </div>


          <div *ngIf="albums[4]?.en" class="card m-0 p-0 shadow-none round rounded-top-4"
            style="background-color: rgba(0,0,0,{{albums[4].opacity}}) ; max-width: 260px ; backdrop-filter: blur({{albums[4].blur}})">
            <img [src]="albums[4]?.emptyImage" alt="empty" class="card-img">
            <div class=" card-img-overlay border overflow-hidden m-0 p-0" [ngStyle]="setTncbBg"
              [ngStyle]="{'padding': fontSmaller?albums[4].padding:''}" [ngClass]="debug? 'border-1':'border-0'">
              <ng-template [ngTemplateOutlet]="Splash_p5"></ng-template>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #Splash_p1>
  <div class="row m-0 p-0">
  </div>
</ng-template>

<ng-template #Splash_p2>
  <div *ngIf="tab_style=='01'" class="row m-0 p-0">
    <div class=" col-12 p-0 m-0">
      <img [src]='splash?.image' class="card-img m-0 p-0" class="object-fit-cover mx-auto d-block" style="width:100%">
    </div>
  </div>
  <div *ngIf="tab_style=='02'" class="row m-0 p-0">
    <div class=" col-12 d-flex align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
      <img [src]='splash?.image' class="object-fit-cover mx-auto d-block" style="width:50%" *ngIf="splash?.image" >
    </div>
  </div>

  <div *ngIf="tab_style=='04'  || tab_style=='05'  || tab_style=='06' || tab_style=='07'  " class="row m-0 p-0">
    <div class=" col-12 d-flex align-items-center justify-content-center position-absolute top-50 start-50 translate-middle  p-0 m-0" >
        <img [src]='splash?.ovalImage' class="object-fit-cover mx-auto d-block card-img p-0 m-0" style="width:50%; " >
       <div class="card-img-overlay overflow-hidden position-absolute top-50 start-50 translate-middle m-0 p-0">
        <img [src]='splash?.image' class="object-fit-scale mx-auto d-block p-0 m-0 h-100" style="width:50% ; border-radius:50% !important" *ngIf="splash?.image">
       </div>
    </div>
  </div>

  </ng-template>

<ng-template #Splash_p3>

  <div *ngIf="tab_style=='02' || tab_style=='04' || tab_style=='05' || tab_style=='06' || tab_style=='07'" class='overflow-hidden m-0 pt-2 pb-0 ps-1 pe-1'>
    <div id='innerHTML' class="text-center text-break text-wrap html " [innerHTML]="splash.title"
      [ngStyle]="{'font-size':fontSmaller?'40%':'' }">
    </div>
  </div>

  <div *ngIf="tab_style=='03'   " class='overflow-hidden m-0 pt-2 pb-0 ps-0 pe-0 rounded-0'>
    <div id='innerHTML' class="text-center text-break text-wrap html offcanvas-header-text" [innerHTML]="terms?.title"
      [ngStyle]="{'font-size':fontSmaller?'50%':'' }">
    </div>
  </div>
</ng-template>

<ng-template #Splash_p4>

  <div class="row text-break m-0 pt-2 pb-0 ps-0 pe-0">
    <div class=" col-10 offset-1  text-center p-0 mb-0">
      <a type="button"
        class="rounded-pill p-1 btn btn-lg  w-100 d-flex align-items-center justify-content-center shadow-none border-0 text-wrap"
        [ngStyle]="setButtonBg">{{terms.button_title}}</a>
      <p class="m-0 mt-1 text-wrap" [ngStyle]="{'color': terms.tnc_text, 'font-size':fontSmaller?'5px':'9px'}">
        Read our <a [ngStyle]="{'color': terms.tnc_link}"> Privacy Policy</a>. Tap “{{terms.button_title}}”
        to accept <a [ngStyle]="{'color': terms.tnc_link}"> {{companyName}}'s Terms of Service. </a>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #Splash_p5>
  <div class="row text-break ">
  </div>
</ng-template>
