import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Media, MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { CommonService } from "src/app/sharedservices/common.service";

@Component({
  selector: "app-button-value-type-component",
  templateUrl: "./button-value-type-component.component.html",
  styleUrls: ["./button-value-type-component.component.scss"],
})
export class ButtonValueTypeComponentComponent implements OnInit, OnDestroy {
  constructor(
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService
  ) {}

  @Input() button_id: string;
  button: MenuButton;
  mode: string;
  @Input() data: any;
  @Input() config: any;
  @ViewChild("largeDataModal") myModal;

  verifySubscription: any;
  selectedImages: any[] = [];
  swaperForm: UntypedFormGroup;
  submitted = false;
  testSubmitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;
  isCollapsed = true;

  component_id: string;

  selectedSettings: any;
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_bgcolor: string;
  row_id: string;
  button_option_color = "#ffffff";
  button_option = [];
  button_value = [];
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  selectedIcon = {};
  selected_button_value = {};
  isAndroid: boolean;
  field_id: string;
  iconType: number;

  icon: any;
  trailing_icon: any;

  text_id: any;

  imageUploaderConfig: any = {
    id: "swaper",
    title: "Image",
    max: 1,
    selectedImages: [],
    cropperView: false,
    resizeToWidth: 370,
    resizeToHeight: 370,
    removeButton: true,
    removePadding: true,
    aspectRatio: "1x1",
    fullImage: true,
    mode: ["upload", "gallery"],
  };

  form: UntypedFormGroup;
  typeList = [];

  sync = true;

  inputType = "text";

  validatorTypes = [
    { key: "required", value: "Required" },
    { key: "range", value: "Range" },
    { key: "pattern", value: "Pattern" },
  ];

  ngOnInit() {
    this.field_id = "label";
    this.form = this.formBuilder.group({
      valueType: new UntypedFormControl("string"),
    });
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );
    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          this.icon;
          this.buttonFunction();
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  buttonFunction() {
    if (this.button) {
      this.text_id = this.config.id.split(/_(.*)/s)[1];
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.typeList = this.config.types_list;
      this.form = this.formBuilder.group({
        valueType: new UntypedFormControl(
          this.button.button_value_type || "string"
        ),
      });
    }
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );
  }

  saveChanges() {
    this.submitted = true;
    this.button.button_value_type = this.form.value.valueType;
    this.updateButtonData();
  }
  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
